/* Options:
Date: 2023-01-19 10:41:09
Version: 6.10
Tip: To override a DTO option, remove "//" prefix before updating
BaseUrl: https://localhost:44349

//GlobalNamespace: 
//MakePropertiesOptional: False
//AddServiceStackTypes: True
//AddResponseStatus: False
//AddImplicitVersion: 
//AddDescriptionAsComments: True
//IncludeTypes: 
//ExcludeTypes: 
//DefaultImports: 
*/


export interface IReturn<T>
{
    createResponse(): T;
}

export interface IReturnVoid
{
    createResponse(): void;
}

export interface IGet
{
}

export interface IPost
{
}

export interface IHasSessionId
{
    sessionId: string;
}

export interface IHasBearerToken
{
    bearerToken: string;
}

export interface IDelete
{
}

export interface IUserGetSingle
{
    metadata: { [index: string]: Object; };
}

export interface ILookupGetCollectionByFormDefinitionId extends IGet
{
    formDefinitionId?: number;
}

export interface IConfigurationGetSingleById extends IGet
{
    id?: number;
}

export interface IConfigurationGetCollection extends IGet
{
    skip?: number;
    take?: number;
}

export interface IConfigurationGetSingleByCode extends IGet
{
    code: string;
}

export interface IDataGetSingleById extends IGet
{
    id?: number;
}

export interface IDataGetCollection extends IGet
{
    skip?: number;
    take?: number;
}

export enum MedicalReviewStatus
{
    New = 1,
    InProgress = 2,
    Complete = 3,
    Cancelled = 4,
}

export class AdverseEventItem implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public studyEventId?: number;
    public eventCrfId?: number;
    public event: string;
    public systemsOrganClass: string;
    public aeTermId?: number;
    public aeTerm: string;
    public aeTermSpecify: string;
    public grade?: number;
    public onset?: string;
    public outcome: string;
    public resolution?: string;
    public serious: string;
    public relationshipToTreatment: string;
    public actionTakenNivolumab: string;
    public actionTakenPaclitaxel: string;
    public actionTakenCarboplatin: string;
    public annotations: string;
    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<AdverseEventItem>) { (Object as any).assign(this, init); }
}

export interface IFormGetSingleByMedicalReviewIdAndRepeat extends IGet
{
    medicalReviewId?: number;
    repeat?: number;
}

export interface IFormPostUpdateById extends IPost
{
    id?: number;
    metadata: { [index: string]: Object; };
}

export class Annotation implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public discrepancyNoteId?: number;
    public studyEventId?: number;
    public eventName: string;
    public eventRepeat?: number;
    public eventCrfId?: number;
    public crfName: string;
    public entityName: string;
    public entityValue: string;
    public description: string;
    public detailedNote: string;
    public groupName: string;
    public groupRepeat?: number;
    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<Annotation>) { (Object as any).assign(this, init); }
}

export class BreastCancerRecurrenceItem implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public studyEventId?: number;
    public eventCrfId?: number;
    public event: string;
    public type: string;
    public location: string;
    public dateOfFirstSuspicion?: string;
    public dateOfConfirmation?: string;
    public timeOnStudy?: number;
    public typeOfConfirmation: string;
    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<BreastCancerRecurrenceItem>) { (Object as any).assign(this, init); }
}

export class BreastUltrasound implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public timepoint: string;
    public side: string;
    public location: string;
    public dimensionOne?: number;
    public dimensionTwo?: number;
    public response: string;
    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<BreastUltrasound>) { (Object as any).assign(this, init); }
}

export class CarboplatinTherapy implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public studyEventId?: number;
    public eventCrfId?: number;
    public eventName: string;
    public administered?: boolean;
    public notAdministeredReason: string;
    public date?: string;
    public expectedDoseCalvert?: number;
    public doseAdministered?: number;
    public reduced?: boolean;
    public reducedReason: string;
    public doseDelayed?: boolean;
    public doseDelayedReason: string;
    public lastDose?: boolean;
    public lastDoseReason: string;
    public lastDoseReasonSpecification: string;
    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<CarboplatinTherapy>) { (Object as any).assign(this, init); }
}

export class Chemistry implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public studyEventId?: number;
    public eventCrfId?: number;
    public eventName: string;
    public assessmentPerformed?: boolean;
    public assessmentNotDoneReason: string;
    public date?: string;
    public totalBilirubin?: number;
    public totalBilirubinSign: string;
    public totalBilirubinNotDone?: boolean;
    public totalBilirubinUnits: string;
    public totalBilirubinLln?: number;
    public totalBilirubinUln?: number;
    public aspartateTransaminase?: number;
    public aspartateTransaminaseSign: string;
    public aspartateTransaminaseNotDone?: boolean;
    public aspartateTransaminaseUnits: string;
    public aspartateTransaminaseLln?: number;
    public aspartateTransaminaseUln?: number;
    public alanineTransaminase?: number;
    public alanineTransaminaseSign: string;
    public alanineTransaminaseNotDone?: boolean;
    public alanineTransaminaseUnits: string;
    public alanineTransaminaseLln?: number;
    public alanineTransaminaseUln?: number;
    public alkalinePhosphatase?: number;
    public alkalinePhosphataseSign: string;
    public alkalinePhosphataseNotDone?: boolean;
    public alkalinePhosphataseUnits: string;
    public alkalinePhosphataseLln?: number;
    public alkalinePhosphataseUln?: number;
    public creatinine?: number;
    public creatinineSign: string;
    public creatinineNotDone?: boolean;
    public creatinineUnits: string;
    public creatinineLln?: number;
    public creatinineUln?: number;
    public calcium?: number;
    public calciumSign: string;
    public calciumNotDone?: boolean;
    public calciumUnits: string;
    public calciumLln?: number;
    public calciumUln?: number;
    public lactateDehydrogenase?: number;
    public lactateDehydrogenaseSign: string;
    public lactateDehydrogenaseNotDone?: boolean;
    public lactateDehydrogenaseUnits: string;
    public lactateDehydrogenaseLln?: number;
    public lactateDehydrogenaseUln?: number;
    public totalProtein?: number;
    public totalProteinSign: string;
    public totalProteinNotDone?: boolean;
    public totalProteinUnits: string;
    public totalProteinLln?: number;
    public totalProteinUln?: number;
    public amylase?: number;
    public amylaseSign: string;
    public amylaseNotDone?: boolean;
    public amylaseUnits: string;
    public amylaseLln?: number;
    public amylaseUln?: number;
    public lipase?: number;
    public lipaseSign: string;
    public lipaseNotDone?: boolean;
    public lipaseUnits: string;
    public lipaseLln?: number;
    public lipaseUln?: number;
    public bloodUreaNitrogen?: number;
    public bloodUreaNitrogenSign: string;
    public bloodUreaNitrogenNotDone?: boolean;
    public bloodUreaNitrogenUnits: string;
    public bloodUreaNitrogenLln?: number;
    public bloodUreaNitrogenUln?: number;
    public serumUrea?: number;
    public serumUreaSign: string;
    public serumUreaNotDone?: boolean;
    public serumUreaUnits: string;
    public serumUreaLln?: number;
    public serumUreaUln?: number;
    public potassium?: number;
    public potassiumSign: string;
    public potassiumNotDone?: boolean;
    public potassiumUnits: string;
    public potassiumLln?: number;
    public potassiumUln?: number;
    public gammaGlutamylTransferase?: number;
    public gammaGlutamylTransferaseSign: string;
    public gammaGlutamylTransferaseNotDone?: boolean;
    public gammaGlutamylTransferaseUnits: string;
    public gammaGlutamylTransferaseLln?: number;
    public gammaGlutamylTransferaseUln?: number;
    public sodium?: number;
    public sodiumSign: string;
    public sodiumNotDone?: boolean;
    public sodiumUnits: string;
    public sodiumLln?: number;
    public sodiumUln?: number;
    public uricAcid?: number;
    public uricAcidSign: string;
    public uricAcidNotDone?: boolean;
    public uricAcidUnits: string;
    public uricAcidLln?: number;
    public uricAcidUln?: number;
    public bloodGlucose?: number;
    public bloodGlucoseSign: string;
    public bloodGlucoseNotDone?: boolean;
    public bloodGlucoseUnits: string;
    public bloodGlucoseLln?: number;
    public bloodGlucoseUln?: number;
    public cortisol?: number;
    public cortisolSign: string;
    public cortisolNotDone?: boolean;
    public cortisolUnits: string;
    public cortisolLln?: number;
    public cortisolUln?: number;
    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<Chemistry>) { (Object as any).assign(this, init); }
}

export class Coagulation implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public studyEventId?: number;
    public eventCrfId?: number;
    public eventName: string;
    public assessmentPerformed?: boolean;
    public assessmentNotDoneReason: string;
    public date?: string;
    public partialThromboplastinTime?: number;
    public partialThromboplastinTimeSign: string;
    public partialThromboplastinTimeNotDone?: boolean;
    public partialThromboplastinTimeUnits: string;
    public partialThromboplastinTimeLln?: number;
    public partialThromboplastinTimeUln?: number;
    public activatedPartialThromboplastinTime?: number;
    public activatedPartialThromboplastinTimeSign: string;
    public activatedPartialThromboplastinTimeNotDone?: boolean;
    public activatedPartialThromboplastinTimeUnits: string;
    public activatedPartialThromboplastinTimeLln?: number;
    public activatedPartialThromboplastinTimeUln?: number;
    public internationalNormalisedRatio?: number;
    public internationalNormalisedRatioSign: string;
    public internationalNormalisedRatioNotDone?: boolean;
    public internationalNormalisedRatioUnits: string;
    public internationalNormalisedRatioLln?: number;
    public internationalNormalisedRatioUln?: number;
    public prothombinTime?: number;
    public prothombinTimeSign: string;
    public prothombinTimeNotDone?: boolean;
    public prothombinTimeUnits: string;
    public prothombinTimeLln?: number;
    public prothombinTimeUln?: number;
    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<Coagulation>) { (Object as any).assign(this, init); }
}

export class ConcomitantMedicationItem implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public studyEventId?: number;
    public eventCrfId?: number;
    public event: string;
    public medication: string;
    public indication: string;
    public dateStarted?: string;
    public dateStopped?: string;
    public ongoing?: boolean;
    public annotations: string;
    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<ConcomitantMedicationItem>) { (Object as any).assign(this, init); }
}

export class DeathReportItem implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public studyEventId?: number;
    public eventCrfId?: number;
    public event: string;
    public dateOfDeath?: string;
    public timeOnStudy?: number;
    public causeOfDeath: string;
    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<DeathReportItem>) { (Object as any).assign(this, init); }
}

export class Haematology implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public studyEventId?: number;
    public eventCrfId?: number;
    public eventName: string;
    public timepoint: string;
    public assessmentPerformed?: boolean;
    public assessmentNotDoneReason: string;
    public date?: string;
    public haemoglobin?: number;
    public haemoglobinSign: string;
    public haemoglobinNotDone?: boolean;
    public haemoglobinUnits: string;
    public haemoglobinLln?: number;
    public haemoglobinUln?: number;
    public rbcCount?: number;
    public rbcCountSign: string;
    public rbcCountNotDone?: boolean;
    public rbcCountUnits: string;
    public rbcCountLln?: number;
    public rbcCountUln?: number;
    public totalNeutrophils?: number;
    public totalNeutrophilsSign: string;
    public totalNeutrophilsNotDone?: boolean;
    public totalNeutrophilsUnits: string;
    public totalNeutrophilsLln?: number;
    public totalNeutrophilsUln?: number;
    public platelets?: number;
    public plateletsSign: string;
    public plateletsNotDone?: boolean;
    public plateletsUnits: string;
    public plateletsLln?: number;
    public plateletsUln?: number;
    public lymphocytes?: number;
    public lymphocytesSign: string;
    public lymphocytesNotDone?: boolean;
    public lymphocytesUnits: string;
    public lymphocytesLln?: number;
    public lymphocytesUln?: number;
    public wbcCount?: number;
    public wbcCountSign: string;
    public wbcCountNotDone?: boolean;
    public wbcCountUnits: string;
    public wbcCountLln?: number;
    public wbcCountUln?: number;
    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<Haematology>) { (Object as any).assign(this, init); }
}

export class MedicalHistoryItem implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public medicalCondition: string;
    public currentAtRegistration: string;
    public dateOfDiagnosis: string;
    public treatmentOngoing: string;
    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<MedicalHistoryItem>) { (Object as any).assign(this, init); }
}

export class MedicalHistoryMedication implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public medicationName: string;
    public indication: string;
    public startDate: string;
    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<MedicalHistoryMedication>) { (Object as any).assign(this, init); }
}

export class NivolumabTherapy implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public studyEventId?: number;
    public eventCrfId?: number;
    public eventName: string;
    public administered?: boolean;
    public notAdministeredReason: string;
    public date?: string;
    public doseAdministered?: number;
    public expectedDose?: number;
    public doseDelayed?: boolean;
    public doseDelayedReason: string;
    public lastDose?: boolean;
    public lastDoseReason: string;
    public lastDoseReasonSpecification: string;
    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<NivolumabTherapy>) { (Object as any).assign(this, init); }
}

export class PaclitaxelTherapy implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public studyEventId?: number;
    public eventCrfId?: number;
    public eventName: string;
    public timepoint: string;
    public administered?: boolean;
    public notAdministeredReason: string;
    public date?: string;
    public doseAdministered?: number;
    public reduced?: boolean;
    public reducedReason: string;
    public omitted?: boolean;
    public omittedReason: string;
    public lastDose?: boolean;
    public lastDoseReason: string;
    public lastDoseReasonSpecification: string;
    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<PaclitaxelTherapy>) { (Object as any).assign(this, init); }
}

export class ProtocolDeviation implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public discrepancyNoteId?: number;
    public studyEventId?: number;
    public eventName: string;
    public eventRepeat?: number;
    public eventCrfId?: number;
    public crfName: string;
    public entityName: string;
    public entityValue: string;
    public description: string;
    public detailedNote: string;
    public groupName: string;
    public groupRepeat?: number;
    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<ProtocolDeviation>) { (Object as any).assign(this, init); }
}

export class ImportantProtocolDeviation implements IMedicalReviewForm {
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public discrepancyNoteId?: number;
    public studyEventId?: number;
    public eventName: string;
    public eventRepeat?: number;
    public eventCrfId?: number;
    public crfName: string;
    public entityName: string;
    public entityValue: string;
    public description: string;
    public detailedNote: string;
    public groupName: string;
    public groupRepeat?: number;
    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<ImportantProtocolDeviation>) { (Object as any).assign(this, init); }
}
export class SecondPrimaryMalignancyItem implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public studyEventId?: number;
    public eventCrfId?: number;
    public event: string;
    public location: string;
    public dateOfFirstSuspicion?: string;
    public dateOfConfirmation?: string;
    public timeOnStudy?: number;
    public typeOfConfirmation: string;
    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<SecondPrimaryMalignancyItem>) { (Object as any).assign(this, init); }
}

export class SeriousAdverseEventItem implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public studyEventId?: number;
    public opmsSaeId?: number;
    public saeNumber?: number;
    public term: string;
    public onset?: string;
    public outcome: string;
    public outcomeDate?: string;
    public suspectDrugOrCause: string;
    public expectedness: string;
    public comments: string;
    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<SeriousAdverseEventItem>) { (Object as any).assign(this, init); }
}

export class SlnbResult implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public nodeNumber?: number;
    public location: string;
    public tumourInvolvement: string;
    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<SlnbResult>) { (Object as any).assign(this, init); }
}

export class SourceDocument implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public section: string;
    public type: string;
    public name: string;
    public path: string;
    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<SourceDocument>) { (Object as any).assign(this, init); }
}

export class SurvivalFollowUpItem implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public studyEventId?: number;
    public eventCrfId?: number;
    public event: string;
    public visitNumber?: number;
    public dateOfVisit?: string;
    public methodOfReview: string;
    public changesObserved: string;
    public antiCancerTreatment: string;
    public dateCommenced: string;
    public dateCompleted: string;
    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<SurvivalFollowUpItem>) { (Object as any).assign(this, init); }
}

export class ThyroidFunction implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public studyEventId?: number;
    public eventCrfId?: number;
    public eventName: string;
    public assessmentPerformed: string;
    public assessmentNotDoneReason: string;
    public date?: string;
    public tsh?: number;
    public tshSign: string;
    public tshUnits: string;
    public tshNotDone?: boolean;
    public tshLln?: number;
    public tshUln?: number;
    public t3?: number;
    public t3Sign: string;
    public t3Units: string;
    public t3NotDone?: boolean;
    public t3Lln?: number;
    public t3Uln?: number;
    public t4?: number;
    public t4Sign: string;
    public t4Units: string;
    public t4NotDone?: boolean;
    public t4Lln?: number;
    public t4Uln?: number;
    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<ThyroidFunction>) { (Object as any).assign(this, init); }
}

export enum QueryStatus
{
    Issued = 1,
    Responded = 2,
    Closed = 3,
    Cancelled = 4,
}

export class Query implements IQuery
{
    public id?: number;
    public medicalReviewId?: number;
    public formDefinitionId?: number;
    public formId?: number;
    public propertyName: string;
    public parentQueryId?: number;
    // @Ignore()
    public queryNumber?: number;

    public repeat?: number;
    public question: string;
    public response: string;
    public recipientId?: number;
    public status?: QueryStatus;
    public issuedNotificationDate?: string;
    public respondedNotificationDate?: string;
    public cancelledNotificationDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<Query>) { (Object as any).assign(this, init); }
}

export class AdverseEvents implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public studyEventId?: number;
    public eventCrfId?: number;
    public event: string;
    // @Ignore()
    public onStudyAdverseEventItems: AdverseEventItem[];

    // @Ignore()
    public registrationAdverseEventItems: MedicalHistoryItem[];

    // @Ignore()
    public seriousAdverseEventItems: SeriousAdverseEventItem[];

    // @Ignore()
    public sourceDocuments: SourceDocument[];

    // @Ignore()
    public annotations: Annotation[];

    // @Ignore()
    public queries: Query[];

    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<AdverseEvents>) { (Object as any).assign(this, init); }
}

export class ConcomitantMedications implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public studyEventId?: number;
    public eventCrfId?: number;
    public event: string;
    // @Ignore()
    public registrationConcomitantMedicationItems: MedicalHistoryMedication[];

    // @Ignore()
    public onStudyConcomitantMedicationItems: ConcomitantMedicationItem[];

    // @Ignore()
    public sourceDocuments: SourceDocument[];

    // @Ignore()
    public annotations: Annotation[];

    // @Ignore()
    public queries: Query[];

    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<ConcomitantMedications>) { (Object as any).assign(this, init); }
}

export class GeneralInformation implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public waivered?: boolean;
    public waiverDate?: string;
    public waiverReason: string;
    // @Ignore()
    public protocolDeviations: ProtocolDeviation[];
    // @Ignore()
    public importantProtocolDeviations: ImportantProtocolDeviation[];

    public comments: string;
    public inevaluable?: boolean;
    // @Ignore()
    public queries: Query[];

    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<GeneralInformation>) { (Object as any).assign(this, init); }
}

export class Histopathology implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public dateOfHistopathDignosis?: string;
    public primaryTumourSite: string;
    public clinicalStagingCt: string;
    public clinicalStagingCn: string;
    public clinicalStagingCm: string;
    public histopathologyType: string;
    public histopathologyTypeSpec: string;
    public stageAtDiagnosis: string;
    public oestrogenSymbol: string;
    public oestrogenValue?: number;
    public progesteroneSymbol: string;
    public progesteroneValue?: number;
    public ihcScore: string;
    public ishScore: string;
    public tilNotDone?: boolean;
    public tilCountSymbol: string;
    public tilCountValue?: number;
    public pdlTestingCompleted?: boolean;
    public pdlAssayType: string;
    public pdlAssayTypeSpec: string;
    public pdlResults: string;
    public pdlResultsValue?: number;
    public brcaGermlineStatus: string;
    // @Ignore()
    public sourceDocuments: SourceDocument[];

    // @Ignore()
    public annotations: Annotation[];

    // @Ignore()
    public queries: Query[];

    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<Histopathology>) { (Object as any).assign(this, init); }
}

export class Imaging implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public breastUltrasoundDate?: string;
    public breastUltrasoundClipNotDone?: boolean;
    public breastUltrasoundClipDate?: string;
    public breastUltrasoundLesionSideId: string;
    public breastUltrasoundLesionLocationId: string;
    public breastUltrasoundLesionDimensionOne?: number;
    public breastUltrasoundLesionDimensionTwo?: number;
    public axillaUltrasoundDate?: string;
    public axillaUltrasoundSuspiciousNodes?: number;
    public axillaUltrasoundClipNotDone?: boolean;
    public axillaUltrasoundClipDate?: string;
    public clinicalExamNotDone?: boolean;
    public clinicalExamDate?: string;
    public clinicalExamSuspiciousNodes?: number;
    public cytologyNotDone?: boolean;
    public cytologyDate?: string;
    public cytologyResults: string;
    public mammogramDate?: string;
    public mammogramLeftDate?: string;
    public mammogramRightDate?: string;
    public abdomenUltrasoundNotDone?: boolean;
    public abdomenUltrasoundNotAvailable?: boolean;
    public abdomenUltrasoundFinding: string;
    public abdomenUltrasoundDate?: string;
    public chestXrayNotDone?: boolean;
    public chestXrayFindings: string;
    public chestXrayDate?: string;
    public ctScanNotDone?: boolean;
    public ctScanFindings: string;
    public ctScanDate?: string;
    public petScanNotDone?: boolean;
    public petScanFindings: string;
    public petScanDate?: string;
    public boneScanNotDone?: boolean;
    public boneScanFindings: string;
    public boneScanDate?: string;
    // @Ignore()
    public sourceDocuments: SourceDocument[];

    // @Ignore()
    public annotations: Annotation[];

    // @Ignore()
    public queries: Query[];

    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<Imaging>) { (Object as any).assign(this, init); }
}

export class MedicalHistory implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    // @Ignore()
    public medicalHistoryItems: MedicalHistoryItem[];

    // @Ignore()
    public medicalHistoryMedications: MedicalHistoryMedication[];

    // @Ignore()
    public sourceDocuments: SourceDocument[];

    // @Ignore()
    public annotations: Annotation[];

    // @Ignore()
    public queries: Query[];

    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<MedicalHistory>) { (Object as any).assign(this, init); }
}

export class NeonTherapy implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public treatmentExposureNivolumab?: number;
    public treatmentExposureCarboplatin?: number;
    public treatmentExposurePaclitaxel?: number;
    public treatmentExposureSurgery: string;
    public treatment: string;
    public plannedStartDate?: string;
    public expectedDoseMosteller?: number;
    public expectedDoseDubois?: number;
    public expectedDoseCalvert?: number;
    // @Ignore()
    public nivolumabTherapies: NivolumabTherapy[];

    // @Ignore()
    public paclitaxelTherapies: PaclitaxelTherapy[];

    // @Ignore()
    public carboplatinTherapies: CarboplatinTherapy[];

    // @Ignore()
    public sourceDocuments: SourceDocument[];

    // @Ignore()
    public annotations: Annotation[];

    // @Ignore()
    public queries: Query[];

    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<NeonTherapy>) { (Object as any).assign(this, init); }
}

export class OrganFunction implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public haematologyDate?: string;
    public haemoglobin?: number;
    public haemoglobinUnits: string;
    public haemoglobinLln?: number;
    public haemoglobinUln?: number;
    public haemoglobinEligibilityLln?: number;
    public rbcCount?: number;
    public rbcCountLln?: number;
    public rbcCountUln?: number;
    public totalNeutrophils?: number;
    public totalNeutrophilsLln?: number;
    public totalNeutrophilsUln?: number;
    public totalNeutrophilsEligibilityLln?: number;
    public platelets?: number;
    public plateletsLln?: number;
    public plateletsUln?: number;
    public plateletsEligibilityLln?: number;
    public lymphocytes?: number;
    public lymphocytesLln?: number;
    public lymphocytesUln?: number;
    public wbcCount?: number;
    public wbcCountLln?: number;
    public wbcCountUln?: number;
    public wbcCountEligibilityLln?: number;
    public biochemistryDate?: string;
    public gilbertSyndrome: string;
    public totalBilirubin?: number;
    public totalBilirubinLln?: number;
    public totalBilirubinUln?: number;
    public totalBilirubinEligibilityUln?: number;
    public aspartateTransaminase?: number;
    public aspartateTransaminaseLln?: number;
    public aspartateTransaminaseUln?: number;
    public astEligibilityUln?: number;
    public alanineTransaminase?: number;
    public alanineTransaminaseLln?: number;
    public alanineTransaminaseUln?: number;
    public altEligibilityUln?: number;
    public alkalinePhosphatase?: number;
    public alkalinePhosphataseLln?: number;
    public alkalinePhosphataseUln?: number;
    public creatinine?: number;
    public creatinineLln?: number;
    public creatinineUln?: number;
    public creatinineEligibilityUln?: number;
    public calculatedCreatinineClearance?: number;
    public calculatedCreatinineEligibilityLln?: number;
    public calcium?: number;
    public calciumLln?: number;
    public calciumUln?: number;
    public lactateDehydrogenase?: number;
    public lactateDehydrogenaseLln?: number;
    public lactateDehydrogenaseUln?: number;
    public totalProtein?: number;
    public totalProteinLln?: number;
    public totalProteinUln?: number;
    public amylase?: number;
    public amylaseLln?: number;
    public amylaseUln?: number;
    public lipase?: number;
    public lipaseLln?: number;
    public lipaseUln?: number;
    public bloodUreaNitrogen?: number;
    public bloodUreaNitrogenLln?: number;
    public bloodUreaNitrogenUln?: number;
    public serumUrea?: number;
    public serumUreaLln?: number;
    public serumUreaUln?: number;
    public potassium?: number;
    public potassiumLln?: number;
    public potassiumUln?: number;
    public gammaGlutamylTransferase?: number;
    public ggtLln?: number;
    public ggtUln?: number;
    public sodium?: number;
    public sodiumLln?: number;
    public sodiumUln?: number;
    public uricAcid?: number;
    public uricAcidLln?: number;
    public uricAcidUln?: number;
    public thyroidFunctionDate?: string;
    public tsh?: number;
    public tshLln?: number;
    public tshUln?: number;
    public t3?: number;
    public t3Lln?: number;
    public t3Uln?: number;
    public t4?: number;
    public t4Lln?: number;
    public t4Uln?: number;
    public ecg?: string;
    public mugaOrEcho: string;
    public mugaOrEchoValue?: number;
    public mugaOrEchoAssessmentDate?: string;
    // @Ignore()
    public sourceDocuments: SourceDocument[];

    // @Ignore()
    public annotations: Annotation[];

    // @Ignore()
    public queries: Query[];

    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<OrganFunction>) { (Object as any).assign(this, init); }
}

export class OrganFunctionTreatment implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    // @Ignore()
    public haematology: Haematology[];

    // @Ignore()
    public chemistry: Chemistry[];

    // @Ignore()
    public thyroidFunction: ThyroidFunction[];

    // @Ignore()
    public coagulation: Coagulation[];

    // @Ignore()
    public sourceDocuments: SourceDocument[];

    // @Ignore()
    public annotations: Annotation[];

    // @Ignore()
    public queries: Query[];

    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<OrganFunctionTreatment>) { (Object as any).assign(this, init); }
}

export class Surgery implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public presurgeryEventCrfId?: number;
    public presurgeryUltrasoundPerformed: string;
    public presurgeryUltrasoundDate?: string;
    public presurgeryUltrasoundNotDoneReason: string;
    public presurgeryUltrasoundNewLeisons: string;
    public presurgeryUltrasoundSuspiciousNodesIdentified: string;
    public presurgeryUltrasoundSuspiciousNodeCount?: number;
    // @Ignore()
    public presurgeryBreastUltrasound: BreastUltrasound[];

    public presurgeryMammogramPerformed: string;
    public presurgeryMammogramDate?: string;
    public presurgeryMammogramNotDoneReason: string;
    public presurgeryMammogramNewLeisons: string;
    public presurgeryMammogramSuspiciousNodesIdentified: string;
    public presurgeryMammogramSuspiciousNodeCount?: number;
    public surgeryEventCrfId?: number;
    public surgeryPerformed: string;
    public surgeryNotDoneReason: string;
    public surgeryBiopsyPerformed: string;
    public surgeryBiopsyDate?: string;
    public surgeryLastTreatmentDate?: string;
    public surgeryBreastConservingSurgeryDate?: string;
    public surgeryBreastConservingSurgeryReexcisionOneDate?: string;
    public surgerySkinSparingMastectomyDate?: string;
    public surgeryMastectomyReconstructionDate?: string;
    public surgeryBreastConservingSurgeryReexcisionTwoDate?: string;
    public surgeryMastectomyDate?: string;
    public surgeryOncoplasticSurgeryDate?: string;
    public surgeryOther: string;
    public surgeryOtherDate?: string;
    public surgerySlnbPerformed: string;
    public surgerySlnbDate?: string;
    public surgerySnRemoved?: number;
    public surgeryNonSnRemoved?: number;
    // @Ignore()
    public surgerySlnbResults: SlnbResult[];

    public surgeryAxillaryDissectionPerformed: string;
    public surgeryAxillaryDissectionDate?: string;
    public surgeryAxillaryDissectionNodesRemoved?: number;
    public surgeryAxillaryDissectionPositiveNodes?: number;
    public surgeryAxillaryRtPlanned: string;
    public histopathologyEventCrfId?: number;
    public histopathologyNumberOfParaffinBlocks: string;
    public histopathologyResidualDiseaseInvasive?: boolean;
    public histopathologyResidualDiseaseInSitu?: boolean;
    public histopathologyResidualDiseaseNone?: boolean;
    public histopathologyType: string;
    public histopathologyGrade: string;
    public histopathologyLymphovascularInvasion: string;
    public histopathologyFocality: string;
    public histopathologyLargestUnilateralMeasurement?: number;
    public histopathologyMarginsClearOfInvasiveBreastCancer: string;
    public histopathologyMarginsClearOfInSitu: string;
    public histopathologyTStage: string;
    public histopathologyNStage: string;
    public histopathologyPercentageTil?: number;
    public histopathologyPdlCompleted: string;
    public histopathologyPdlAssayType: string;
    public histopathologyPdlResults: string;
    public histopathologyPdlValue?: number;
    public histopathologyRcbDimensionOne?: number;
    public histopathologyRcbDimensionTwo?: number;
    public histopathologyRcbCancerCellularity?: number;
    public histopathologyRcbInSituDisease?: number;
    public histopathologyRcbNumberPositiveNodes?: number;
    public histopathologyRcbLargestDiameter?: number;
    public histopathologyRcb?: number;
    public histopathologyRcbClass: string;
    // @Ignore()
    public sourceDocuments: SourceDocument[];

    // @Ignore()
    public annotations: Annotation[];

    // @Ignore()
    public queries: Query[];

    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<Surgery>) { (Object as any).assign(this, init); }
}

export class SurvivalAndRecurrence implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    // @Ignore()
    public survivalFollowUpItems: SurvivalFollowUpItem[];

    // @Ignore()
    public breastCancerRecurrenceItems: BreastCancerRecurrenceItem[];

    // @Ignore()
    public secondPrimaryMalignancyItems: SecondPrimaryMalignancyItem[];

    // @Ignore()
    public deathReportItems: DeathReportItem[];

    // @Ignore()
    public sourceDocuments: SourceDocument[];

    // @Ignore()
    public annotations: Annotation[];

    // @Ignore()
    public queries: Query[];

    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<SurvivalAndRecurrence>) { (Object as any).assign(this, init); }
}

export class MasterGroup implements IMasterGroup
{
    public id?: number;
    public code: string;
    public name: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<MasterGroup>) { (Object as any).assign(this, init); }
}

export class CoordinatingGroup implements ICoordinatingGroup
{
    public id?: number;
    public masterGroupId?: number;
    public code: string;
    public name: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<CoordinatingGroup>) { (Object as any).assign(this, init); }
}

export class Country implements ICountry
{
    public id?: number;
    public code: string;
    public name: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<Country>) { (Object as any).assign(this, init); }
}

export class Institution implements IInstitution
{
    public id?: number;
    public countryId?: number;
    public masterGroupId?: number;
    public coordinatingGroupId?: number;
    public code: string;
    public name: string;
    public enabled?: boolean;
    public activatedDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<Institution>) { (Object as any).assign(this, init); }
}

export class MedicalReviewPatient implements IMedicalReviewPatient
{
    public id?: number;
    public institutionId?: number;
    public studyNumber: string;
    public treatmentArm: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;
    // @Ignore()
    public medicalReviewers: number[];

    // @Ignore()
    public requiresAttention: number[];

    // @Ignore()
    public reviewStatus?: MedicalReviewStatus;

    public constructor(init?: Partial<MedicalReviewPatient>) { (Object as any).assign(this, init); }
}

export interface IPatientGetSingleByStudyNumber
{
    studyNumber: string;
}

export class MedicalReviewRequestContext
{
    public masterGroupId?: number;
    public coordinatingGroupId?: number;
    public countryId?: number;
    public institutionId?: number;
    public patientId?: number;
    public reviewerIds: number[];
    public medicalReviewId?: number;
    public reviewerId?: number;
    public formDefinitionId?: number;
    public formId?: number;
    public fileId?: number;
    public queryId?: number;

    public constructor(init?: Partial<MedicalReviewRequestContext>) { (Object as any).assign(this, init); }
}

export enum MedicalReviewPermission
{
    Access = 1,
    ViewMasterGroup = 2,
    ViewCoordinatingGroup = 3,
    ViewCountry = 4,
    ViewInstitution = 5,
    ViewPatient = 6,
    ViewMedicalReview = 7,
    ViewMedicalReviewForm = 8,
    ViewQuery = 9,
    CreateMedicalReview = 10,
    CompleteMedicalReview = 11,
    CancelMedicalReview = 12,
    CompleteMedicalReviewForm = 13,
    IssueQuery = 14,
    RespondToQuery = 15,
    CloseQuery = 16,
    ViewAllPatients = 17,
    CancelQuery = 18,
    UpdateMedicalReview = 19,
}

export class Reviewer implements IReviewer
{
    public id?: number;
    public onTrial?: boolean;
    public masterGroups: { [index: number]: boolean; };
    public coordinatingGroups: { [index: number]: boolean; };
    public institutions: { [index: number]: boolean; };
    public usernames: string[];
    public name: string;
    public email: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<Reviewer>) { (Object as any).assign(this, init); }
}

export class MedicalReview implements IMedicalReview
{
    public id?: number;
    public patientId?: number;
    public medicalReviewDefinitionId?: number;
    public status?: MedicalReviewStatus;
    public reviewerId?: number;
    public repeat?: number;
    public startDate?: string;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public formDefinitionIds: number[];
    public notificationDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<MedicalReview>) { (Object as any).assign(this, init); }
}

export interface IMedicalReviewGetSingleByPatientIdAndRepeat extends IGet
{
    patientId?: number;
    repeat?: number;
}

export interface IMedicalReviewGetCollectionByPatientId extends IGet
{
    patientId?: number;
}

export class MedicalReviewCreationOptions
{
    public formDefinitionIds: number[];
    public includeUnchangedForms?: boolean;
    public compareFormsAcrossMedicalReviewDefinitions?: boolean;

    public constructor(init?: Partial<MedicalReviewCreationOptions>) { (Object as any).assign(this, init); }
}

export class MedicalReviewUpdateOptions extends MedicalReviewCreationOptions
{
    public addNewForms?: boolean;
    public deleteOldForms?: boolean;

    public constructor(init?: Partial<MedicalReviewUpdateOptions>) { super(init); (Object as any).assign(this, init); }
}

export interface IMedicalReviewPostUpdateReview extends IPost
{
    metadata: { [index: string]: Object; };
    medicalReviewId?: number;
    options: MedicalReviewUpdateOptions;
}

export interface IMedicalReviewPostFormValidationCollectionById extends IPost
{
    id?: number;
    metadata: { [index: string]: Object; };
}

export interface IQueryGetCollectionByMedicalReviewId extends IGet
{
    medicalReviewId?: number;
}

export class UpdatedFormProperty implements IUpdatedFormProperty
{
    public id?: number;
    public formDefinitionId?: number;
    public formId?: number;
    public propertyPath: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<UpdatedFormProperty>) { (Object as any).assign(this, init); }
}

export interface IUpdatedFormPropertyGetCollectionByFormDefinitionIdAndFormId extends IGet
{
    formDefinitionId?: number;
    formId?: number;
}

// @DataContract
export class ResponseError
{
    // @DataMember(Order=1)
    public errorCode: string;

    // @DataMember(Order=2)
    public fieldName: string;

    // @DataMember(Order=3)
    public message: string;

    // @DataMember(Order=4)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<ResponseError>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ResponseStatus
{
    // @DataMember(Order=1)
    public errorCode: string;

    // @DataMember(Order=2)
    public message: string;

    // @DataMember(Order=3)
    public stackTrace: string;

    // @DataMember(Order=4)
    public errors: ResponseError[];

    // @DataMember(Order=5)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<ResponseStatus>) { (Object as any).assign(this, init); }
}

export class EmailAddress
{
    public displayName: string;
    public address: string;

    public constructor(init?: Partial<EmailAddress>) { (Object as any).assign(this, init); }
}

export class IdentityServerExceptionInfo
{
    public message: string;
    public stackTrace: string;
    public innerException: IdentityServerExceptionInfo;

    public constructor(init?: Partial<IdentityServerExceptionInfo>) { (Object as any).assign(this, init); }
}

export class IdentityServerDiagnostic
{
    public test: string;
    public pass: boolean;
    public exception: IdentityServerExceptionInfo;
    public values: { [index: string]: Object; };

    public constructor(init?: Partial<IdentityServerDiagnostic>) { (Object as any).assign(this, init); }
}

// @DataContract
export class UserApiKey
{
    // @DataMember(Order=1)
    public key: string;

    // @DataMember(Order=2)
    public keyType: string;

    // @DataMember(Order=3)
    public expiryDate?: string;

    // @DataMember(Order=4)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<UserApiKey>) { (Object as any).assign(this, init); }
}

export interface IFileUploadTemporary
{
    guid: string;
    name: string;
    extension: string;
    fileSize?: number;
    contentType: string;
    image: boolean;
}

export class User implements IUserDto
{
    public userId: string;
    public userName: string;
    public displayName: string;
    public sessionId: string;

    public constructor(init?: Partial<User>) { (Object as any).assign(this, init); }
}

export class LookupItem
{
    public id?: number;
    public value: string;
    public order?: number;

    public constructor(init?: Partial<LookupItem>) { (Object as any).assign(this, init); }
}

export class Lookup
{
    public propertyName: string;
    public items: LookupItem[];

    public constructor(init?: Partial<Lookup>) { (Object as any).assign(this, init); }
}

export class FormMetadata
{
    public formDefinitionId?: number;
    public formDefinitionCode: string;
    public name: string;
    public propertyName: string;
    public title: string;
    public instructions: string;

    public constructor(init?: Partial<FormMetadata>) { (Object as any).assign(this, init); }
}

export class FormType implements IFormType
{
    public id?: number;
    public code: string;
    public name: string;

    public constructor(init?: Partial<FormType>) { (Object as any).assign(this, init); }
}

export enum ValidationErrorType
{
    Warning = 1,
    Low = 2,
    Normal = 3,
    High = 4,
    Critical = 5,
}

export class FormDefinitionPropertyErrorMessage implements IFormDefinitionPropertyErrorMessage
{
    public id?: number;
    // @References("typeof(NewtonGreen.ServiceStack.Forms.Models.Configuration.FormDefinitionProperty)")
    public formDefinitionPropertyId?: number;

    public errorCode: string;
    public message: string;
    public detailedMessage: string;
    public type?: ValidationErrorType;

    public constructor(init?: Partial<FormDefinitionPropertyErrorMessage>) { (Object as any).assign(this, init); }
}

export class FormDefinitionProperty
{
    public id?: number;
    // @References("typeof(NewtonGreen.ServiceStack.Forms.Models.Configuration.FormDefinition)")
    public formDefinitionId?: number;

    public propertyName: string;
    public label: string;
    public detailedLabel: string;
    public errorMessages: FormDefinitionPropertyErrorMessage[];

    public constructor(init?: Partial<FormDefinitionProperty>) { (Object as any).assign(this, init); }
}

export class FormDefinition
{
    public id?: number;
    public code: string;
    public type?: number;
    public name: string;
    public instructions: string;
    public patientInstructions: string;
    public properties: FormDefinitionProperty[];
    // @Ignore()
    public subformProperties: { [index: string]: FormDefinitionProperty[]; };

    public constructor(init?: Partial<FormDefinition>) { (Object as any).assign(this, init); }
}

export enum ValidationResultType
{
    Valid = 1,
    Low = 2,
    Normal = 3,
    High = 4,
    Critical = 5,
}

export class ValidationError implements IValidationError
{
    public property: string;
    public message: string;
    public detailedMessage: string;
    public code: string;
    public ruleSet: string;
    public type?: ValidationErrorType;

    public constructor(init?: Partial<ValidationError>) { (Object as any).assign(this, init); }
}

export class ValidationResult
{
    public result: ValidationResultType;
    public errors: ValidationError[];
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<ValidationResult>) { (Object as any).assign(this, init); }
}

export class MedicalReviewDefinition implements IMedicalReviewDefinition
{
    public id?: number;
    public code: string;
    public name: string;
    public availableFormDefinitionIds: number[];
    public defaultFormDefinitionIds: number[];

    public constructor(init?: Partial<MedicalReviewDefinition>) { (Object as any).assign(this, init); }
}

export class QueryRecipient implements IQueryRecipient
{
    public id?: number;
    public name: string;

    public constructor(init?: Partial<QueryRecipient>) { (Object as any).assign(this, init); }
}

export interface IMedicalReviewForm extends IForm
{
    medicalReviewId?: number;
    medicalReviewStatus?: MedicalReviewStatus;
    updateDate?: string;
    completeDate?: string;
    cancelDate?: string;
}

export interface IForm extends IDataModel, IHasParentFormDefinitionId, IHasParentFormId
{
    repeat?: number;
}

export interface IDataModel extends IModel, IHasAudit
{
}

export interface IModel
{
    id?: number;
}

export interface IHasAudit
{
    enteredDate?: string;
    enteredBy: string;
    modifiedDate?: string;
    modifiedBy: string;
}

export interface IHasParentFormDefinitionId
{
    parentFormDefinitionId?: number;
}

export interface IHasParentFormId
{
    parentFormId?: number;
}

export interface IMasterGroup extends IDataModel, IHasCode
{
    name: string;
}

export interface IHasCode
{
    code: string;
}

export interface ICoordinatingGroup extends IDataModel, IHasMasterGroupId, IHasCode
{
    name: string;
}

export interface IHasMasterGroupId
{
    masterGroupId?: number;
}

export interface ICountry extends IDataModel, IHasCode
{
    name: string;
}

export interface IInstitution extends IDataModel, IHasMasterGroupId, IHasCoordinatingGroupId, IHasCountryId, IHasCode
{
    name: string;
    enabled?: boolean;
    activatedDate?: string;
}

export interface IHasCoordinatingGroupId
{
    coordinatingGroupId?: number;
}

export interface IHasCountryId
{
    countryId?: number;
}

export interface IMedicalReviewPatient extends IPatient
{
    treatmentArm: string;
    medicalReviewers: number[];
    requiresAttention: number[];
    reviewStatus?: MedicalReviewStatus;
}

export interface IPatient extends IDataModel, IHasInstitutionId
{
    studyNumber: string;
}

export interface IHasInstitutionId
{
    institutionId?: number;
}

export interface IReviewer extends IDataModel
{
    name: string;
    usernames: string[];
    email: string;
    onTrial?: boolean;
    masterGroups: { [index: number]: boolean; };
    coordinatingGroups: { [index: number]: boolean; };
    institutions: { [index: number]: boolean; };
}

export interface IMedicalReview extends IDataModel, IHasPatientId
{
    medicalReviewDefinitionId?: number;
    status?: MedicalReviewStatus;
    reviewerId?: number;
    repeat?: number;
    formDefinitionIds: number[];
    startDate?: string;
    updateDate?: string;
    completeDate?: string;
    cancelDate?: string;
}

export interface IHasPatientId
{
    patientId?: number;
}

export interface IQuery extends IDataModel, IHasFormDefinitionId, IHasFormId
{
    medicalReviewId?: number;
    propertyName: string;
    parentQueryId?: number;
    queryNumber?: number;
    repeat?: number;
    question: string;
    response: string;
    recipientId?: number;
    status?: QueryStatus;
}

export interface IHasFormDefinitionId
{
    formDefinitionId?: number;
}

export interface IHasFormId
{
    formId?: number;
}

export interface IUpdatedFormProperty extends IDataModel
{
    formDefinitionId?: number;
    formId?: number;
    propertyPath: string;
}

export interface IUserDto
{
    userId: string;
    userName: string;
    displayName: string;
    sessionId: string;
}

export interface ILookupItem
{
    id: Object;
    value: Object;
    order: Object;
}

export interface ILookup
{
    propertyName: string;
    items: ILookupItem[];
}

export interface IFormType extends IConfigurationModel, IHasCode
{
    name: string;
}

export interface IConfigurationModel extends IModel
{
}

export interface IFormDefinitionPropertyErrorMessage extends IConfigurationModel, IHasFormDefinitionPropertyId
{
    errorCode: string;
    message: string;
    detailedMessage: string;
    type?: ValidationErrorType;
}

export interface IFormDefinitionProperty extends IConfigurationModel, IHasFormDefinitionId
{
    propertyName: string;
    label: string;
    detailedLabel: string;
    errorMessages: IFormDefinitionPropertyErrorMessage[];
}

export interface IFormDefinition extends IConfigurationModel, IHasCode
{
    type?: number;
    name: string;
    instructions: string;
    properties: IFormDefinitionProperty[];
    subformProperties: { [index: string]: IFormDefinitionProperty[]; };
}

export interface IValidationError
{
    property: string;
    message: string;
    detailedMessage: string;
    code: string;
    type?: ValidationErrorType;
}

export interface IValidationResult
{
    metadata: { [index: string]: Object; };
    result: ValidationResultType;
    errors: IValidationError[];
}

export interface IMedicalReviewDefinition extends IConfigurationModel, IHasCode
{
    name: string;
    availableFormDefinitionIds: number[];
    defaultFormDefinitionIds: number[];
}

export interface IQueryRecipient extends IConfigurationModel
{
    name: string;
}

export interface IHasFormDefinitionPropertyId
{
    formDefinitionPropertyId?: number;
}

export class QueueEmailJobsResponse
{
    public responseStatus: ResponseStatus;
    public count?: number;

    public constructor(init?: Partial<QueueEmailJobsResponse>) { (Object as any).assign(this, init); }
}

export class SendMedicalReviewEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;
    public emailNotRequired: boolean;

    public constructor(init?: Partial<SendMedicalReviewEmailResponse>) { (Object as any).assign(this, init); }
}

export class GetPatientsByIdsAsyncResponse
{
    public responseStatus: ResponseStatus;
    public patients: MedicalReviewPatient[];

    public constructor(init?: Partial<GetPatientsByIdsAsyncResponse>) { (Object as any).assign(this, init); }
}

export class IdentityServerDiagnosticsResponse
{
    public responseStatus: ResponseStatus;
    public diagnostics: IdentityServerDiagnostic[];

    public constructor(init?: Partial<IdentityServerDiagnosticsResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AuthenticateResponse implements IHasSessionId, IHasBearerToken
{
    // @DataMember(Order=1)
    public userId: string;

    // @DataMember(Order=2)
    public sessionId: string;

    // @DataMember(Order=3)
    public userName: string;

    // @DataMember(Order=4)
    public displayName: string;

    // @DataMember(Order=5)
    public referrerUrl: string;

    // @DataMember(Order=6)
    public bearerToken: string;

    // @DataMember(Order=7)
    public refreshToken: string;

    // @DataMember(Order=8)
    public profileUrl: string;

    // @DataMember(Order=9)
    public roles: string[];

    // @DataMember(Order=10)
    public permissions: string[];

    // @DataMember(Order=11)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=12)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<AuthenticateResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AssignRolesResponse
{
    // @DataMember(Order=1)
    public allRoles: string[];

    // @DataMember(Order=2)
    public allPermissions: string[];

    // @DataMember(Order=3)
    public meta: { [index: string]: string; };

    // @DataMember(Order=4)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<AssignRolesResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class UnAssignRolesResponse
{
    // @DataMember(Order=1)
    public allRoles: string[];

    // @DataMember(Order=2)
    public allPermissions: string[];

    // @DataMember(Order=3)
    public meta: { [index: string]: string; };

    // @DataMember(Order=4)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<UnAssignRolesResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class GetApiKeysResponse
{
    // @DataMember(Order=1)
    public results: UserApiKey[];

    // @DataMember(Order=2)
    public meta: { [index: string]: string; };

    // @DataMember(Order=3)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetApiKeysResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class RegenerateApiKeysResponse
{
    // @DataMember(Order=1)
    public results: UserApiKey[];

    // @DataMember(Order=2)
    public meta: { [index: string]: string; };

    // @DataMember(Order=3)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<RegenerateApiKeysResponse>) { (Object as any).assign(this, init); }
}

export class UploadResponse
{
    public responseStatus: ResponseStatus;
    public upload: IFileUploadTemporary;

    public constructor(init?: Partial<UploadResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class UserSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved user.
    */
    // @DataMember(Order=2)
    public user: User;

    public constructor(init?: Partial<UserSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class LookupCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved lookups.
    */
    // @DataMember(Order=2)
    public lookups: Lookup[];

    public constructor(init?: Partial<LookupCollectionResponse>) { (Object as any).assign(this, init); }
}

export class GetFormMetadataResponse
{
    public responseStatus: ResponseStatus;
    public formMetadata: FormMetadata[];

    public constructor(init?: Partial<GetFormMetadataResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class FormTypeSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved form type.
    */
    // @DataMember(Order=2)
    public configuration: FormType;

    public constructor(init?: Partial<FormTypeSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class FormTypeCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved form types.
    */
    // @DataMember(Order=2)
    public configuration: FormType[];

    public constructor(init?: Partial<FormTypeCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class FormDefinitionSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved form definition.
    */
    // @DataMember(Order=2)
    public configuration: FormDefinition;

    public constructor(init?: Partial<FormDefinitionSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class FormDefinitionCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved form definitions.
    */
    // @DataMember(Order=2)
    public configuration: FormDefinition[];

    public constructor(init?: Partial<FormDefinitionCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AdverseEventItemSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved adverse event item.
    */
    // @DataMember(Order=2)
    public data: AdverseEventItem;

    public constructor(init?: Partial<AdverseEventItemSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AdverseEventItemCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved adverse event items.
    */
    // @DataMember(Order=2)
    public data: AdverseEventItem[];

    public constructor(init?: Partial<AdverseEventItemCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AdverseEventItemValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<AdverseEventItemValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AnnotationSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved annotation.
    */
    // @DataMember(Order=2)
    public data: Annotation;

    public constructor(init?: Partial<AnnotationSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AnnotationCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved annotations.
    */
    // @DataMember(Order=2)
    public data: Annotation[];

    public constructor(init?: Partial<AnnotationCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AnnotationValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<AnnotationValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class BreastCancerRecurrenceItemSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved breast cancer recurrence item.
    */
    // @DataMember(Order=2)
    public data: BreastCancerRecurrenceItem;

    public constructor(init?: Partial<BreastCancerRecurrenceItemSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class BreastCancerRecurrenceItemCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved breast cancer recurrence items.
    */
    // @DataMember(Order=2)
    public data: BreastCancerRecurrenceItem[];

    public constructor(init?: Partial<BreastCancerRecurrenceItemCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class BreastCancerRecurrenceItemValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<BreastCancerRecurrenceItemValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class BreastUltrasoundSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved breast ultrasound.
    */
    // @DataMember(Order=2)
    public data: BreastUltrasound;

    public constructor(init?: Partial<BreastUltrasoundSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class BreastUltrasoundCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved breast ultrasounds.
    */
    // @DataMember(Order=2)
    public data: BreastUltrasound[];

    public constructor(init?: Partial<BreastUltrasoundCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class BreastUltrasoundValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<BreastUltrasoundValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CarboplatinTherapySingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved carboplatin therapy.
    */
    // @DataMember(Order=2)
    public data: CarboplatinTherapy;

    public constructor(init?: Partial<CarboplatinTherapySingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CarboplatinTherapyCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved carboplatin therapys.
    */
    // @DataMember(Order=2)
    public data: CarboplatinTherapy[];

    public constructor(init?: Partial<CarboplatinTherapyCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CarboplatinTherapyValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<CarboplatinTherapyValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ChemistrySingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved chemistry.
    */
    // @DataMember(Order=2)
    public data: Chemistry;

    public constructor(init?: Partial<ChemistrySingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ChemistryCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved chemistrys.
    */
    // @DataMember(Order=2)
    public data: Chemistry[];

    public constructor(init?: Partial<ChemistryCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ChemistryValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<ChemistryValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CoagulationSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved coagulation.
    */
    // @DataMember(Order=2)
    public data: Coagulation;

    public constructor(init?: Partial<CoagulationSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CoagulationCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved coagulations.
    */
    // @DataMember(Order=2)
    public data: Coagulation[];

    public constructor(init?: Partial<CoagulationCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CoagulationValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<CoagulationValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ConcomitantMedicationItemSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved concomitant medication item.
    */
    // @DataMember(Order=2)
    public data: ConcomitantMedicationItem;

    public constructor(init?: Partial<ConcomitantMedicationItemSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ConcomitantMedicationItemCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved concomitant medication items.
    */
    // @DataMember(Order=2)
    public data: ConcomitantMedicationItem[];

    public constructor(init?: Partial<ConcomitantMedicationItemCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ConcomitantMedicationItemValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<ConcomitantMedicationItemValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class DeathReportItemSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved death report item.
    */
    // @DataMember(Order=2)
    public data: DeathReportItem;

    public constructor(init?: Partial<DeathReportItemSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class DeathReportItemCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved death report items.
    */
    // @DataMember(Order=2)
    public data: DeathReportItem[];

    public constructor(init?: Partial<DeathReportItemCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class DeathReportItemValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<DeathReportItemValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class HaematologySingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved haematology.
    */
    // @DataMember(Order=2)
    public data: Haematology;

    public constructor(init?: Partial<HaematologySingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class HaematologyCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved haematologys.
    */
    // @DataMember(Order=2)
    public data: Haematology[];

    public constructor(init?: Partial<HaematologyCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class HaematologyValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<HaematologyValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MedicalHistoryItemSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved medical history item.
    */
    // @DataMember(Order=2)
    public data: MedicalHistoryItem;

    public constructor(init?: Partial<MedicalHistoryItemSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MedicalHistoryItemCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved medical history items.
    */
    // @DataMember(Order=2)
    public data: MedicalHistoryItem[];

    public constructor(init?: Partial<MedicalHistoryItemCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MedicalHistoryItemValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<MedicalHistoryItemValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MedicalHistoryMedicationSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved medical history medication.
    */
    // @DataMember(Order=2)
    public data: MedicalHistoryMedication;

    public constructor(init?: Partial<MedicalHistoryMedicationSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MedicalHistoryMedicationCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved medical history medications.
    */
    // @DataMember(Order=2)
    public data: MedicalHistoryMedication[];

    public constructor(init?: Partial<MedicalHistoryMedicationCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MedicalHistoryMedicationValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<MedicalHistoryMedicationValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class NivolumabTherapySingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved nivolumab therapy.
    */
    // @DataMember(Order=2)
    public data: NivolumabTherapy;

    public constructor(init?: Partial<NivolumabTherapySingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class NivolumabTherapyCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved nivolumab therapys.
    */
    // @DataMember(Order=2)
    public data: NivolumabTherapy[];

    public constructor(init?: Partial<NivolumabTherapyCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class NivolumabTherapyValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<NivolumabTherapyValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PaclitaxelTherapySingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved paclitaxel therapy.
    */
    // @DataMember(Order=2)
    public data: PaclitaxelTherapy;

    public constructor(init?: Partial<PaclitaxelTherapySingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PaclitaxelTherapyCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved paclitaxel therapys.
    */
    // @DataMember(Order=2)
    public data: PaclitaxelTherapy[];

    public constructor(init?: Partial<PaclitaxelTherapyCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PaclitaxelTherapyValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<PaclitaxelTherapyValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ImportantProtocolDeviationSingleResponse {
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved protocol deviation.
    */
    // @DataMember(Order=2)
    public data: ImportantProtocolDeviation;

    public constructor(init?: Partial<ImportantProtocolDeviationSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ImportantProtocolDeviationCollectionResponse {
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved protocol deviations.
    */
    // @DataMember(Order=2)
    public data: ImportantProtocolDeviation[];

    public constructor(init?: Partial<ImportantProtocolDeviationCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ImportantProtocolDeviationValidationResponse {
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<ImportantProtocolDeviationValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ProtocolDeviationSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved protocol deviation.
    */
    // @DataMember(Order=2)
    public data: ProtocolDeviation;

    public constructor(init?: Partial<ProtocolDeviationSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ProtocolDeviationCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved protocol deviations.
    */
    // @DataMember(Order=2)
    public data: ProtocolDeviation[];

    public constructor(init?: Partial<ProtocolDeviationCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ProtocolDeviationValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<ProtocolDeviationValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SecondPrimaryMalignancyItemSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved second primary malignancy item.
    */
    // @DataMember(Order=2)
    public data: SecondPrimaryMalignancyItem;

    public constructor(init?: Partial<SecondPrimaryMalignancyItemSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SecondPrimaryMalignancyItemCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved second primary malignancy items.
    */
    // @DataMember(Order=2)
    public data: SecondPrimaryMalignancyItem[];

    public constructor(init?: Partial<SecondPrimaryMalignancyItemCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SecondPrimaryMalignancyItemValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<SecondPrimaryMalignancyItemValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SeriousAdverseEventItemSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved serious adverse event item.
    */
    // @DataMember(Order=2)
    public data: SeriousAdverseEventItem;

    public constructor(init?: Partial<SeriousAdverseEventItemSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SeriousAdverseEventItemCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved serious adverse event items.
    */
    // @DataMember(Order=2)
    public data: SeriousAdverseEventItem[];

    public constructor(init?: Partial<SeriousAdverseEventItemCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SeriousAdverseEventItemValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<SeriousAdverseEventItemValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SlnbResultSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved slnb result.
    */
    // @DataMember(Order=2)
    public data: SlnbResult;

    public constructor(init?: Partial<SlnbResultSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SlnbResultCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved slnb results.
    */
    // @DataMember(Order=2)
    public data: SlnbResult[];

    public constructor(init?: Partial<SlnbResultCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SlnbResultValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<SlnbResultValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SourceDocumentSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved source document.
    */
    // @DataMember(Order=2)
    public data: SourceDocument;

    public constructor(init?: Partial<SourceDocumentSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SourceDocumentCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved source documents.
    */
    // @DataMember(Order=2)
    public data: SourceDocument[];

    public constructor(init?: Partial<SourceDocumentCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SourceDocumentValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<SourceDocumentValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SurvivalFollowUpItemSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved survival follow up item.
    */
    // @DataMember(Order=2)
    public data: SurvivalFollowUpItem;

    public constructor(init?: Partial<SurvivalFollowUpItemSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SurvivalFollowUpItemCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved survival follow up items.
    */
    // @DataMember(Order=2)
    public data: SurvivalFollowUpItem[];

    public constructor(init?: Partial<SurvivalFollowUpItemCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SurvivalFollowUpItemValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<SurvivalFollowUpItemValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ThyroidFunctionSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved thyroid function.
    */
    // @DataMember(Order=2)
    public data: ThyroidFunction;

    public constructor(init?: Partial<ThyroidFunctionSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ThyroidFunctionCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved thyroid functions.
    */
    // @DataMember(Order=2)
    public data: ThyroidFunction[];

    public constructor(init?: Partial<ThyroidFunctionCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ThyroidFunctionValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<ThyroidFunctionValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AdverseEventsSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved adverse events.
    */
    // @DataMember(Order=2)
    public data: AdverseEvents;

    public constructor(init?: Partial<AdverseEventsSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AdverseEventsCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved adverse eventss.
    */
    // @DataMember(Order=2)
    public data: AdverseEvents[];

    public constructor(init?: Partial<AdverseEventsCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AdverseEventsValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<AdverseEventsValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ConcomitantMedicationsSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved concomitant medications.
    */
    // @DataMember(Order=2)
    public data: ConcomitantMedications;

    public constructor(init?: Partial<ConcomitantMedicationsSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ConcomitantMedicationsCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved concomitant medicationss.
    */
    // @DataMember(Order=2)
    public data: ConcomitantMedications[];

    public constructor(init?: Partial<ConcomitantMedicationsCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ConcomitantMedicationsValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<ConcomitantMedicationsValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class GeneralInformationSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved general information.
    */
    // @DataMember(Order=2)
    public data: GeneralInformation;

    public constructor(init?: Partial<GeneralInformationSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class GeneralInformationCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved general informations.
    */
    // @DataMember(Order=2)
    public data: GeneralInformation[];

    public constructor(init?: Partial<GeneralInformationCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class GeneralInformationValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<GeneralInformationValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class HistopathologySingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved histopathology.
    */
    // @DataMember(Order=2)
    public data: Histopathology;

    public constructor(init?: Partial<HistopathologySingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class HistopathologyCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved histopathologys.
    */
    // @DataMember(Order=2)
    public data: Histopathology[];

    public constructor(init?: Partial<HistopathologyCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class HistopathologyValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<HistopathologyValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ImagingSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved imaging.
    */
    // @DataMember(Order=2)
    public data: Imaging;

    public constructor(init?: Partial<ImagingSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ImagingCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved imagings.
    */
    // @DataMember(Order=2)
    public data: Imaging[];

    public constructor(init?: Partial<ImagingCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ImagingValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<ImagingValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MedicalHistorySingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved medical history.
    */
    // @DataMember(Order=2)
    public data: MedicalHistory;

    public constructor(init?: Partial<MedicalHistorySingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MedicalHistoryCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved medical historys.
    */
    // @DataMember(Order=2)
    public data: MedicalHistory[];

    public constructor(init?: Partial<MedicalHistoryCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MedicalHistoryValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<MedicalHistoryValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class NeonTherapySingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved neon therapy.
    */
    // @DataMember(Order=2)
    public data: NeonTherapy;

    public constructor(init?: Partial<NeonTherapySingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class NeonTherapyCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved neon therapys.
    */
    // @DataMember(Order=2)
    public data: NeonTherapy[];

    public constructor(init?: Partial<NeonTherapyCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class NeonTherapyValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<NeonTherapyValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class OrganFunctionSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved organ function.
    */
    // @DataMember(Order=2)
    public data: OrganFunction;

    public constructor(init?: Partial<OrganFunctionSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class OrganFunctionCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved organ functions.
    */
    // @DataMember(Order=2)
    public data: OrganFunction[];

    public constructor(init?: Partial<OrganFunctionCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class OrganFunctionValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<OrganFunctionValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class OrganFunctionTreatmentSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved organ function treatment.
    */
    // @DataMember(Order=2)
    public data: OrganFunctionTreatment;

    public constructor(init?: Partial<OrganFunctionTreatmentSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class OrganFunctionTreatmentCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved organ function treatments.
    */
    // @DataMember(Order=2)
    public data: OrganFunctionTreatment[];

    public constructor(init?: Partial<OrganFunctionTreatmentCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class OrganFunctionTreatmentValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<OrganFunctionTreatmentValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SurgerySingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved surgery.
    */
    // @DataMember(Order=2)
    public data: Surgery;

    public constructor(init?: Partial<SurgerySingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SurgeryCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved surgerys.
    */
    // @DataMember(Order=2)
    public data: Surgery[];

    public constructor(init?: Partial<SurgeryCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SurgeryValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<SurgeryValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SurvivalAndRecurrenceSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved survival and recurrence.
    */
    // @DataMember(Order=2)
    public data: SurvivalAndRecurrence;

    public constructor(init?: Partial<SurvivalAndRecurrenceSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SurvivalAndRecurrenceCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved survival and recurrences.
    */
    // @DataMember(Order=2)
    public data: SurvivalAndRecurrence[];

    public constructor(init?: Partial<SurvivalAndRecurrenceCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SurvivalAndRecurrenceValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<SurvivalAndRecurrenceValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MasterGroupSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved master group.
    */
    // @DataMember(Order=2)
    public data: MasterGroup;

    public constructor(init?: Partial<MasterGroupSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MasterGroupCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved master groups.
    */
    // @DataMember(Order=2)
    public data: MasterGroup[];

    public constructor(init?: Partial<MasterGroupCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MasterGroupValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<MasterGroupValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CoordinatingGroupSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved coordinating group.
    */
    // @DataMember(Order=2)
    public data: CoordinatingGroup;

    public constructor(init?: Partial<CoordinatingGroupSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CoordinatingGroupCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved coordinating groups.
    */
    // @DataMember(Order=2)
    public data: CoordinatingGroup[];

    public constructor(init?: Partial<CoordinatingGroupCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CoordinatingGroupValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<CoordinatingGroupValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CountrySingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved country.
    */
    // @DataMember(Order=2)
    public data: Country;

    public constructor(init?: Partial<CountrySingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CountryCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved countrys.
    */
    // @DataMember(Order=2)
    public data: Country[];

    public constructor(init?: Partial<CountryCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CountryValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<CountryValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class InstitutionSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved institution.
    */
    // @DataMember(Order=2)
    public data: Institution;

    public constructor(init?: Partial<InstitutionSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class InstitutionCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved institutions.
    */
    // @DataMember(Order=2)
    public data: Institution[];

    public constructor(init?: Partial<InstitutionCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class InstitutionValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<InstitutionValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PatientSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved patient.
    */
    // @DataMember(Order=2)
    public data: MedicalReviewPatient;

    public constructor(init?: Partial<PatientSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PatientCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved patients.
    */
    // @DataMember(Order=2)
    public data: MedicalReviewPatient[];

    public constructor(init?: Partial<PatientCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PatientValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<PatientValidationResponse>) { (Object as any).assign(this, init); }
}

export class PostHasPermissionsResponse
{
    public responseStatus: ResponseStatus;
    public permissions: { [index: string]: boolean; };

    public constructor(init?: Partial<PostHasPermissionsResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MedicalReviewDefinitionSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved medical review definition.
    */
    // @DataMember(Order=2)
    public configuration: MedicalReviewDefinition;

    public constructor(init?: Partial<MedicalReviewDefinitionSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MedicalReviewDefinitionCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved medical review definitions.
    */
    // @DataMember(Order=2)
    public configuration: MedicalReviewDefinition[];

    public constructor(init?: Partial<MedicalReviewDefinitionCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class QueryRecipientSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved query recipient.
    */
    // @DataMember(Order=2)
    public configuration: QueryRecipient;

    public constructor(init?: Partial<QueryRecipientSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class QueryRecipientCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved query recipients.
    */
    // @DataMember(Order=2)
    public configuration: QueryRecipient[];

    public constructor(init?: Partial<QueryRecipientCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ReviewerSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved reviewer.
    */
    // @DataMember(Order=2)
    public data: Reviewer;

    public constructor(init?: Partial<ReviewerSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ReviewerCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved reviewers.
    */
    // @DataMember(Order=2)
    public data: Reviewer[];

    public constructor(init?: Partial<ReviewerCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ReviewerValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<ReviewerValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MedicalReviewSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved medical review.
    */
    // @DataMember(Order=2)
    public data: MedicalReview;

    public constructor(init?: Partial<MedicalReviewSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MedicalReviewCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved medical reviews.
    */
    // @DataMember(Order=2)
    public data: MedicalReview[];

    public constructor(init?: Partial<MedicalReviewCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MedicalReviewValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<MedicalReviewValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MedicalReviewCreationSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved medical review.
    */
    // @DataMember(Order=2)
    public data: MedicalReview;

    /**
    * If no forms were provided for medical review creation.
    */
    // @DataMember(Order=3)
    public hasNoForms?: boolean;

    /**
    * If no reports had changes during the medical review creation.
    */
    // @DataMember(Order=4)
    public hasNoChanges?: boolean;

    public constructor(init?: Partial<MedicalReviewCreationSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MedicalReviewValidationCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult[];

    public constructor(init?: Partial<MedicalReviewValidationCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class QuerySingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved query.
    */
    // @DataMember(Order=2)
    public data: Query;

    public constructor(init?: Partial<QuerySingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class QueryCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved querys.
    */
    // @DataMember(Order=2)
    public data: Query[];

    public constructor(init?: Partial<QueryCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class QueryValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<QueryValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class UpdatedFormPropertySingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved updatedformproperty.
    */
    // @DataMember(Order=2)
    public data: UpdatedFormProperty;

    public constructor(init?: Partial<UpdatedFormPropertySingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class UpdatedFormPropertyCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved updatedformpropertys.
    */
    // @DataMember(Order=2)
    public data: UpdatedFormProperty[];

    public constructor(init?: Partial<UpdatedFormPropertyCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class UpdatedFormPropertyValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<UpdatedFormPropertyValidationResponse>) { (Object as any).assign(this, init); }
}

export class QueueAvailableMedicalReviewEmails implements IReturn<QueueEmailJobsResponse>
{

    public constructor(init?: Partial<QueueAvailableMedicalReviewEmails>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'QueueAvailableMedicalReviewEmails'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new QueueEmailJobsResponse(); }
}

export class QueueStartedAndCompletedMedicalReviewEmails implements IReturn<QueueEmailJobsResponse>
{

    public constructor(init?: Partial<QueueStartedAndCompletedMedicalReviewEmails>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'QueueStartedAndCompletedMedicalReviewEmails'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new QueueEmailJobsResponse(); }
}

export class SendMedicalReviewsAvailableEmails implements IReturn<SendMedicalReviewEmailResponse>
{
    public reviewerId?: number;

    public constructor(init?: Partial<SendMedicalReviewsAvailableEmails>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendMedicalReviewsAvailableEmails'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendMedicalReviewEmailResponse(); }
}

export class SendStartedAndCompletedMedicalReviewsEmail implements IReturn<SendMedicalReviewEmailResponse>
{

    public constructor(init?: Partial<SendStartedAndCompletedMedicalReviewsEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendStartedAndCompletedMedicalReviewsEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendMedicalReviewEmailResponse(); }
}

export class SendMedicalReviewCompleteEmail implements IReturn<SendMedicalReviewEmailResponse>
{
    public medicalReviewId?: number;

    public constructor(init?: Partial<SendMedicalReviewCompleteEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendMedicalReviewCompleteEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendMedicalReviewEmailResponse(); }
}

export class SendNewQueryAddedEmail implements IReturn<SendMedicalReviewEmailResponse>
{
    public queryId?: number;

    public constructor(init?: Partial<SendNewQueryAddedEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendNewQueryAddedEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendMedicalReviewEmailResponse(); }
}

export class SendNewQueryAsResponseEmail implements IReturn<SendMedicalReviewEmailResponse>
{
    public queryId?: number;

    public constructor(init?: Partial<SendNewQueryAsResponseEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendNewQueryAsResponseEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendMedicalReviewEmailResponse(); }
}

export class SendQueryClosedEarlyEmail implements IReturn<SendMedicalReviewEmailResponse>
{
    public queryId?: number;

    public constructor(init?: Partial<SendQueryClosedEarlyEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendQueryClosedEarlyEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendMedicalReviewEmailResponse(); }
}

export class SendQueryResponseEmail implements IReturn<SendMedicalReviewEmailResponse>
{
    public queryId?: number;

    public constructor(init?: Partial<SendQueryResponseEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendQueryResponseEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendMedicalReviewEmailResponse(); }
}

// @Route("/medical-review/patients-by-ids", "GET")
export class GetPatientsByIdsAsync implements IReturn<GetPatientsByIdsAsyncResponse>, IGet
{
    public ids: number[];

    public constructor(init?: Partial<GetPatientsByIdsAsync>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetPatientsByIdsAsync'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetPatientsByIdsAsyncResponse(); }
}

export class QueueInstitutionQuerySummaryEmails implements IReturn<QueueEmailJobsResponse>
{

    public constructor(init?: Partial<QueueInstitutionQuerySummaryEmails>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'QueueInstitutionQuerySummaryEmails'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new QueueEmailJobsResponse(); }
}

// @Route("/emails/institution-query-summary/{InstitutionId}")
export class SendInstitutionQuerySummaryEmail implements IReturn<SendMedicalReviewEmailResponse>
{
    public institutionId?: number;

    public constructor(init?: Partial<SendInstitutionQuerySummaryEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendInstitutionQuerySummaryEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendMedicalReviewEmailResponse(); }
}

// @Route("/emails/reviewer-query-summary/{ReviewerId}")
export class SendReviewerQuerySummaryEmail implements IReturn<SendMedicalReviewEmailResponse>
{
    public reviewerId?: number;

    public constructor(init?: Partial<SendReviewerQuerySummaryEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendReviewerQuerySummaryEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendMedicalReviewEmailResponse(); }
}

export class QueueReviewerQuerySummaryEmails implements IReturn<QueueEmailJobsResponse>
{

    public constructor(init?: Partial<QueueReviewerQuerySummaryEmails>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'QueueReviewerQuerySummaryEmails'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new QueueEmailJobsResponse(); }
}

// @Route("/queries", "GET")
export class QueryReport
{
    public institutionId?: number;
    public patientId?: number;
    public medicalReviewId?: number;

    public constructor(init?: Partial<QueryReport>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'QueryReport'; }
    public getMethod() { return 'GET'; }
    public createResponse() {}
}

// @Route("/identity-server/authorized")
export class IdentityServerAuthorized implements IReturn<IdentityServerDiagnosticsResponse>
{

    public constructor(init?: Partial<IdentityServerAuthorized>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'IdentityServerAuthorized'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new IdentityServerDiagnosticsResponse(); }
}

// @Route("/identity-server/test")
export class IdentityServerDiagnostics implements IReturn<IdentityServerDiagnosticsResponse>
{
    public code: string;
    public state: string;
    public error: string;

    public constructor(init?: Partial<IdentityServerDiagnostics>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'IdentityServerDiagnostics'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new IdentityServerDiagnosticsResponse(); }
}

/**
* Sign In
*/
// @Route("/auth", "OPTIONS,GET,POST,DELETE")
// @Route("/auth/{provider}", "OPTIONS,GET,POST,DELETE")
// @Api(Description="Sign In")
// @DataContract
export class Authenticate implements IReturn<AuthenticateResponse>, IPost
{
    /**
    * AuthProvider, e.g. credentials
    */
    // @DataMember(Order=1)
    public provider: string;

    // @DataMember(Order=2)
    public state: string;

    // @DataMember(Order=3)
    public oauth_token: string;

    // @DataMember(Order=4)
    public oauth_verifier: string;

    // @DataMember(Order=5)
    public userName: string;

    // @DataMember(Order=6)
    public password: string;

    // @DataMember(Order=7)
    public rememberMe?: boolean;

    // @DataMember(Order=9)
    public errorView: string;

    // @DataMember(Order=10)
    public nonce: string;

    // @DataMember(Order=11)
    public uri: string;

    // @DataMember(Order=12)
    public response: string;

    // @DataMember(Order=13)
    public qop: string;

    // @DataMember(Order=14)
    public nc: string;

    // @DataMember(Order=15)
    public cnonce: string;

    // @DataMember(Order=17)
    public accessToken: string;

    // @DataMember(Order=18)
    public accessTokenSecret: string;

    // @DataMember(Order=19)
    public scope: string;

    // @DataMember(Order=20)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<Authenticate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'Authenticate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AuthenticateResponse(); }
}

// @Route("/assignroles", "POST")
// @DataContract
export class AssignRoles implements IReturn<AssignRolesResponse>, IPost
{
    // @DataMember(Order=1)
    public userName: string;

    // @DataMember(Order=2)
    public permissions: string[];

    // @DataMember(Order=3)
    public roles: string[];

    // @DataMember(Order=4)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<AssignRoles>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AssignRoles'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AssignRolesResponse(); }
}

// @Route("/unassignroles", "POST")
// @DataContract
export class UnAssignRoles implements IReturn<UnAssignRolesResponse>, IPost
{
    // @DataMember(Order=1)
    public userName: string;

    // @DataMember(Order=2)
    public permissions: string[];

    // @DataMember(Order=3)
    public roles: string[];

    // @DataMember(Order=4)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<UnAssignRoles>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UnAssignRoles'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new UnAssignRolesResponse(); }
}

// @Route("/apikeys")
// @Route("/apikeys/{Environment}")
// @DataContract
export class GetApiKeys implements IReturn<GetApiKeysResponse>, IGet
{
    // @DataMember(Order=1)
    public environment: string;

    // @DataMember(Order=2)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<GetApiKeys>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetApiKeys'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetApiKeysResponse(); }
}

// @Route("/apikeys/regenerate")
// @Route("/apikeys/regenerate/{Environment}")
// @DataContract
export class RegenerateApiKeys implements IReturn<RegenerateApiKeysResponse>, IPost
{
    // @DataMember(Order=1)
    public environment: string;

    // @DataMember(Order=2)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<RegenerateApiKeys>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'RegenerateApiKeys'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new RegenerateApiKeysResponse(); }
}

// @Route("/medical-review/file-upload", "POST")
export class PostUploadFile implements IReturn<UploadResponse>, IPost
{

    public constructor(init?: Partial<PostUploadFile>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PostUploadFile'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new UploadResponse(); }
}

// @Route("/medical-review/file-upload/info/{Guid}", "GET")
export class GetUploadInfo implements IReturn<UploadResponse>, IGet
{
    public guid: string;

    public constructor(init?: Partial<GetUploadInfo>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetUploadInfo'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new UploadResponse(); }
}

// @Route("/medical-review/file-upload/{Guid}", "GET")
export class GetUpload implements IReturn<Blob>, IGet
{
    public guid: string;

    public constructor(init?: Partial<GetUpload>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetUpload'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Blob(); }
}

// @Route("/medical-review/file-upload/download/{Guid}", "GET")
export class DownloadUpload implements IReturn<Blob>, IGet
{
    public guid: string;

    public constructor(init?: Partial<DownloadUpload>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DownloadUpload'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Blob(); }
}

// @Route("/medical-review/file-upload/{Guid}", "DELETE")
export class DeleteUpload implements IReturn<UploadResponse>, IDelete
{
    public guid: string;

    public constructor(init?: Partial<DeleteUpload>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeleteUpload'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new UploadResponse(); }
}

/**
* User
*/
// @Route("/medical-review/user/single/user", "GET")
// @Api(Description="User")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.User.UserSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.User.UserSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.User.UserSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.User.UserSingleResponse)", StatusCode=500)
// @DataContract
export class UserGetSingle implements IReturn<UserSingleResponse>, IUserGetSingle
{
    /**
    * The metadata of the request.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<UserGetSingle>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UserGetSingle'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new UserSingleResponse(); }
}

/**
* Lookup
*/
// @Route("/medical-review/lookup/collection/form-definition/{FormDefinitionId}", "GET")
// @Api(Description="Lookup")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Lookup.LookupCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Lookup.LookupCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Lookup.LookupCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Lookup.LookupCollectionResponse)", StatusCode=500)
// @DataContract
export class LookupGetCollectionByFormDefinitionId implements IReturn<LookupCollectionResponse>, ILookupGetCollectionByFormDefinitionId
{
    /**
    * The ID of the Form Definition associated with the lookup to retrieve.
    */
    // @DataMember(Order=1)
    public formDefinitionId?: number;

    public constructor(init?: Partial<LookupGetCollectionByFormDefinitionId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'LookupGetCollectionByFormDefinitionId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new LookupCollectionResponse(); }
}

// @Route("/medical-review/form-names", "GET")
export class GetFormMetadata implements IReturn<GetFormMetadataResponse>
{

    public constructor(init?: Partial<GetFormMetadata>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetFormMetadata'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetFormMetadataResponse(); }
}

/**
* Form Type
*/
// @Route("/medical-review/form-type/single/id/{Id}", "GET")
// @Api(Description="Form Type")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.FormType.FormTypeSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.FormType.FormTypeSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.FormType.FormTypeSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.FormType.FormTypeSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.FormType.FormTypeSingleResponse)", StatusCode=500)
// @DataContract
export class FormTypeGetSingleById implements IReturn<FormTypeSingleResponse>, IConfigurationGetSingleById
{
    /**
    * The ID of the form type to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<FormTypeGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'FormTypeGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new FormTypeSingleResponse(); }
}

/**
* Form Type
*/
// @Route("/medical-review/form-type/collection", "GET")
// @Api(Description="Form Type")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.FormType.FormTypeCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.FormType.FormTypeCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.FormType.FormTypeCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.FormType.FormTypeCollectionResponse)", StatusCode=500)
// @DataContract
export class FormTypeGetCollection implements IReturn<FormTypeCollectionResponse>, IConfigurationGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<FormTypeGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'FormTypeGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new FormTypeCollectionResponse(); }
}

/**
* Form Type
*/
// @Route("/medical-review/form-type/single/code/{Code}", "GET")
// @Api(Description="Form Type")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.FormType.FormTypeSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.FormType.FormTypeSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.FormType.FormTypeSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.FormType.FormTypeSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.FormType.FormTypeSingleResponse)", StatusCode=500)
// @DataContract
export class FormTypeGetSingleByCode implements IReturn<FormTypeSingleResponse>, IConfigurationGetSingleByCode
{
    /**
    * The ID of the form type to retrieve.
    */
    // @DataMember(Order=1)
    public code: string;

    public constructor(init?: Partial<FormTypeGetSingleByCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'FormTypeGetSingleByCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new FormTypeSingleResponse(); }
}

/**
* Form Definition
*/
// @Route("/medical-review/form-definition/single/id/{Id}", "GET")
// @Api(Description="Form Definition")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=500)
// @DataContract
export class FormDefinitionGetSingleById implements IReturn<FormDefinitionSingleResponse>, IConfigurationGetSingleById
{
    /**
    * The ID of the form definition to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<FormDefinitionGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'FormDefinitionGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new FormDefinitionSingleResponse(); }
}

/**
* Form Definition
*/
// @Route("/medical-review/form-definition/collection", "GET")
// @Api(Description="Form Definition")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionCollectionResponse)", StatusCode=500)
// @DataContract
export class FormDefinitionGetCollection implements IReturn<FormDefinitionCollectionResponse>, IConfigurationGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<FormDefinitionGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'FormDefinitionGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new FormDefinitionCollectionResponse(); }
}

/**
* Form Definition
*/
// @Route("/medical-review/form-definition/single/code/{Code}", "GET")
// @Api(Description="Form Definition")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=500)
// @DataContract
export class FormDefinitionGetSingleByCode implements IReturn<FormDefinitionSingleResponse>, IConfigurationGetSingleByCode
{
    /**
    * The ID of the form definition to retrieve.
    */
    // @DataMember(Order=1)
    public code: string;

    public constructor(init?: Partial<FormDefinitionGetSingleByCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'FormDefinitionGetSingleByCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new FormDefinitionSingleResponse(); }
}

/**
* Form - AdverseEventItem
*/
// @Route("/medical-review/form/adverse-event-item/single/id/{Id}", "GET")
// @Api(Description="Form - AdverseEventItem")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AdverseEventItemSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AdverseEventItemSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AdverseEventItemSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AdverseEventItemSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AdverseEventItemSingleResponse)", StatusCode=500)
// @DataContract
export class AdverseEventItemGetSingleById implements IReturn<AdverseEventItemSingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the adverse event item to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<AdverseEventItemGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdverseEventItemGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new AdverseEventItemSingleResponse(); }
}

/**
* Form - AdverseEventItem
*/
// @Route("/medical-review/form/adverse-event-item/collection", "GET")
// @Api(Description="Form - AdverseEventItem")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AdverseEventItemCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AdverseEventItemCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AdverseEventItemCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AdverseEventItemCollectionResponse)", StatusCode=500)
// @DataContract
export class AdverseEventItemGetCollection implements IReturn<AdverseEventItemCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<AdverseEventItemGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdverseEventItemGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new AdverseEventItemCollectionResponse(); }
}

/**
* Form - AdverseEventItem
*/
// @Route("/medical-review/form/adverse-event-item/create", "POST")
// @Api(Description="Form - AdverseEventItem")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AdverseEventItemSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AdverseEventItemSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AdverseEventItemSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AdverseEventItemSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AdverseEventItemSingleResponse)", StatusCode=500)
// @DataContract
export class AdverseEventItemPostCreate implements IReturn<AdverseEventItemSingleResponse>
{
    /**
    * The metadata to use when creating the adverse event item.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the adverse event item.
    */
    // @DataMember(Order=2)
    public data: AdverseEventItem;

    public constructor(init?: Partial<AdverseEventItemPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdverseEventItemPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new AdverseEventItemSingleResponse(); }
}

/**
* Form - AdverseEventItem
*/
// @Route("/medical-review/form/adverse-event-item/save", "POST")
// @Api(Description="Form - AdverseEventItem")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AdverseEventItemSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AdverseEventItemSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AdverseEventItemSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AdverseEventItemSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AdverseEventItemSingleResponse)", StatusCode=500)
// @DataContract
export class AdverseEventItemPostSave implements IReturn<AdverseEventItemSingleResponse>
{
    /**
    * The metadata to use when creating the adverse event item.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The adverse event item to save.
    */
    // @DataMember(Order=2)
    public data: AdverseEventItem;

    public constructor(init?: Partial<AdverseEventItemPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdverseEventItemPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AdverseEventItemSingleResponse(); }
}

/**
* Form - AdverseEventItem
*/
// @Route("/medical-review/form/adverse-event-item/validate", "POST")
// @Api(Description="Form - AdverseEventItem")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AdverseEventItemValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AdverseEventItemValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AdverseEventItemValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AdverseEventItemValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AdverseEventItemValidationResponse)", StatusCode=500)
// @DataContract
export class AdverseEventItemPostValidate implements IReturn<AdverseEventItemValidationResponse>
{
    /**
    * The metadata to use when validating the adverse event item.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The adverse event item to validate.
    */
    // @DataMember(Order=2)
    public data: AdverseEventItem;

    public constructor(init?: Partial<AdverseEventItemPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdverseEventItemPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AdverseEventItemValidationResponse(); }
}

/**
* Form - AdverseEventItem
*/
// @Route("/medical-review/form/adverse-event-item/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - AdverseEventItem")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AdverseEventItemSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AdverseEventItemSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AdverseEventItemSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AdverseEventItemSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AdverseEventItemSingleResponse)", StatusCode=500)
// @DataContract
export class AdverseEventItemGetSingleByMedicalReviewIdAndRepeat implements IReturn<AdverseEventItemSingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    /**
    * The id of the medical review.
    */
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    /**
    * The repeat of the adverse event item to retrieve.
    */
    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<AdverseEventItemGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdverseEventItemGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new AdverseEventItemSingleResponse(); }
}

/**
* Form - AdverseEventItem
*/
// @Route("/medical-review/form/adverse-event-item/single/update", "POST")
// @Api(Description="Form - AdverseEventItem")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AdverseEventItemSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AdverseEventItemSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AdverseEventItemSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AdverseEventItemSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AdverseEventItemSingleResponse)", StatusCode=500)
// @DataContract
export class AdverseEventItemPostUpdateById implements IReturn<AdverseEventItemSingleResponse>, IFormPostUpdateById
{
    /**
    * The id of the medical review form.
    */
    // @DataMember(Order=1)
    public id?: number;

    /**
    * The additional metadata for the medical review.
    */
    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<AdverseEventItemPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdverseEventItemPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AdverseEventItemSingleResponse(); }
}

/**
* Form - Annotation
*/
// @Route("/medical-review/form/annotation/single/id/{Id}", "GET")
// @Api(Description="Form - Annotation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AnnotationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AnnotationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AnnotationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AnnotationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AnnotationSingleResponse)", StatusCode=500)
// @DataContract
export class AnnotationGetSingleById implements IReturn<AnnotationSingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the annotation to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<AnnotationGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AnnotationGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new AnnotationSingleResponse(); }
}

/**
* Form - Annotation
*/
// @Route("/medical-review/form/annotation/collection", "GET")
// @Api(Description="Form - Annotation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AnnotationCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AnnotationCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AnnotationCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AnnotationCollectionResponse)", StatusCode=500)
// @DataContract
export class AnnotationGetCollection implements IReturn<AnnotationCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<AnnotationGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AnnotationGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new AnnotationCollectionResponse(); }
}

/**
* Form - Annotation
*/
// @Route("/medical-review/form/annotation/create", "POST")
// @Api(Description="Form - Annotation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AnnotationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AnnotationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AnnotationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AnnotationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AnnotationSingleResponse)", StatusCode=500)
// @DataContract
export class AnnotationPostCreate implements IReturn<AnnotationSingleResponse>
{
    /**
    * The metadata to use when creating the annotation.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the annotation.
    */
    // @DataMember(Order=2)
    public data: Annotation;

    public constructor(init?: Partial<AnnotationPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AnnotationPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new AnnotationSingleResponse(); }
}

/**
* Form - Annotation
*/
// @Route("/medical-review/form/annotation/save", "POST")
// @Api(Description="Form - Annotation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AnnotationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AnnotationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AnnotationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AnnotationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AnnotationSingleResponse)", StatusCode=500)
// @DataContract
export class AnnotationPostSave implements IReturn<AnnotationSingleResponse>
{
    /**
    * The metadata to use when creating the annotation.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The annotation to save.
    */
    // @DataMember(Order=2)
    public data: Annotation;

    public constructor(init?: Partial<AnnotationPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AnnotationPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AnnotationSingleResponse(); }
}

/**
* Form - Annotation
*/
// @Route("/medical-review/form/annotation/validate", "POST")
// @Api(Description="Form - Annotation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AnnotationValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AnnotationValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AnnotationValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AnnotationValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AnnotationValidationResponse)", StatusCode=500)
// @DataContract
export class AnnotationPostValidate implements IReturn<AnnotationValidationResponse>
{
    /**
    * The metadata to use when validating the annotation.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The annotation to validate.
    */
    // @DataMember(Order=2)
    public data: Annotation;

    public constructor(init?: Partial<AnnotationPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AnnotationPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AnnotationValidationResponse(); }
}

/**
* Form - Annotation
*/
// @Route("/medical-review/form/annotation/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - Annotation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AnnotationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AnnotationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AnnotationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AnnotationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AnnotationSingleResponse)", StatusCode=500)
// @DataContract
export class AnnotationGetSingleByMedicalReviewIdAndRepeat implements IReturn<AnnotationSingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    /**
    * The id of the medical review.
    */
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    /**
    * The repeat of the annotation to retrieve.
    */
    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<AnnotationGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AnnotationGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new AnnotationSingleResponse(); }
}

/**
* Form - Annotation
*/
// @Route("/medical-review/form/annotation/single/update", "POST")
// @Api(Description="Form - Annotation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AnnotationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AnnotationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AnnotationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AnnotationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AnnotationSingleResponse)", StatusCode=500)
// @DataContract
export class AnnotationPostUpdateById implements IReturn<AnnotationSingleResponse>, IFormPostUpdateById
{
    /**
    * The id of the medical review form.
    */
    // @DataMember(Order=1)
    public id?: number;

    /**
    * The additional metadata for the medical review.
    */
    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<AnnotationPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AnnotationPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AnnotationSingleResponse(); }
}

/**
* Form - BreastCancerRecurrenceItem
*/
// @Route("/medical-review/form/breast-cancer-recurrence-item/single/id/{Id}", "GET")
// @Api(Description="Form - BreastCancerRecurrenceItem")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.BreastCancerRecurrenceItemSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.BreastCancerRecurrenceItemSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.BreastCancerRecurrenceItemSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.BreastCancerRecurrenceItemSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.BreastCancerRecurrenceItemSingleResponse)", StatusCode=500)
// @DataContract
export class BreastCancerRecurrenceItemGetSingleById implements IReturn<BreastCancerRecurrenceItemSingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the breast cancer recurrence item to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<BreastCancerRecurrenceItemGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BreastCancerRecurrenceItemGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new BreastCancerRecurrenceItemSingleResponse(); }
}

/**
* Form - BreastCancerRecurrenceItem
*/
// @Route("/medical-review/form/breast-cancer-recurrence-item/collection", "GET")
// @Api(Description="Form - BreastCancerRecurrenceItem")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.BreastCancerRecurrenceItemCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.BreastCancerRecurrenceItemCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.BreastCancerRecurrenceItemCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.BreastCancerRecurrenceItemCollectionResponse)", StatusCode=500)
// @DataContract
export class BreastCancerRecurrenceItemGetCollection implements IReturn<BreastCancerRecurrenceItemCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<BreastCancerRecurrenceItemGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BreastCancerRecurrenceItemGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new BreastCancerRecurrenceItemCollectionResponse(); }
}

/**
* Form - BreastCancerRecurrenceItem
*/
// @Route("/medical-review/form/breast-cancer-recurrence-item/create", "POST")
// @Api(Description="Form - BreastCancerRecurrenceItem")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.BreastCancerRecurrenceItemSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.BreastCancerRecurrenceItemSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.BreastCancerRecurrenceItemSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.BreastCancerRecurrenceItemSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.BreastCancerRecurrenceItemSingleResponse)", StatusCode=500)
// @DataContract
export class BreastCancerRecurrenceItemPostCreate implements IReturn<BreastCancerRecurrenceItemSingleResponse>
{
    /**
    * The metadata to use when creating the breast cancer recurrence item.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the breast cancer recurrence item.
    */
    // @DataMember(Order=2)
    public data: BreastCancerRecurrenceItem;

    public constructor(init?: Partial<BreastCancerRecurrenceItemPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BreastCancerRecurrenceItemPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new BreastCancerRecurrenceItemSingleResponse(); }
}

/**
* Form - BreastCancerRecurrenceItem
*/
// @Route("/medical-review/form/breast-cancer-recurrence-item/save", "POST")
// @Api(Description="Form - BreastCancerRecurrenceItem")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.BreastCancerRecurrenceItemSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.BreastCancerRecurrenceItemSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.BreastCancerRecurrenceItemSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.BreastCancerRecurrenceItemSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.BreastCancerRecurrenceItemSingleResponse)", StatusCode=500)
// @DataContract
export class BreastCancerRecurrenceItemPostSave implements IReturn<BreastCancerRecurrenceItemSingleResponse>
{
    /**
    * The metadata to use when creating the breast cancer recurrence item.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The breast cancer recurrence item to save.
    */
    // @DataMember(Order=2)
    public data: BreastCancerRecurrenceItem;

    public constructor(init?: Partial<BreastCancerRecurrenceItemPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BreastCancerRecurrenceItemPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BreastCancerRecurrenceItemSingleResponse(); }
}

/**
* Form - BreastCancerRecurrenceItem
*/
// @Route("/medical-review/form/breast-cancer-recurrence-item/validate", "POST")
// @Api(Description="Form - BreastCancerRecurrenceItem")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.BreastCancerRecurrenceItemValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.BreastCancerRecurrenceItemValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.BreastCancerRecurrenceItemValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.BreastCancerRecurrenceItemValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.BreastCancerRecurrenceItemValidationResponse)", StatusCode=500)
// @DataContract
export class BreastCancerRecurrenceItemPostValidate implements IReturn<BreastCancerRecurrenceItemValidationResponse>
{
    /**
    * The metadata to use when validating the breast cancer recurrence item.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The breast cancer recurrence item to validate.
    */
    // @DataMember(Order=2)
    public data: BreastCancerRecurrenceItem;

    public constructor(init?: Partial<BreastCancerRecurrenceItemPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BreastCancerRecurrenceItemPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BreastCancerRecurrenceItemValidationResponse(); }
}

/**
* Form - BreastCancerRecurrenceItem
*/
// @Route("/medical-review/form/breast-cancer-recurrence-item/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - BreastCancerRecurrenceItem")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.BreastCancerRecurrenceItemSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.BreastCancerRecurrenceItemSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.BreastCancerRecurrenceItemSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.BreastCancerRecurrenceItemSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.BreastCancerRecurrenceItemSingleResponse)", StatusCode=500)
// @DataContract
export class BreastCancerRecurrenceItemGetSingleByMedicalReviewIdAndRepeat implements IReturn<BreastCancerRecurrenceItemSingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    /**
    * The id of the medical review.
    */
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    /**
    * The repeat of the breast cancer recurrence item to retrieve.
    */
    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<BreastCancerRecurrenceItemGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BreastCancerRecurrenceItemGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new BreastCancerRecurrenceItemSingleResponse(); }
}

/**
* Form - BreastCancerRecurrenceItem
*/
// @Route("/medical-review/form/breast-cancer-recurrence-item/single/update", "POST")
// @Api(Description="Form - BreastCancerRecurrenceItem")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.BreastCancerRecurrenceItemSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.BreastCancerRecurrenceItemSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.BreastCancerRecurrenceItemSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.BreastCancerRecurrenceItemSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.BreastCancerRecurrenceItemSingleResponse)", StatusCode=500)
// @DataContract
export class BreastCancerRecurrenceItemPostUpdateById implements IReturn<BreastCancerRecurrenceItemSingleResponse>, IFormPostUpdateById
{
    /**
    * The id of the medical review form.
    */
    // @DataMember(Order=1)
    public id?: number;

    /**
    * The additional metadata for the medical review.
    */
    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<BreastCancerRecurrenceItemPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BreastCancerRecurrenceItemPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BreastCancerRecurrenceItemSingleResponse(); }
}

/**
* Form - BreastUltrasound
*/
// @Route("/medical-review/form/breast-ultrasound/single/id/{Id}", "GET")
// @Api(Description="Form - BreastUltrasound")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.BreastUltrasoundSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.BreastUltrasoundSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.BreastUltrasoundSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.BreastUltrasoundSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.BreastUltrasoundSingleResponse)", StatusCode=500)
// @DataContract
export class BreastUltrasoundGetSingleById implements IReturn<BreastUltrasoundSingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the breast ultrasound to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<BreastUltrasoundGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BreastUltrasoundGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new BreastUltrasoundSingleResponse(); }
}

/**
* Form - BreastUltrasound
*/
// @Route("/medical-review/form/breast-ultrasound/collection", "GET")
// @Api(Description="Form - BreastUltrasound")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.BreastUltrasoundCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.BreastUltrasoundCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.BreastUltrasoundCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.BreastUltrasoundCollectionResponse)", StatusCode=500)
// @DataContract
export class BreastUltrasoundGetCollection implements IReturn<BreastUltrasoundCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<BreastUltrasoundGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BreastUltrasoundGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new BreastUltrasoundCollectionResponse(); }
}

/**
* Form - BreastUltrasound
*/
// @Route("/medical-review/form/breast-ultrasound/create", "POST")
// @Api(Description="Form - BreastUltrasound")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.BreastUltrasoundSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.BreastUltrasoundSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.BreastUltrasoundSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.BreastUltrasoundSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.BreastUltrasoundSingleResponse)", StatusCode=500)
// @DataContract
export class BreastUltrasoundPostCreate implements IReturn<BreastUltrasoundSingleResponse>
{
    /**
    * The metadata to use when creating the breast ultrasound.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the breast ultrasound.
    */
    // @DataMember(Order=2)
    public data: BreastUltrasound;

    public constructor(init?: Partial<BreastUltrasoundPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BreastUltrasoundPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new BreastUltrasoundSingleResponse(); }
}

/**
* Form - BreastUltrasound
*/
// @Route("/medical-review/form/breast-ultrasound/save", "POST")
// @Api(Description="Form - BreastUltrasound")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.BreastUltrasoundSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.BreastUltrasoundSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.BreastUltrasoundSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.BreastUltrasoundSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.BreastUltrasoundSingleResponse)", StatusCode=500)
// @DataContract
export class BreastUltrasoundPostSave implements IReturn<BreastUltrasoundSingleResponse>
{
    /**
    * The metadata to use when creating the breast ultrasound.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The breast ultrasound to save.
    */
    // @DataMember(Order=2)
    public data: BreastUltrasound;

    public constructor(init?: Partial<BreastUltrasoundPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BreastUltrasoundPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BreastUltrasoundSingleResponse(); }
}

/**
* Form - BreastUltrasound
*/
// @Route("/medical-review/form/breast-ultrasound/validate", "POST")
// @Api(Description="Form - BreastUltrasound")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.BreastUltrasoundValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.BreastUltrasoundValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.BreastUltrasoundValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.BreastUltrasoundValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.BreastUltrasoundValidationResponse)", StatusCode=500)
// @DataContract
export class BreastUltrasoundPostValidate implements IReturn<BreastUltrasoundValidationResponse>
{
    /**
    * The metadata to use when validating the breast ultrasound.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The breast ultrasound to validate.
    */
    // @DataMember(Order=2)
    public data: BreastUltrasound;

    public constructor(init?: Partial<BreastUltrasoundPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BreastUltrasoundPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BreastUltrasoundValidationResponse(); }
}

/**
* Form - BreastUltrasound
*/
// @Route("/medical-review/form/breast-ultrasound/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - BreastUltrasound")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.BreastUltrasoundSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.BreastUltrasoundSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.BreastUltrasoundSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.BreastUltrasoundSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.BreastUltrasoundSingleResponse)", StatusCode=500)
// @DataContract
export class BreastUltrasoundGetSingleByMedicalReviewIdAndRepeat implements IReturn<BreastUltrasoundSingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    /**
    * The id of the medical review.
    */
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    /**
    * The repeat of the breast ultrasound to retrieve.
    */
    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<BreastUltrasoundGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BreastUltrasoundGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new BreastUltrasoundSingleResponse(); }
}

/**
* Form - BreastUltrasound
*/
// @Route("/medical-review/form/breast-ultrasound/single/update", "POST")
// @Api(Description="Form - BreastUltrasound")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.BreastUltrasoundSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.BreastUltrasoundSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.BreastUltrasoundSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.BreastUltrasoundSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.BreastUltrasoundSingleResponse)", StatusCode=500)
// @DataContract
export class BreastUltrasoundPostUpdateById implements IReturn<BreastUltrasoundSingleResponse>, IFormPostUpdateById
{
    /**
    * The id of the medical review form.
    */
    // @DataMember(Order=1)
    public id?: number;

    /**
    * The additional metadata for the medical review.
    */
    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<BreastUltrasoundPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BreastUltrasoundPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BreastUltrasoundSingleResponse(); }
}

/**
* Form - CarboplatinTherapy
*/
// @Route("/medical-review/form/carboplatin-therapy/single/id/{Id}", "GET")
// @Api(Description="Form - CarboplatinTherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.CarboplatinTherapySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.CarboplatinTherapySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.CarboplatinTherapySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.CarboplatinTherapySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.CarboplatinTherapySingleResponse)", StatusCode=500)
// @DataContract
export class CarboplatinTherapyGetSingleById implements IReturn<CarboplatinTherapySingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the carboplatin therapy to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<CarboplatinTherapyGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CarboplatinTherapyGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CarboplatinTherapySingleResponse(); }
}

/**
* Form - CarboplatinTherapy
*/
// @Route("/medical-review/form/carboplatin-therapy/collection", "GET")
// @Api(Description="Form - CarboplatinTherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.CarboplatinTherapyCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.CarboplatinTherapyCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.CarboplatinTherapyCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.CarboplatinTherapyCollectionResponse)", StatusCode=500)
// @DataContract
export class CarboplatinTherapyGetCollection implements IReturn<CarboplatinTherapyCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<CarboplatinTherapyGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CarboplatinTherapyGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CarboplatinTherapyCollectionResponse(); }
}

/**
* Form - CarboplatinTherapy
*/
// @Route("/medical-review/form/carboplatin-therapy/create", "POST")
// @Api(Description="Form - CarboplatinTherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.CarboplatinTherapySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.CarboplatinTherapySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.CarboplatinTherapySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.CarboplatinTherapySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.CarboplatinTherapySingleResponse)", StatusCode=500)
// @DataContract
export class CarboplatinTherapyPostCreate implements IReturn<CarboplatinTherapySingleResponse>
{
    /**
    * The metadata to use when creating the carboplatin therapy.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the carboplatin therapy.
    */
    // @DataMember(Order=2)
    public data: CarboplatinTherapy;

    public constructor(init?: Partial<CarboplatinTherapyPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CarboplatinTherapyPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CarboplatinTherapySingleResponse(); }
}

/**
* Form - CarboplatinTherapy
*/
// @Route("/medical-review/form/carboplatin-therapy/save", "POST")
// @Api(Description="Form - CarboplatinTherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.CarboplatinTherapySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.CarboplatinTherapySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.CarboplatinTherapySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.CarboplatinTherapySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.CarboplatinTherapySingleResponse)", StatusCode=500)
// @DataContract
export class CarboplatinTherapyPostSave implements IReturn<CarboplatinTherapySingleResponse>
{
    /**
    * The metadata to use when creating the carboplatin therapy.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The carboplatin therapy to save.
    */
    // @DataMember(Order=2)
    public data: CarboplatinTherapy;

    public constructor(init?: Partial<CarboplatinTherapyPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CarboplatinTherapyPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CarboplatinTherapySingleResponse(); }
}

/**
* Form - CarboplatinTherapy
*/
// @Route("/medical-review/form/carboplatin-therapy/validate", "POST")
// @Api(Description="Form - CarboplatinTherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.CarboplatinTherapyValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.CarboplatinTherapyValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.CarboplatinTherapyValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.CarboplatinTherapyValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.CarboplatinTherapyValidationResponse)", StatusCode=500)
// @DataContract
export class CarboplatinTherapyPostValidate implements IReturn<CarboplatinTherapyValidationResponse>
{
    /**
    * The metadata to use when validating the carboplatin therapy.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The carboplatin therapy to validate.
    */
    // @DataMember(Order=2)
    public data: CarboplatinTherapy;

    public constructor(init?: Partial<CarboplatinTherapyPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CarboplatinTherapyPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CarboplatinTherapyValidationResponse(); }
}

/**
* Form - CarboplatinTherapy
*/
// @Route("/medical-review/form/carboplatin-therapy/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - CarboplatinTherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.CarboplatinTherapySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.CarboplatinTherapySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.CarboplatinTherapySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.CarboplatinTherapySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.CarboplatinTherapySingleResponse)", StatusCode=500)
// @DataContract
export class CarboplatinTherapyGetSingleByMedicalReviewIdAndRepeat implements IReturn<CarboplatinTherapySingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    /**
    * The id of the medical review.
    */
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    /**
    * The repeat of the carboplatin therapy to retrieve.
    */
    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<CarboplatinTherapyGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CarboplatinTherapyGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CarboplatinTherapySingleResponse(); }
}

/**
* Form - CarboplatinTherapy
*/
// @Route("/medical-review/form/carboplatin-therapy/single/update", "POST")
// @Api(Description="Form - CarboplatinTherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.CarboplatinTherapySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.CarboplatinTherapySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.CarboplatinTherapySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.CarboplatinTherapySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.CarboplatinTherapySingleResponse)", StatusCode=500)
// @DataContract
export class CarboplatinTherapyPostUpdateById implements IReturn<CarboplatinTherapySingleResponse>, IFormPostUpdateById
{
    /**
    * The id of the medical review form.
    */
    // @DataMember(Order=1)
    public id?: number;

    /**
    * The additional metadata for the medical review.
    */
    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<CarboplatinTherapyPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CarboplatinTherapyPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CarboplatinTherapySingleResponse(); }
}

/**
* Form - Chemistry
*/
// @Route("/medical-review/form/chemistry/single/id/{Id}", "GET")
// @Api(Description="Form - Chemistry")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ChemistrySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ChemistrySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ChemistrySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ChemistrySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ChemistrySingleResponse)", StatusCode=500)
// @DataContract
export class ChemistryGetSingleById implements IReturn<ChemistrySingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the chemistry to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<ChemistryGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ChemistryGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ChemistrySingleResponse(); }
}

/**
* Form - Chemistry
*/
// @Route("/medical-review/form/chemistry/collection", "GET")
// @Api(Description="Form - Chemistry")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ChemistryCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ChemistryCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ChemistryCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ChemistryCollectionResponse)", StatusCode=500)
// @DataContract
export class ChemistryGetCollection implements IReturn<ChemistryCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<ChemistryGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ChemistryGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ChemistryCollectionResponse(); }
}

/**
* Form - Chemistry
*/
// @Route("/medical-review/form/chemistry/create", "POST")
// @Api(Description="Form - Chemistry")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ChemistrySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ChemistrySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ChemistrySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ChemistrySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ChemistrySingleResponse)", StatusCode=500)
// @DataContract
export class ChemistryPostCreate implements IReturn<ChemistrySingleResponse>
{
    /**
    * The metadata to use when creating the chemistry.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the chemistry.
    */
    // @DataMember(Order=2)
    public data: Chemistry;

    public constructor(init?: Partial<ChemistryPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ChemistryPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ChemistrySingleResponse(); }
}

/**
* Form - Chemistry
*/
// @Route("/medical-review/form/chemistry/save", "POST")
// @Api(Description="Form - Chemistry")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ChemistrySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ChemistrySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ChemistrySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ChemistrySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ChemistrySingleResponse)", StatusCode=500)
// @DataContract
export class ChemistryPostSave implements IReturn<ChemistrySingleResponse>
{
    /**
    * The metadata to use when creating the chemistry.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The chemistry to save.
    */
    // @DataMember(Order=2)
    public data: Chemistry;

    public constructor(init?: Partial<ChemistryPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ChemistryPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ChemistrySingleResponse(); }
}

/**
* Form - Chemistry
*/
// @Route("/medical-review/form/chemistry/validate", "POST")
// @Api(Description="Form - Chemistry")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ChemistryValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ChemistryValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ChemistryValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ChemistryValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ChemistryValidationResponse)", StatusCode=500)
// @DataContract
export class ChemistryPostValidate implements IReturn<ChemistryValidationResponse>
{
    /**
    * The metadata to use when validating the chemistry.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The chemistry to validate.
    */
    // @DataMember(Order=2)
    public data: Chemistry;

    public constructor(init?: Partial<ChemistryPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ChemistryPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ChemistryValidationResponse(); }
}

/**
* Form - Chemistry
*/
// @Route("/medical-review/form/chemistry/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - Chemistry")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ChemistrySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ChemistrySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ChemistrySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ChemistrySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ChemistrySingleResponse)", StatusCode=500)
// @DataContract
export class ChemistryGetSingleByMedicalReviewIdAndRepeat implements IReturn<ChemistrySingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    /**
    * The id of the medical review.
    */
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    /**
    * The repeat of the chemistry to retrieve.
    */
    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<ChemistryGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ChemistryGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ChemistrySingleResponse(); }
}

/**
* Form - Chemistry
*/
// @Route("/medical-review/form/chemistry/single/update", "POST")
// @Api(Description="Form - Chemistry")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ChemistrySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ChemistrySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ChemistrySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ChemistrySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ChemistrySingleResponse)", StatusCode=500)
// @DataContract
export class ChemistryPostUpdateById implements IReturn<ChemistrySingleResponse>, IFormPostUpdateById
{
    /**
    * The id of the medical review form.
    */
    // @DataMember(Order=1)
    public id?: number;

    /**
    * The additional metadata for the medical review.
    */
    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<ChemistryPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ChemistryPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ChemistrySingleResponse(); }
}

/**
* Form - Coagulation
*/
// @Route("/medical-review/form/coagulation/single/id/{Id}", "GET")
// @Api(Description="Form - Coagulation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.CoagulationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.CoagulationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.CoagulationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.CoagulationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.CoagulationSingleResponse)", StatusCode=500)
// @DataContract
export class CoagulationGetSingleById implements IReturn<CoagulationSingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the coagulation to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<CoagulationGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CoagulationGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CoagulationSingleResponse(); }
}

/**
* Form - Coagulation
*/
// @Route("/medical-review/form/coagulation/collection", "GET")
// @Api(Description="Form - Coagulation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.CoagulationCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.CoagulationCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.CoagulationCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.CoagulationCollectionResponse)", StatusCode=500)
// @DataContract
export class CoagulationGetCollection implements IReturn<CoagulationCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<CoagulationGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CoagulationGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CoagulationCollectionResponse(); }
}

/**
* Form - Coagulation
*/
// @Route("/medical-review/form/coagulation/create", "POST")
// @Api(Description="Form - Coagulation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.CoagulationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.CoagulationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.CoagulationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.CoagulationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.CoagulationSingleResponse)", StatusCode=500)
// @DataContract
export class CoagulationPostCreate implements IReturn<CoagulationSingleResponse>
{
    /**
    * The metadata to use when creating the coagulation.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the coagulation.
    */
    // @DataMember(Order=2)
    public data: Coagulation;

    public constructor(init?: Partial<CoagulationPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CoagulationPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CoagulationSingleResponse(); }
}

/**
* Form - Coagulation
*/
// @Route("/medical-review/form/coagulation/save", "POST")
// @Api(Description="Form - Coagulation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.CoagulationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.CoagulationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.CoagulationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.CoagulationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.CoagulationSingleResponse)", StatusCode=500)
// @DataContract
export class CoagulationPostSave implements IReturn<CoagulationSingleResponse>
{
    /**
    * The metadata to use when creating the coagulation.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The coagulation to save.
    */
    // @DataMember(Order=2)
    public data: Coagulation;

    public constructor(init?: Partial<CoagulationPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CoagulationPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CoagulationSingleResponse(); }
}

/**
* Form - Coagulation
*/
// @Route("/medical-review/form/coagulation/validate", "POST")
// @Api(Description="Form - Coagulation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.CoagulationValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.CoagulationValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.CoagulationValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.CoagulationValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.CoagulationValidationResponse)", StatusCode=500)
// @DataContract
export class CoagulationPostValidate implements IReturn<CoagulationValidationResponse>
{
    /**
    * The metadata to use when validating the coagulation.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The coagulation to validate.
    */
    // @DataMember(Order=2)
    public data: Coagulation;

    public constructor(init?: Partial<CoagulationPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CoagulationPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CoagulationValidationResponse(); }
}

/**
* Form - Coagulation
*/
// @Route("/medical-review/form/coagulation/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - Coagulation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.CoagulationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.CoagulationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.CoagulationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.CoagulationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.CoagulationSingleResponse)", StatusCode=500)
// @DataContract
export class CoagulationGetSingleByMedicalReviewIdAndRepeat implements IReturn<CoagulationSingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    /**
    * The id of the medical review.
    */
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    /**
    * The repeat of the coagulation to retrieve.
    */
    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<CoagulationGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CoagulationGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CoagulationSingleResponse(); }
}

/**
* Form - Coagulation
*/
// @Route("/medical-review/form/coagulation/single/update", "POST")
// @Api(Description="Form - Coagulation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.CoagulationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.CoagulationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.CoagulationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.CoagulationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.CoagulationSingleResponse)", StatusCode=500)
// @DataContract
export class CoagulationPostUpdateById implements IReturn<CoagulationSingleResponse>, IFormPostUpdateById
{
    /**
    * The id of the medical review form.
    */
    // @DataMember(Order=1)
    public id?: number;

    /**
    * The additional metadata for the medical review.
    */
    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<CoagulationPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CoagulationPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CoagulationSingleResponse(); }
}

/**
* Form - ConcomitantMedicationItem
*/
// @Route("/medical-review/form/concomitant-medication-item/single/id/{Id}", "GET")
// @Api(Description="Form - ConcomitantMedicationItem")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationItemSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationItemSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationItemSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationItemSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationItemSingleResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationItemGetSingleById implements IReturn<ConcomitantMedicationItemSingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the concomitant medication item to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<ConcomitantMedicationItemGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationItemGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ConcomitantMedicationItemSingleResponse(); }
}

/**
* Form - ConcomitantMedicationItem
*/
// @Route("/medical-review/form/concomitant-medication-item/collection", "GET")
// @Api(Description="Form - ConcomitantMedicationItem")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationItemCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationItemCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationItemCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationItemCollectionResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationItemGetCollection implements IReturn<ConcomitantMedicationItemCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<ConcomitantMedicationItemGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationItemGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ConcomitantMedicationItemCollectionResponse(); }
}

/**
* Form - ConcomitantMedicationItem
*/
// @Route("/medical-review/form/concomitant-medication-item/create", "POST")
// @Api(Description="Form - ConcomitantMedicationItem")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationItemSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationItemSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationItemSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationItemSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationItemSingleResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationItemPostCreate implements IReturn<ConcomitantMedicationItemSingleResponse>
{
    /**
    * The metadata to use when creating the concomitant medication item.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the concomitant medication item.
    */
    // @DataMember(Order=2)
    public data: ConcomitantMedicationItem;

    public constructor(init?: Partial<ConcomitantMedicationItemPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationItemPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ConcomitantMedicationItemSingleResponse(); }
}

/**
* Form - ConcomitantMedicationItem
*/
// @Route("/medical-review/form/concomitant-medication-item/save", "POST")
// @Api(Description="Form - ConcomitantMedicationItem")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationItemSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationItemSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationItemSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationItemSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationItemSingleResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationItemPostSave implements IReturn<ConcomitantMedicationItemSingleResponse>
{
    /**
    * The metadata to use when creating the concomitant medication item.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The concomitant medication item to save.
    */
    // @DataMember(Order=2)
    public data: ConcomitantMedicationItem;

    public constructor(init?: Partial<ConcomitantMedicationItemPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationItemPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ConcomitantMedicationItemSingleResponse(); }
}

/**
* Form - ConcomitantMedicationItem
*/
// @Route("/medical-review/form/concomitant-medication-item/validate", "POST")
// @Api(Description="Form - ConcomitantMedicationItem")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationItemValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationItemValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationItemValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationItemValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationItemValidationResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationItemPostValidate implements IReturn<ConcomitantMedicationItemValidationResponse>
{
    /**
    * The metadata to use when validating the concomitant medication item.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The concomitant medication item to validate.
    */
    // @DataMember(Order=2)
    public data: ConcomitantMedicationItem;

    public constructor(init?: Partial<ConcomitantMedicationItemPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationItemPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ConcomitantMedicationItemValidationResponse(); }
}

/**
* Form - ConcomitantMedicationItem
*/
// @Route("/medical-review/form/concomitant-medication-item/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - ConcomitantMedicationItem")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationItemSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationItemSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationItemSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationItemSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationItemSingleResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationItemGetSingleByMedicalReviewIdAndRepeat implements IReturn<ConcomitantMedicationItemSingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    /**
    * The id of the medical review.
    */
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    /**
    * The repeat of the concomitant medication item to retrieve.
    */
    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<ConcomitantMedicationItemGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationItemGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ConcomitantMedicationItemSingleResponse(); }
}

/**
* Form - ConcomitantMedicationItem
*/
// @Route("/medical-review/form/concomitant-medication-item/single/update", "POST")
// @Api(Description="Form - ConcomitantMedicationItem")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationItemSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationItemSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationItemSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationItemSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationItemSingleResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationItemPostUpdateById implements IReturn<ConcomitantMedicationItemSingleResponse>, IFormPostUpdateById
{
    /**
    * The id of the medical review form.
    */
    // @DataMember(Order=1)
    public id?: number;

    /**
    * The additional metadata for the medical review.
    */
    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<ConcomitantMedicationItemPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationItemPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ConcomitantMedicationItemSingleResponse(); }
}

/**
* Form - DeathReportItem
*/
// @Route("/medical-review/form/death-report-item/single/id/{Id}", "GET")
// @Api(Description="Form - DeathReportItem")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.DeathReportItemSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.DeathReportItemSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.DeathReportItemSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.DeathReportItemSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.DeathReportItemSingleResponse)", StatusCode=500)
// @DataContract
export class DeathReportItemGetSingleById implements IReturn<DeathReportItemSingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the death report item to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<DeathReportItemGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeathReportItemGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new DeathReportItemSingleResponse(); }
}

/**
* Form - DeathReportItem
*/
// @Route("/medical-review/form/death-report-item/collection", "GET")
// @Api(Description="Form - DeathReportItem")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.DeathReportItemCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.DeathReportItemCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.DeathReportItemCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.DeathReportItemCollectionResponse)", StatusCode=500)
// @DataContract
export class DeathReportItemGetCollection implements IReturn<DeathReportItemCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<DeathReportItemGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeathReportItemGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new DeathReportItemCollectionResponse(); }
}

/**
* Form - DeathReportItem
*/
// @Route("/medical-review/form/death-report-item/create", "POST")
// @Api(Description="Form - DeathReportItem")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.DeathReportItemSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.DeathReportItemSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.DeathReportItemSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.DeathReportItemSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.DeathReportItemSingleResponse)", StatusCode=500)
// @DataContract
export class DeathReportItemPostCreate implements IReturn<DeathReportItemSingleResponse>
{
    /**
    * The metadata to use when creating the death report item.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the death report item.
    */
    // @DataMember(Order=2)
    public data: DeathReportItem;

    public constructor(init?: Partial<DeathReportItemPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeathReportItemPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new DeathReportItemSingleResponse(); }
}

/**
* Form - DeathReportItem
*/
// @Route("/medical-review/form/death-report-item/save", "POST")
// @Api(Description="Form - DeathReportItem")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.DeathReportItemSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.DeathReportItemSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.DeathReportItemSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.DeathReportItemSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.DeathReportItemSingleResponse)", StatusCode=500)
// @DataContract
export class DeathReportItemPostSave implements IReturn<DeathReportItemSingleResponse>
{
    /**
    * The metadata to use when creating the death report item.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The death report item to save.
    */
    // @DataMember(Order=2)
    public data: DeathReportItem;

    public constructor(init?: Partial<DeathReportItemPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeathReportItemPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new DeathReportItemSingleResponse(); }
}

/**
* Form - DeathReportItem
*/
// @Route("/medical-review/form/death-report-item/validate", "POST")
// @Api(Description="Form - DeathReportItem")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.DeathReportItemValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.DeathReportItemValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.DeathReportItemValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.DeathReportItemValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.DeathReportItemValidationResponse)", StatusCode=500)
// @DataContract
export class DeathReportItemPostValidate implements IReturn<DeathReportItemValidationResponse>
{
    /**
    * The metadata to use when validating the death report item.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The death report item to validate.
    */
    // @DataMember(Order=2)
    public data: DeathReportItem;

    public constructor(init?: Partial<DeathReportItemPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeathReportItemPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new DeathReportItemValidationResponse(); }
}

/**
* Form - DeathReportItem
*/
// @Route("/medical-review/form/death-report-item/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - DeathReportItem")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.DeathReportItemSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.DeathReportItemSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.DeathReportItemSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.DeathReportItemSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.DeathReportItemSingleResponse)", StatusCode=500)
// @DataContract
export class DeathReportItemGetSingleByMedicalReviewIdAndRepeat implements IReturn<DeathReportItemSingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    /**
    * The id of the medical review.
    */
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    /**
    * The repeat of the death report item to retrieve.
    */
    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<DeathReportItemGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeathReportItemGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new DeathReportItemSingleResponse(); }
}

/**
* Form - DeathReportItem
*/
// @Route("/medical-review/form/death-report-item/single/update", "POST")
// @Api(Description="Form - DeathReportItem")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.DeathReportItemSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.DeathReportItemSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.DeathReportItemSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.DeathReportItemSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.DeathReportItemSingleResponse)", StatusCode=500)
// @DataContract
export class DeathReportItemPostUpdateById implements IReturn<DeathReportItemSingleResponse>, IFormPostUpdateById
{
    /**
    * The id of the medical review form.
    */
    // @DataMember(Order=1)
    public id?: number;

    /**
    * The additional metadata for the medical review.
    */
    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<DeathReportItemPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeathReportItemPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new DeathReportItemSingleResponse(); }
}

/**
* Form - Haematology
*/
// @Route("/medical-review/form/haematology/single/id/{Id}", "GET")
// @Api(Description="Form - Haematology")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.HaematologySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.HaematologySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.HaematologySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.HaematologySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.HaematologySingleResponse)", StatusCode=500)
// @DataContract
export class HaematologyGetSingleById implements IReturn<HaematologySingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the haematology to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<HaematologyGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'HaematologyGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new HaematologySingleResponse(); }
}

/**
* Form - Haematology
*/
// @Route("/medical-review/form/haematology/collection", "GET")
// @Api(Description="Form - Haematology")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.HaematologyCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.HaematologyCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.HaematologyCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.HaematologyCollectionResponse)", StatusCode=500)
// @DataContract
export class HaematologyGetCollection implements IReturn<HaematologyCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<HaematologyGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'HaematologyGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new HaematologyCollectionResponse(); }
}

/**
* Form - Haematology
*/
// @Route("/medical-review/form/haematology/create", "POST")
// @Api(Description="Form - Haematology")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.HaematologySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.HaematologySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.HaematologySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.HaematologySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.HaematologySingleResponse)", StatusCode=500)
// @DataContract
export class HaematologyPostCreate implements IReturn<HaematologySingleResponse>
{
    /**
    * The metadata to use when creating the haematology.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the haematology.
    */
    // @DataMember(Order=2)
    public data: Haematology;

    public constructor(init?: Partial<HaematologyPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'HaematologyPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new HaematologySingleResponse(); }
}

/**
* Form - Haematology
*/
// @Route("/medical-review/form/haematology/save", "POST")
// @Api(Description="Form - Haematology")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.HaematologySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.HaematologySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.HaematologySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.HaematologySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.HaematologySingleResponse)", StatusCode=500)
// @DataContract
export class HaematologyPostSave implements IReturn<HaematologySingleResponse>
{
    /**
    * The metadata to use when creating the haematology.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The haematology to save.
    */
    // @DataMember(Order=2)
    public data: Haematology;

    public constructor(init?: Partial<HaematologyPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'HaematologyPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new HaematologySingleResponse(); }
}

/**
* Form - Haematology
*/
// @Route("/medical-review/form/haematology/validate", "POST")
// @Api(Description="Form - Haematology")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.HaematologyValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.HaematologyValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.HaematologyValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.HaematologyValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.HaematologyValidationResponse)", StatusCode=500)
// @DataContract
export class HaematologyPostValidate implements IReturn<HaematologyValidationResponse>
{
    /**
    * The metadata to use when validating the haematology.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The haematology to validate.
    */
    // @DataMember(Order=2)
    public data: Haematology;

    public constructor(init?: Partial<HaematologyPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'HaematologyPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new HaematologyValidationResponse(); }
}

/**
* Form - Haematology
*/
// @Route("/medical-review/form/haematology/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - Haematology")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.HaematologySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.HaematologySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.HaematologySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.HaematologySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.HaematologySingleResponse)", StatusCode=500)
// @DataContract
export class HaematologyGetSingleByMedicalReviewIdAndRepeat implements IReturn<HaematologySingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    /**
    * The id of the medical review.
    */
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    /**
    * The repeat of the haematology to retrieve.
    */
    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<HaematologyGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'HaematologyGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new HaematologySingleResponse(); }
}

/**
* Form - Haematology
*/
// @Route("/medical-review/form/haematology/single/update", "POST")
// @Api(Description="Form - Haematology")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.HaematologySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.HaematologySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.HaematologySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.HaematologySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.HaematologySingleResponse)", StatusCode=500)
// @DataContract
export class HaematologyPostUpdateById implements IReturn<HaematologySingleResponse>, IFormPostUpdateById
{
    /**
    * The id of the medical review form.
    */
    // @DataMember(Order=1)
    public id?: number;

    /**
    * The additional metadata for the medical review.
    */
    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<HaematologyPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'HaematologyPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new HaematologySingleResponse(); }
}

/**
* Form - MedicalHistoryItem
*/
// @Route("/medical-review/form/medical-history-item/single/id/{Id}", "GET")
// @Api(Description="Form - MedicalHistoryItem")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.MedicalHistoryItemSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.MedicalHistoryItemSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.MedicalHistoryItemSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.MedicalHistoryItemSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.MedicalHistoryItemSingleResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryItemGetSingleById implements IReturn<MedicalHistoryItemSingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the medical history item to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<MedicalHistoryItemGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalHistoryItemGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MedicalHistoryItemSingleResponse(); }
}

/**
* Form - MedicalHistoryItem
*/
// @Route("/medical-review/form/medical-history-item/collection", "GET")
// @Api(Description="Form - MedicalHistoryItem")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.MedicalHistoryItemCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.MedicalHistoryItemCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.MedicalHistoryItemCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.MedicalHistoryItemCollectionResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryItemGetCollection implements IReturn<MedicalHistoryItemCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<MedicalHistoryItemGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalHistoryItemGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MedicalHistoryItemCollectionResponse(); }
}

/**
* Form - MedicalHistoryItem
*/
// @Route("/medical-review/form/medical-history-item/create", "POST")
// @Api(Description="Form - MedicalHistoryItem")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.MedicalHistoryItemSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.MedicalHistoryItemSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.MedicalHistoryItemSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.MedicalHistoryItemSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.MedicalHistoryItemSingleResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryItemPostCreate implements IReturn<MedicalHistoryItemSingleResponse>
{
    /**
    * The metadata to use when creating the medical history item.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the medical history item.
    */
    // @DataMember(Order=2)
    public data: MedicalHistoryItem;

    public constructor(init?: Partial<MedicalHistoryItemPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalHistoryItemPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MedicalHistoryItemSingleResponse(); }
}

/**
* Form - MedicalHistoryItem
*/
// @Route("/medical-review/form/medical-history-item/save", "POST")
// @Api(Description="Form - MedicalHistoryItem")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.MedicalHistoryItemSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.MedicalHistoryItemSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.MedicalHistoryItemSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.MedicalHistoryItemSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.MedicalHistoryItemSingleResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryItemPostSave implements IReturn<MedicalHistoryItemSingleResponse>
{
    /**
    * The metadata to use when creating the medical history item.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The medical history item to save.
    */
    // @DataMember(Order=2)
    public data: MedicalHistoryItem;

    public constructor(init?: Partial<MedicalHistoryItemPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalHistoryItemPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new MedicalHistoryItemSingleResponse(); }
}

/**
* Form - MedicalHistoryItem
*/
// @Route("/medical-review/form/medical-history-item/validate", "POST")
// @Api(Description="Form - MedicalHistoryItem")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.MedicalHistoryItemValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.MedicalHistoryItemValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.MedicalHistoryItemValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.MedicalHistoryItemValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.MedicalHistoryItemValidationResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryItemPostValidate implements IReturn<MedicalHistoryItemValidationResponse>
{
    /**
    * The metadata to use when validating the medical history item.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The medical history item to validate.
    */
    // @DataMember(Order=2)
    public data: MedicalHistoryItem;

    public constructor(init?: Partial<MedicalHistoryItemPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalHistoryItemPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new MedicalHistoryItemValidationResponse(); }
}

/**
* Form - MedicalHistoryItem
*/
// @Route("/medical-review/form/medical-history-item/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - MedicalHistoryItem")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.MedicalHistoryItemSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.MedicalHistoryItemSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.MedicalHistoryItemSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.MedicalHistoryItemSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.MedicalHistoryItemSingleResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryItemGetSingleByMedicalReviewIdAndRepeat implements IReturn<MedicalHistoryItemSingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    /**
    * The id of the medical review.
    */
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    /**
    * The repeat of the medical history item to retrieve.
    */
    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<MedicalHistoryItemGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalHistoryItemGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MedicalHistoryItemSingleResponse(); }
}

/**
* Form - MedicalHistoryItem
*/
// @Route("/medical-review/form/medical-history-item/single/update", "POST")
// @Api(Description="Form - MedicalHistoryItem")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.MedicalHistoryItemSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.MedicalHistoryItemSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.MedicalHistoryItemSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.MedicalHistoryItemSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.MedicalHistoryItemSingleResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryItemPostUpdateById implements IReturn<MedicalHistoryItemSingleResponse>, IFormPostUpdateById
{
    /**
    * The id of the medical review form.
    */
    // @DataMember(Order=1)
    public id?: number;

    /**
    * The additional metadata for the medical review.
    */
    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<MedicalHistoryItemPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalHistoryItemPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new MedicalHistoryItemSingleResponse(); }
}

/**
* Form - MedicalHistoryMedication
*/
// @Route("/medical-review/form/medical-history-medication/single/id/{Id}", "GET")
// @Api(Description="Form - MedicalHistoryMedication")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.MedicalHistoryMedicationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.MedicalHistoryMedicationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.MedicalHistoryMedicationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.MedicalHistoryMedicationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.MedicalHistoryMedicationSingleResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryMedicationGetSingleById implements IReturn<MedicalHistoryMedicationSingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the medical history medication to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<MedicalHistoryMedicationGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalHistoryMedicationGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MedicalHistoryMedicationSingleResponse(); }
}

/**
* Form - MedicalHistoryMedication
*/
// @Route("/medical-review/form/medical-history-medication/collection", "GET")
// @Api(Description="Form - MedicalHistoryMedication")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.MedicalHistoryMedicationCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.MedicalHistoryMedicationCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.MedicalHistoryMedicationCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.MedicalHistoryMedicationCollectionResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryMedicationGetCollection implements IReturn<MedicalHistoryMedicationCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<MedicalHistoryMedicationGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalHistoryMedicationGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MedicalHistoryMedicationCollectionResponse(); }
}

/**
* Form - MedicalHistoryMedication
*/
// @Route("/medical-review/form/medical-history-medication/create", "POST")
// @Api(Description="Form - MedicalHistoryMedication")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.MedicalHistoryMedicationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.MedicalHistoryMedicationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.MedicalHistoryMedicationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.MedicalHistoryMedicationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.MedicalHistoryMedicationSingleResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryMedicationPostCreate implements IReturn<MedicalHistoryMedicationSingleResponse>
{
    /**
    * The metadata to use when creating the medical history medication.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the medical history medication.
    */
    // @DataMember(Order=2)
    public data: MedicalHistoryMedication;

    public constructor(init?: Partial<MedicalHistoryMedicationPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalHistoryMedicationPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MedicalHistoryMedicationSingleResponse(); }
}

/**
* Form - MedicalHistoryMedication
*/
// @Route("/medical-review/form/medical-history-medication/save", "POST")
// @Api(Description="Form - MedicalHistoryMedication")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.MedicalHistoryMedicationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.MedicalHistoryMedicationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.MedicalHistoryMedicationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.MedicalHistoryMedicationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.MedicalHistoryMedicationSingleResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryMedicationPostSave implements IReturn<MedicalHistoryMedicationSingleResponse>
{
    /**
    * The metadata to use when creating the medical history medication.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The medical history medication to save.
    */
    // @DataMember(Order=2)
    public data: MedicalHistoryMedication;

    public constructor(init?: Partial<MedicalHistoryMedicationPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalHistoryMedicationPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new MedicalHistoryMedicationSingleResponse(); }
}

/**
* Form - MedicalHistoryMedication
*/
// @Route("/medical-review/form/medical-history-medication/validate", "POST")
// @Api(Description="Form - MedicalHistoryMedication")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.MedicalHistoryMedicationValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.MedicalHistoryMedicationValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.MedicalHistoryMedicationValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.MedicalHistoryMedicationValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.MedicalHistoryMedicationValidationResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryMedicationPostValidate implements IReturn<MedicalHistoryMedicationValidationResponse>
{
    /**
    * The metadata to use when validating the medical history medication.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The medical history medication to validate.
    */
    // @DataMember(Order=2)
    public data: MedicalHistoryMedication;

    public constructor(init?: Partial<MedicalHistoryMedicationPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalHistoryMedicationPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new MedicalHistoryMedicationValidationResponse(); }
}

/**
* Form - MedicalHistoryMedication
*/
// @Route("/medical-review/form/medical-history-medication/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - MedicalHistoryMedication")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.MedicalHistoryMedicationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.MedicalHistoryMedicationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.MedicalHistoryMedicationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.MedicalHistoryMedicationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.MedicalHistoryMedicationSingleResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryMedicationGetSingleByMedicalReviewIdAndRepeat implements IReturn<MedicalHistoryMedicationSingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    /**
    * The id of the medical review.
    */
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    /**
    * The repeat of the medical history medication to retrieve.
    */
    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<MedicalHistoryMedicationGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalHistoryMedicationGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MedicalHistoryMedicationSingleResponse(); }
}

/**
* Form - MedicalHistoryMedication
*/
// @Route("/medical-review/form/medical-history-medication/single/update", "POST")
// @Api(Description="Form - MedicalHistoryMedication")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.MedicalHistoryMedicationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.MedicalHistoryMedicationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.MedicalHistoryMedicationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.MedicalHistoryMedicationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.MedicalHistoryMedicationSingleResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryMedicationPostUpdateById implements IReturn<MedicalHistoryMedicationSingleResponse>, IFormPostUpdateById
{
    /**
    * The id of the medical review form.
    */
    // @DataMember(Order=1)
    public id?: number;

    /**
    * The additional metadata for the medical review.
    */
    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<MedicalHistoryMedicationPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalHistoryMedicationPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new MedicalHistoryMedicationSingleResponse(); }
}

/**
* Form - NivolumabTherapy
*/
// @Route("/medical-review/form/nivolumab-therapy/single/id/{Id}", "GET")
// @Api(Description="Form - NivolumabTherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.NivolumabTherapySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.NivolumabTherapySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.NivolumabTherapySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.NivolumabTherapySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.NivolumabTherapySingleResponse)", StatusCode=500)
// @DataContract
export class NivolumabTherapyGetSingleById implements IReturn<NivolumabTherapySingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the nivolumab therapy to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<NivolumabTherapyGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'NivolumabTherapyGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new NivolumabTherapySingleResponse(); }
}

/**
* Form - NivolumabTherapy
*/
// @Route("/medical-review/form/nivolumab-therapy/collection", "GET")
// @Api(Description="Form - NivolumabTherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.NivolumabTherapyCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.NivolumabTherapyCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.NivolumabTherapyCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.NivolumabTherapyCollectionResponse)", StatusCode=500)
// @DataContract
export class NivolumabTherapyGetCollection implements IReturn<NivolumabTherapyCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<NivolumabTherapyGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'NivolumabTherapyGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new NivolumabTherapyCollectionResponse(); }
}

/**
* Form - NivolumabTherapy
*/
// @Route("/medical-review/form/nivolumab-therapy/create", "POST")
// @Api(Description="Form - NivolumabTherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.NivolumabTherapySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.NivolumabTherapySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.NivolumabTherapySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.NivolumabTherapySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.NivolumabTherapySingleResponse)", StatusCode=500)
// @DataContract
export class NivolumabTherapyPostCreate implements IReturn<NivolumabTherapySingleResponse>
{
    /**
    * The metadata to use when creating the nivolumab therapy.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the nivolumab therapy.
    */
    // @DataMember(Order=2)
    public data: NivolumabTherapy;

    public constructor(init?: Partial<NivolumabTherapyPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'NivolumabTherapyPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new NivolumabTherapySingleResponse(); }
}

/**
* Form - NivolumabTherapy
*/
// @Route("/medical-review/form/nivolumab-therapy/save", "POST")
// @Api(Description="Form - NivolumabTherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.NivolumabTherapySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.NivolumabTherapySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.NivolumabTherapySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.NivolumabTherapySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.NivolumabTherapySingleResponse)", StatusCode=500)
// @DataContract
export class NivolumabTherapyPostSave implements IReturn<NivolumabTherapySingleResponse>
{
    /**
    * The metadata to use when creating the nivolumab therapy.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The nivolumab therapy to save.
    */
    // @DataMember(Order=2)
    public data: NivolumabTherapy;

    public constructor(init?: Partial<NivolumabTherapyPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'NivolumabTherapyPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new NivolumabTherapySingleResponse(); }
}

/**
* Form - NivolumabTherapy
*/
// @Route("/medical-review/form/nivolumab-therapy/validate", "POST")
// @Api(Description="Form - NivolumabTherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.NivolumabTherapyValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.NivolumabTherapyValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.NivolumabTherapyValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.NivolumabTherapyValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.NivolumabTherapyValidationResponse)", StatusCode=500)
// @DataContract
export class NivolumabTherapyPostValidate implements IReturn<NivolumabTherapyValidationResponse>
{
    /**
    * The metadata to use when validating the nivolumab therapy.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The nivolumab therapy to validate.
    */
    // @DataMember(Order=2)
    public data: NivolumabTherapy;

    public constructor(init?: Partial<NivolumabTherapyPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'NivolumabTherapyPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new NivolumabTherapyValidationResponse(); }
}

/**
* Form - NivolumabTherapy
*/
// @Route("/medical-review/form/nivolumab-therapy/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - NivolumabTherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.NivolumabTherapySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.NivolumabTherapySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.NivolumabTherapySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.NivolumabTherapySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.NivolumabTherapySingleResponse)", StatusCode=500)
// @DataContract
export class NivolumabTherapyGetSingleByMedicalReviewIdAndRepeat implements IReturn<NivolumabTherapySingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    /**
    * The id of the medical review.
    */
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    /**
    * The repeat of the nivolumab therapy to retrieve.
    */
    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<NivolumabTherapyGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'NivolumabTherapyGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new NivolumabTherapySingleResponse(); }
}

/**
* Form - NivolumabTherapy
*/
// @Route("/medical-review/form/nivolumab-therapy/single/update", "POST")
// @Api(Description="Form - NivolumabTherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.NivolumabTherapySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.NivolumabTherapySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.NivolumabTherapySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.NivolumabTherapySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.NivolumabTherapySingleResponse)", StatusCode=500)
// @DataContract
export class NivolumabTherapyPostUpdateById implements IReturn<NivolumabTherapySingleResponse>, IFormPostUpdateById
{
    /**
    * The id of the medical review form.
    */
    // @DataMember(Order=1)
    public id?: number;

    /**
    * The additional metadata for the medical review.
    */
    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<NivolumabTherapyPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'NivolumabTherapyPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new NivolumabTherapySingleResponse(); }
}

/**
* Form - PaclitaxelTherapy
*/
// @Route("/medical-review/form/paclitaxel-therapy/single/id/{Id}", "GET")
// @Api(Description="Form - PaclitaxelTherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.PaclitaxelTherapySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.PaclitaxelTherapySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.PaclitaxelTherapySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.PaclitaxelTherapySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.PaclitaxelTherapySingleResponse)", StatusCode=500)
// @DataContract
export class PaclitaxelTherapyGetSingleById implements IReturn<PaclitaxelTherapySingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the paclitaxel therapy to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<PaclitaxelTherapyGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PaclitaxelTherapyGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PaclitaxelTherapySingleResponse(); }
}

/**
* Form - PaclitaxelTherapy
*/
// @Route("/medical-review/form/paclitaxel-therapy/collection", "GET")
// @Api(Description="Form - PaclitaxelTherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.PaclitaxelTherapyCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.PaclitaxelTherapyCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.PaclitaxelTherapyCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.PaclitaxelTherapyCollectionResponse)", StatusCode=500)
// @DataContract
export class PaclitaxelTherapyGetCollection implements IReturn<PaclitaxelTherapyCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<PaclitaxelTherapyGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PaclitaxelTherapyGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PaclitaxelTherapyCollectionResponse(); }
}

/**
* Form - PaclitaxelTherapy
*/
// @Route("/medical-review/form/paclitaxel-therapy/create", "POST")
// @Api(Description="Form - PaclitaxelTherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.PaclitaxelTherapySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.PaclitaxelTherapySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.PaclitaxelTherapySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.PaclitaxelTherapySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.PaclitaxelTherapySingleResponse)", StatusCode=500)
// @DataContract
export class PaclitaxelTherapyPostCreate implements IReturn<PaclitaxelTherapySingleResponse>
{
    /**
    * The metadata to use when creating the paclitaxel therapy.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the paclitaxel therapy.
    */
    // @DataMember(Order=2)
    public data: PaclitaxelTherapy;

    public constructor(init?: Partial<PaclitaxelTherapyPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PaclitaxelTherapyPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PaclitaxelTherapySingleResponse(); }
}

/**
* Form - PaclitaxelTherapy
*/
// @Route("/medical-review/form/paclitaxel-therapy/save", "POST")
// @Api(Description="Form - PaclitaxelTherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.PaclitaxelTherapySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.PaclitaxelTherapySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.PaclitaxelTherapySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.PaclitaxelTherapySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.PaclitaxelTherapySingleResponse)", StatusCode=500)
// @DataContract
export class PaclitaxelTherapyPostSave implements IReturn<PaclitaxelTherapySingleResponse>
{
    /**
    * The metadata to use when creating the paclitaxel therapy.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The paclitaxel therapy to save.
    */
    // @DataMember(Order=2)
    public data: PaclitaxelTherapy;

    public constructor(init?: Partial<PaclitaxelTherapyPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PaclitaxelTherapyPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PaclitaxelTherapySingleResponse(); }
}

/**
* Form - PaclitaxelTherapy
*/
// @Route("/medical-review/form/paclitaxel-therapy/validate", "POST")
// @Api(Description="Form - PaclitaxelTherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.PaclitaxelTherapyValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.PaclitaxelTherapyValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.PaclitaxelTherapyValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.PaclitaxelTherapyValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.PaclitaxelTherapyValidationResponse)", StatusCode=500)
// @DataContract
export class PaclitaxelTherapyPostValidate implements IReturn<PaclitaxelTherapyValidationResponse>
{
    /**
    * The metadata to use when validating the paclitaxel therapy.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The paclitaxel therapy to validate.
    */
    // @DataMember(Order=2)
    public data: PaclitaxelTherapy;

    public constructor(init?: Partial<PaclitaxelTherapyPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PaclitaxelTherapyPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PaclitaxelTherapyValidationResponse(); }
}

/**
* Form - PaclitaxelTherapy
*/
// @Route("/medical-review/form/paclitaxel-therapy/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - PaclitaxelTherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.PaclitaxelTherapySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.PaclitaxelTherapySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.PaclitaxelTherapySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.PaclitaxelTherapySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.PaclitaxelTherapySingleResponse)", StatusCode=500)
// @DataContract
export class PaclitaxelTherapyGetSingleByMedicalReviewIdAndRepeat implements IReturn<PaclitaxelTherapySingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    /**
    * The id of the medical review.
    */
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    /**
    * The repeat of the paclitaxel therapy to retrieve.
    */
    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<PaclitaxelTherapyGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PaclitaxelTherapyGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PaclitaxelTherapySingleResponse(); }
}

/**
* Form - PaclitaxelTherapy
*/
// @Route("/medical-review/form/paclitaxel-therapy/single/update", "POST")
// @Api(Description="Form - PaclitaxelTherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.PaclitaxelTherapySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.PaclitaxelTherapySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.PaclitaxelTherapySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.PaclitaxelTherapySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.PaclitaxelTherapySingleResponse)", StatusCode=500)
// @DataContract
export class PaclitaxelTherapyPostUpdateById implements IReturn<PaclitaxelTherapySingleResponse>, IFormPostUpdateById
{
    /**
    * The id of the medical review form.
    */
    // @DataMember(Order=1)
    public id?: number;

    /**
    * The additional metadata for the medical review.
    */
    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<PaclitaxelTherapyPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PaclitaxelTherapyPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PaclitaxelTherapySingleResponse(); }
}

/**
* Form - ImportantProtocolDeviation
*/
// @Route("/medical-review/form/important-protocol-deviation/single/id/{Id}", "GET")
// @Api(Description="Form - ImportantProtocolDeviation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ImportantProtocolDeviationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ImportantProtocolDeviationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ImportantProtocolDeviationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ImportantProtocolDeviationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ImportantProtocolDeviationSingleResponse)", StatusCode=500)
// @DataContract
export class ImportantProtocolDeviationGetSingleById implements IReturn<ImportantProtocolDeviationSingleResponse>, IDataGetSingleById {
    /**
    * The ID of the important protocol deviation to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<ImportantProtocolDeviationGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ImportantProtocolDeviationGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ImportantProtocolDeviationSingleResponse(); }
}

/**
* Form - ImportantProtocolDeviation
*/
// @Route("/medical-review/form/important-protocol-deviation/collection", "GET")
// @Api(Description="Form - ImportantProtocolDeviation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ImportantProtocolDeviationCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ImportantProtocolDeviationCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ImportantProtocolDeviationCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ImportantProtocolDeviationCollectionResponse)", StatusCode=500)
// @DataContract
export class ImportantProtocolDeviationGetCollection implements IReturn<ImportantProtocolDeviationCollectionResponse>, IDataGetCollection {
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<ImportantProtocolDeviationGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ImportantProtocolDeviationGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ImportantProtocolDeviationCollectionResponse(); }
}

/**
* Form - ImportantProtocolDeviation
*/
// @Route("/medical-review/form/important-protocol-deviation/create", "POST")
// @Api(Description="Form - ImportantProtocolDeviation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ImportantProtocolDeviationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ImportantProtocolDeviationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ImportantProtocolDeviationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ImportantProtocolDeviationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ImportantProtocolDeviationSingleResponse)", StatusCode=500)
// @DataContract
export class ImportantProtocolDeviationPostCreate implements IReturn<ImportantProtocolDeviationSingleResponse>
{
    /**
    * The metadata to use when creating the important protocol deviation.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the important protocol deviation.
    */
    // @DataMember(Order=2)
    public data: ImportantProtocolDeviation;

    public constructor(init?: Partial<ImportantProtocolDeviationPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ImportantProtocolDeviationPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ImportantProtocolDeviationSingleResponse(); }
}

/**
* Form - ImportantProtocolDeviation
*/
// @Route("/medical-review/form/important-protocol-deviation/save", "POST")
// @Api(Description="Form - ImportantProtocolDeviation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ImportantProtocolDeviationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ImportantProtocolDeviationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ImportantProtocolDeviationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ImportantProtocolDeviationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ImportantProtocolDeviationSingleResponse)", StatusCode=500)
// @DataContract
export class ImportantProtocolDeviationPostSave implements IReturn<ImportantProtocolDeviationSingleResponse>
{
    /**
    * The metadata to use when creating the important protocol deviation.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The important protocol deviation to save.
    */
    // @DataMember(Order=2)
    public data: ImportantProtocolDeviation;

    public constructor(init?: Partial<ImportantProtocolDeviationPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ImportantProtocolDeviationPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ImportantProtocolDeviationSingleResponse(); }
}

/**
* Form - ImportantProtocolDeviation
*/
// @Route("/medical-review/form/important-protocol-deviation/validate", "POST")
// @Api(Description="Form - ImportantProtocolDeviation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ImportantProtocolDeviationValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ImportantProtocolDeviationValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ImportantProtocolDeviationValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ImportantProtocolDeviationValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ImportantProtocolDeviationValidationResponse)", StatusCode=500)
// @DataContract
export class ImportantProtocolDeviationPostValidate implements IReturn<ImportantProtocolDeviationValidationResponse>
{
    /**
    * The metadata to use when validating the important protocol deviation.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The important protocol deviation to validate.
    */
    // @DataMember(Order=2)
    public data: ImportantProtocolDeviation;

    public constructor(init?: Partial<ImportantProtocolDeviationPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ImportantProtocolDeviationPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ImportantProtocolDeviationValidationResponse(); }
}

/**
* Form - ImportantProtocolDeviation
*/
// @Route("/medical-review/form/important-protocol-deviation/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - ImportantProtocolDeviation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ImportantProtocolDeviationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ImportantProtocolDeviationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ImportantProtocolDeviationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ImportantProtocolDeviationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ImportantProtocolDeviationSingleResponse)", StatusCode=500)
// @DataContract
export class ImportantProtocolDeviationGetSingleByMedicalReviewIdAndRepeat implements IReturn<ImportantProtocolDeviationSingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat {
    /**
    * The id of the medical review.
    */
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    /**
    * The repeat of the important protocol deviation to retrieve.
    */
    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<ImportantProtocolDeviationGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ImportantProtocolDeviationGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ImportantProtocolDeviationSingleResponse(); }
}

/**
* Form - ImportantProtocolDeviation
*/
// @Route("/medical-review/form/important-protocol-deviation/single/update", "POST")
// @Api(Description="Form - ImportantProtocolDeviation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ImportantProtocolDeviationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ImportantProtocolDeviationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ImportantProtocolDeviationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ImportantProtocolDeviationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ImportantProtocolDeviationSingleResponse)", StatusCode=500)
// @DataContract
export class ImportantProtocolDeviationPostUpdateById implements IReturn<ImportantProtocolDeviationSingleResponse>, IFormPostUpdateById {
    /**
    * The id of the medical review form.
    */
    // @DataMember(Order=1)
    public id?: number;

    /**
    * The additional metadata for the medical review.
    */
    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<ImportantProtocolDeviationPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ImportantProtocolDeviationPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ImportantProtocolDeviationSingleResponse(); }
}

/**
* Form - ProtocolDeviation
*/
// @Route("/medical-review/form/protocol-deviation/single/id/{Id}", "GET")
// @Api(Description="Form - ProtocolDeviation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ProtocolDeviationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ProtocolDeviationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ProtocolDeviationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ProtocolDeviationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ProtocolDeviationSingleResponse)", StatusCode=500)
// @DataContract
export class ProtocolDeviationGetSingleById implements IReturn<ProtocolDeviationSingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the protocol deviation to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<ProtocolDeviationGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProtocolDeviationGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ProtocolDeviationSingleResponse(); }
}

/**
* Form - ProtocolDeviation
*/
// @Route("/medical-review/form/protocol-deviation/collection", "GET")
// @Api(Description="Form - ProtocolDeviation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ProtocolDeviationCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ProtocolDeviationCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ProtocolDeviationCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ProtocolDeviationCollectionResponse)", StatusCode=500)
// @DataContract
export class ProtocolDeviationGetCollection implements IReturn<ProtocolDeviationCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<ProtocolDeviationGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProtocolDeviationGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ProtocolDeviationCollectionResponse(); }
}

/**
* Form - ProtocolDeviation
*/
// @Route("/medical-review/form/protocol-deviation/create", "POST")
// @Api(Description="Form - ProtocolDeviation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ProtocolDeviationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ProtocolDeviationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ProtocolDeviationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ProtocolDeviationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ProtocolDeviationSingleResponse)", StatusCode=500)
// @DataContract
export class ProtocolDeviationPostCreate implements IReturn<ProtocolDeviationSingleResponse>
{
    /**
    * The metadata to use when creating the protocol deviation.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the protocol deviation.
    */
    // @DataMember(Order=2)
    public data: ProtocolDeviation;

    public constructor(init?: Partial<ProtocolDeviationPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProtocolDeviationPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ProtocolDeviationSingleResponse(); }
}

/**
* Form - ProtocolDeviation
*/
// @Route("/medical-review/form/protocol-deviation/save", "POST")
// @Api(Description="Form - ProtocolDeviation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ProtocolDeviationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ProtocolDeviationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ProtocolDeviationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ProtocolDeviationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ProtocolDeviationSingleResponse)", StatusCode=500)
// @DataContract
export class ProtocolDeviationPostSave implements IReturn<ProtocolDeviationSingleResponse>
{
    /**
    * The metadata to use when creating the protocol deviation.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The protocol deviation to save.
    */
    // @DataMember(Order=2)
    public data: ProtocolDeviation;

    public constructor(init?: Partial<ProtocolDeviationPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProtocolDeviationPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ProtocolDeviationSingleResponse(); }
}

/**
* Form - ProtocolDeviation
*/
// @Route("/medical-review/form/protocol-deviation/validate", "POST")
// @Api(Description="Form - ProtocolDeviation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ProtocolDeviationValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ProtocolDeviationValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ProtocolDeviationValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ProtocolDeviationValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ProtocolDeviationValidationResponse)", StatusCode=500)
// @DataContract
export class ProtocolDeviationPostValidate implements IReturn<ProtocolDeviationValidationResponse>
{
    /**
    * The metadata to use when validating the protocol deviation.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The protocol deviation to validate.
    */
    // @DataMember(Order=2)
    public data: ProtocolDeviation;

    public constructor(init?: Partial<ProtocolDeviationPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProtocolDeviationPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ProtocolDeviationValidationResponse(); }
}

/**
* Form - ProtocolDeviation
*/
// @Route("/medical-review/form/protocol-deviation/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - ProtocolDeviation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ProtocolDeviationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ProtocolDeviationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ProtocolDeviationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ProtocolDeviationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ProtocolDeviationSingleResponse)", StatusCode=500)
// @DataContract
export class ProtocolDeviationGetSingleByMedicalReviewIdAndRepeat implements IReturn<ProtocolDeviationSingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    /**
    * The id of the medical review.
    */
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    /**
    * The repeat of the protocol deviation to retrieve.
    */
    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<ProtocolDeviationGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProtocolDeviationGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ProtocolDeviationSingleResponse(); }
}

/**
* Form - ProtocolDeviation
*/
// @Route("/medical-review/form/protocol-deviation/single/update", "POST")
// @Api(Description="Form - ProtocolDeviation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ProtocolDeviationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ProtocolDeviationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ProtocolDeviationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ProtocolDeviationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ProtocolDeviationSingleResponse)", StatusCode=500)
// @DataContract
export class ProtocolDeviationPostUpdateById implements IReturn<ProtocolDeviationSingleResponse>, IFormPostUpdateById
{
    /**
    * The id of the medical review form.
    */
    // @DataMember(Order=1)
    public id?: number;

    /**
    * The additional metadata for the medical review.
    */
    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<ProtocolDeviationPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProtocolDeviationPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ProtocolDeviationSingleResponse(); }
}

/**
* Form - SecondPrimaryMalignancyItem
*/
// @Route("/medical-review/form/second-primary-malignancy-item/single/id/{Id}", "GET")
// @Api(Description="Form - SecondPrimaryMalignancyItem")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SecondPrimaryMalignancyItemSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SecondPrimaryMalignancyItemSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SecondPrimaryMalignancyItemSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SecondPrimaryMalignancyItemSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SecondPrimaryMalignancyItemSingleResponse)", StatusCode=500)
// @DataContract
export class SecondPrimaryMalignancyItemGetSingleById implements IReturn<SecondPrimaryMalignancyItemSingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the second primary malignancy item to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<SecondPrimaryMalignancyItemGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SecondPrimaryMalignancyItemGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SecondPrimaryMalignancyItemSingleResponse(); }
}

/**
* Form - SecondPrimaryMalignancyItem
*/
// @Route("/medical-review/form/second-primary-malignancy-item/collection", "GET")
// @Api(Description="Form - SecondPrimaryMalignancyItem")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SecondPrimaryMalignancyItemCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SecondPrimaryMalignancyItemCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SecondPrimaryMalignancyItemCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SecondPrimaryMalignancyItemCollectionResponse)", StatusCode=500)
// @DataContract
export class SecondPrimaryMalignancyItemGetCollection implements IReturn<SecondPrimaryMalignancyItemCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<SecondPrimaryMalignancyItemGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SecondPrimaryMalignancyItemGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SecondPrimaryMalignancyItemCollectionResponse(); }
}

/**
* Form - SecondPrimaryMalignancyItem
*/
// @Route("/medical-review/form/second-primary-malignancy-item/create", "POST")
// @Api(Description="Form - SecondPrimaryMalignancyItem")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SecondPrimaryMalignancyItemSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SecondPrimaryMalignancyItemSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SecondPrimaryMalignancyItemSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SecondPrimaryMalignancyItemSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SecondPrimaryMalignancyItemSingleResponse)", StatusCode=500)
// @DataContract
export class SecondPrimaryMalignancyItemPostCreate implements IReturn<SecondPrimaryMalignancyItemSingleResponse>
{
    /**
    * The metadata to use when creating the second primary malignancy item.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the second primary malignancy item.
    */
    // @DataMember(Order=2)
    public data: SecondPrimaryMalignancyItem;

    public constructor(init?: Partial<SecondPrimaryMalignancyItemPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SecondPrimaryMalignancyItemPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SecondPrimaryMalignancyItemSingleResponse(); }
}

/**
* Form - SecondPrimaryMalignancyItem
*/
// @Route("/medical-review/form/second-primary-malignancy-item/save", "POST")
// @Api(Description="Form - SecondPrimaryMalignancyItem")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SecondPrimaryMalignancyItemSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SecondPrimaryMalignancyItemSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SecondPrimaryMalignancyItemSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SecondPrimaryMalignancyItemSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SecondPrimaryMalignancyItemSingleResponse)", StatusCode=500)
// @DataContract
export class SecondPrimaryMalignancyItemPostSave implements IReturn<SecondPrimaryMalignancyItemSingleResponse>
{
    /**
    * The metadata to use when creating the second primary malignancy item.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The second primary malignancy item to save.
    */
    // @DataMember(Order=2)
    public data: SecondPrimaryMalignancyItem;

    public constructor(init?: Partial<SecondPrimaryMalignancyItemPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SecondPrimaryMalignancyItemPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SecondPrimaryMalignancyItemSingleResponse(); }
}

/**
* Form - SecondPrimaryMalignancyItem
*/
// @Route("/medical-review/form/second-primary-malignancy-item/validate", "POST")
// @Api(Description="Form - SecondPrimaryMalignancyItem")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SecondPrimaryMalignancyItemValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SecondPrimaryMalignancyItemValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SecondPrimaryMalignancyItemValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SecondPrimaryMalignancyItemValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SecondPrimaryMalignancyItemValidationResponse)", StatusCode=500)
// @DataContract
export class SecondPrimaryMalignancyItemPostValidate implements IReturn<SecondPrimaryMalignancyItemValidationResponse>
{
    /**
    * The metadata to use when validating the second primary malignancy item.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The second primary malignancy item to validate.
    */
    // @DataMember(Order=2)
    public data: SecondPrimaryMalignancyItem;

    public constructor(init?: Partial<SecondPrimaryMalignancyItemPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SecondPrimaryMalignancyItemPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SecondPrimaryMalignancyItemValidationResponse(); }
}

/**
* Form - SecondPrimaryMalignancyItem
*/
// @Route("/medical-review/form/second-primary-malignancy-item/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - SecondPrimaryMalignancyItem")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SecondPrimaryMalignancyItemSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SecondPrimaryMalignancyItemSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SecondPrimaryMalignancyItemSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SecondPrimaryMalignancyItemSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SecondPrimaryMalignancyItemSingleResponse)", StatusCode=500)
// @DataContract
export class SecondPrimaryMalignancyItemGetSingleByMedicalReviewIdAndRepeat implements IReturn<SecondPrimaryMalignancyItemSingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    /**
    * The id of the medical review.
    */
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    /**
    * The repeat of the second primary malignancy item to retrieve.
    */
    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<SecondPrimaryMalignancyItemGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SecondPrimaryMalignancyItemGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SecondPrimaryMalignancyItemSingleResponse(); }
}

/**
* Form - SecondPrimaryMalignancyItem
*/
// @Route("/medical-review/form/second-primary-malignancy-item/single/update", "POST")
// @Api(Description="Form - SecondPrimaryMalignancyItem")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SecondPrimaryMalignancyItemSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SecondPrimaryMalignancyItemSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SecondPrimaryMalignancyItemSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SecondPrimaryMalignancyItemSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SecondPrimaryMalignancyItemSingleResponse)", StatusCode=500)
// @DataContract
export class SecondPrimaryMalignancyItemPostUpdateById implements IReturn<SecondPrimaryMalignancyItemSingleResponse>, IFormPostUpdateById
{
    /**
    * The id of the medical review form.
    */
    // @DataMember(Order=1)
    public id?: number;

    /**
    * The additional metadata for the medical review.
    */
    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<SecondPrimaryMalignancyItemPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SecondPrimaryMalignancyItemPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SecondPrimaryMalignancyItemSingleResponse(); }
}

/**
* Form - SeriousAdverseEventItem
*/
// @Route("/medical-review/form/serious-adverse-event-item/single/id/{Id}", "GET")
// @Api(Description="Form - SeriousAdverseEventItem")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventItemSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventItemSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventItemSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventItemSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventItemSingleResponse)", StatusCode=500)
// @DataContract
export class SeriousAdverseEventItemGetSingleById implements IReturn<SeriousAdverseEventItemSingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the serious adverse event item to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<SeriousAdverseEventItemGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SeriousAdverseEventItemGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SeriousAdverseEventItemSingleResponse(); }
}

/**
* Form - SeriousAdverseEventItem
*/
// @Route("/medical-review/form/serious-adverse-event-item/collection", "GET")
// @Api(Description="Form - SeriousAdverseEventItem")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventItemCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventItemCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventItemCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventItemCollectionResponse)", StatusCode=500)
// @DataContract
export class SeriousAdverseEventItemGetCollection implements IReturn<SeriousAdverseEventItemCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<SeriousAdverseEventItemGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SeriousAdverseEventItemGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SeriousAdverseEventItemCollectionResponse(); }
}

/**
* Form - SeriousAdverseEventItem
*/
// @Route("/medical-review/form/serious-adverse-event-item/create", "POST")
// @Api(Description="Form - SeriousAdverseEventItem")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventItemSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventItemSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventItemSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventItemSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventItemSingleResponse)", StatusCode=500)
// @DataContract
export class SeriousAdverseEventItemPostCreate implements IReturn<SeriousAdverseEventItemSingleResponse>
{
    /**
    * The metadata to use when creating the serious adverse event item.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the serious adverse event item.
    */
    // @DataMember(Order=2)
    public data: SeriousAdverseEventItem;

    public constructor(init?: Partial<SeriousAdverseEventItemPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SeriousAdverseEventItemPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SeriousAdverseEventItemSingleResponse(); }
}

/**
* Form - SeriousAdverseEventItem
*/
// @Route("/medical-review/form/serious-adverse-event-item/save", "POST")
// @Api(Description="Form - SeriousAdverseEventItem")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventItemSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventItemSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventItemSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventItemSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventItemSingleResponse)", StatusCode=500)
// @DataContract
export class SeriousAdverseEventItemPostSave implements IReturn<SeriousAdverseEventItemSingleResponse>
{
    /**
    * The metadata to use when creating the serious adverse event item.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The serious adverse event item to save.
    */
    // @DataMember(Order=2)
    public data: SeriousAdverseEventItem;

    public constructor(init?: Partial<SeriousAdverseEventItemPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SeriousAdverseEventItemPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SeriousAdverseEventItemSingleResponse(); }
}

/**
* Form - SeriousAdverseEventItem
*/
// @Route("/medical-review/form/serious-adverse-event-item/validate", "POST")
// @Api(Description="Form - SeriousAdverseEventItem")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventItemValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventItemValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventItemValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventItemValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventItemValidationResponse)", StatusCode=500)
// @DataContract
export class SeriousAdverseEventItemPostValidate implements IReturn<SeriousAdverseEventItemValidationResponse>
{
    /**
    * The metadata to use when validating the serious adverse event item.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The serious adverse event item to validate.
    */
    // @DataMember(Order=2)
    public data: SeriousAdverseEventItem;

    public constructor(init?: Partial<SeriousAdverseEventItemPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SeriousAdverseEventItemPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SeriousAdverseEventItemValidationResponse(); }
}

/**
* Form - SeriousAdverseEventItem
*/
// @Route("/medical-review/form/serious-adverse-event-item/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - SeriousAdverseEventItem")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventItemSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventItemSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventItemSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventItemSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventItemSingleResponse)", StatusCode=500)
// @DataContract
export class SeriousAdverseEventItemGetSingleByMedicalReviewIdAndRepeat implements IReturn<SeriousAdverseEventItemSingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    /**
    * The id of the medical review.
    */
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    /**
    * The repeat of the serious adverse event item to retrieve.
    */
    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<SeriousAdverseEventItemGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SeriousAdverseEventItemGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SeriousAdverseEventItemSingleResponse(); }
}

/**
* Form - SeriousAdverseEventItem
*/
// @Route("/medical-review/form/serious-adverse-event-item/single/update", "POST")
// @Api(Description="Form - SeriousAdverseEventItem")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventItemSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventItemSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventItemSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventItemSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventItemSingleResponse)", StatusCode=500)
// @DataContract
export class SeriousAdverseEventItemPostUpdateById implements IReturn<SeriousAdverseEventItemSingleResponse>, IFormPostUpdateById
{
    /**
    * The id of the medical review form.
    */
    // @DataMember(Order=1)
    public id?: number;

    /**
    * The additional metadata for the medical review.
    */
    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<SeriousAdverseEventItemPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SeriousAdverseEventItemPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SeriousAdverseEventItemSingleResponse(); }
}

/**
* Form - SlnbResult
*/
// @Route("/medical-review/form/slnb-result/single/id/{Id}", "GET")
// @Api(Description="Form - SlnbResult")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SlnbResultSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SlnbResultSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SlnbResultSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SlnbResultSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SlnbResultSingleResponse)", StatusCode=500)
// @DataContract
export class SlnbResultGetSingleById implements IReturn<SlnbResultSingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the slnb result to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<SlnbResultGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SlnbResultGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SlnbResultSingleResponse(); }
}

/**
* Form - SlnbResult
*/
// @Route("/medical-review/form/slnb-result/collection", "GET")
// @Api(Description="Form - SlnbResult")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SlnbResultCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SlnbResultCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SlnbResultCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SlnbResultCollectionResponse)", StatusCode=500)
// @DataContract
export class SlnbResultGetCollection implements IReturn<SlnbResultCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<SlnbResultGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SlnbResultGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SlnbResultCollectionResponse(); }
}

/**
* Form - SlnbResult
*/
// @Route("/medical-review/form/slnb-result/create", "POST")
// @Api(Description="Form - SlnbResult")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SlnbResultSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SlnbResultSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SlnbResultSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SlnbResultSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SlnbResultSingleResponse)", StatusCode=500)
// @DataContract
export class SlnbResultPostCreate implements IReturn<SlnbResultSingleResponse>
{
    /**
    * The metadata to use when creating the slnb result.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the slnb result.
    */
    // @DataMember(Order=2)
    public data: SlnbResult;

    public constructor(init?: Partial<SlnbResultPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SlnbResultPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SlnbResultSingleResponse(); }
}

/**
* Form - SlnbResult
*/
// @Route("/medical-review/form/slnb-result/save", "POST")
// @Api(Description="Form - SlnbResult")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SlnbResultSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SlnbResultSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SlnbResultSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SlnbResultSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SlnbResultSingleResponse)", StatusCode=500)
// @DataContract
export class SlnbResultPostSave implements IReturn<SlnbResultSingleResponse>
{
    /**
    * The metadata to use when creating the slnb result.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The slnb result to save.
    */
    // @DataMember(Order=2)
    public data: SlnbResult;

    public constructor(init?: Partial<SlnbResultPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SlnbResultPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SlnbResultSingleResponse(); }
}

/**
* Form - SlnbResult
*/
// @Route("/medical-review/form/slnb-result/validate", "POST")
// @Api(Description="Form - SlnbResult")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SlnbResultValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SlnbResultValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SlnbResultValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SlnbResultValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SlnbResultValidationResponse)", StatusCode=500)
// @DataContract
export class SlnbResultPostValidate implements IReturn<SlnbResultValidationResponse>
{
    /**
    * The metadata to use when validating the slnb result.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The slnb result to validate.
    */
    // @DataMember(Order=2)
    public data: SlnbResult;

    public constructor(init?: Partial<SlnbResultPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SlnbResultPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SlnbResultValidationResponse(); }
}

/**
* Form - SlnbResult
*/
// @Route("/medical-review/form/slnb-result/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - SlnbResult")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SlnbResultSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SlnbResultSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SlnbResultSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SlnbResultSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SlnbResultSingleResponse)", StatusCode=500)
// @DataContract
export class SlnbResultGetSingleByMedicalReviewIdAndRepeat implements IReturn<SlnbResultSingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    /**
    * The id of the medical review.
    */
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    /**
    * The repeat of the slnb result to retrieve.
    */
    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<SlnbResultGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SlnbResultGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SlnbResultSingleResponse(); }
}

/**
* Form - SlnbResult
*/
// @Route("/medical-review/form/slnb-result/single/update", "POST")
// @Api(Description="Form - SlnbResult")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SlnbResultSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SlnbResultSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SlnbResultSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SlnbResultSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SlnbResultSingleResponse)", StatusCode=500)
// @DataContract
export class SlnbResultPostUpdateById implements IReturn<SlnbResultSingleResponse>, IFormPostUpdateById
{
    /**
    * The id of the medical review form.
    */
    // @DataMember(Order=1)
    public id?: number;

    /**
    * The additional metadata for the medical review.
    */
    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<SlnbResultPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SlnbResultPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SlnbResultSingleResponse(); }
}

/**
* Form - SourceDocument
*/
// @Route("/medical-review/form/source-document/single/id/{Id}", "GET")
// @Api(Description="Form - SourceDocument")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SourceDocumentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SourceDocumentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SourceDocumentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SourceDocumentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SourceDocumentSingleResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentGetSingleById implements IReturn<SourceDocumentSingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the source document to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<SourceDocumentGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SourceDocumentSingleResponse(); }
}

/**
* Form - SourceDocument
*/
// @Route("/medical-review/form/source-document/collection", "GET")
// @Api(Description="Form - SourceDocument")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SourceDocumentCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SourceDocumentCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SourceDocumentCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SourceDocumentCollectionResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentGetCollection implements IReturn<SourceDocumentCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<SourceDocumentGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SourceDocumentCollectionResponse(); }
}

/**
* Form - SourceDocument
*/
// @Route("/medical-review/form/source-document/create", "POST")
// @Api(Description="Form - SourceDocument")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SourceDocumentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SourceDocumentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SourceDocumentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SourceDocumentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SourceDocumentSingleResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentPostCreate implements IReturn<SourceDocumentSingleResponse>
{
    /**
    * The metadata to use when creating the source document.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the source document.
    */
    // @DataMember(Order=2)
    public data: SourceDocument;

    public constructor(init?: Partial<SourceDocumentPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SourceDocumentSingleResponse(); }
}

/**
* Form - SourceDocument
*/
// @Route("/medical-review/form/source-document/save", "POST")
// @Api(Description="Form - SourceDocument")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SourceDocumentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SourceDocumentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SourceDocumentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SourceDocumentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SourceDocumentSingleResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentPostSave implements IReturn<SourceDocumentSingleResponse>
{
    /**
    * The metadata to use when creating the source document.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The source document to save.
    */
    // @DataMember(Order=2)
    public data: SourceDocument;

    public constructor(init?: Partial<SourceDocumentPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SourceDocumentSingleResponse(); }
}

/**
* Form - SourceDocument
*/
// @Route("/medical-review/form/source-document/validate", "POST")
// @Api(Description="Form - SourceDocument")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SourceDocumentValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SourceDocumentValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SourceDocumentValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SourceDocumentValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SourceDocumentValidationResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentPostValidate implements IReturn<SourceDocumentValidationResponse>
{
    /**
    * The metadata to use when validating the source document.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The source document to validate.
    */
    // @DataMember(Order=2)
    public data: SourceDocument;

    public constructor(init?: Partial<SourceDocumentPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SourceDocumentValidationResponse(); }
}

/**
* Form - SourceDocument
*/
// @Route("/medical-review/form/source-document/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - SourceDocument")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SourceDocumentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SourceDocumentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SourceDocumentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SourceDocumentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SourceDocumentSingleResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentGetSingleByMedicalReviewIdAndRepeat implements IReturn<SourceDocumentSingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    /**
    * The id of the medical review.
    */
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    /**
    * The repeat of the source document to retrieve.
    */
    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<SourceDocumentGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SourceDocumentSingleResponse(); }
}

/**
* Form - SourceDocument
*/
// @Route("/medical-review/form/source-document/single/update", "POST")
// @Api(Description="Form - SourceDocument")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SourceDocumentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SourceDocumentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SourceDocumentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SourceDocumentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SourceDocumentSingleResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentPostUpdateById implements IReturn<SourceDocumentSingleResponse>, IFormPostUpdateById
{
    /**
    * The id of the medical review form.
    */
    // @DataMember(Order=1)
    public id?: number;

    /**
    * The additional metadata for the medical review.
    */
    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<SourceDocumentPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SourceDocumentSingleResponse(); }
}

/**
* Form - SurvivalFollowUpItem
*/
// @Route("/medical-review/form/survival-follow-up-item/single/id/{Id}", "GET")
// @Api(Description="Form - SurvivalFollowUpItem")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SurvivalFollowUpItemSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SurvivalFollowUpItemSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SurvivalFollowUpItemSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SurvivalFollowUpItemSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SurvivalFollowUpItemSingleResponse)", StatusCode=500)
// @DataContract
export class SurvivalFollowUpItemGetSingleById implements IReturn<SurvivalFollowUpItemSingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the survival follow up item to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<SurvivalFollowUpItemGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SurvivalFollowUpItemGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SurvivalFollowUpItemSingleResponse(); }
}

/**
* Form - SurvivalFollowUpItem
*/
// @Route("/medical-review/form/survival-follow-up-item/collection", "GET")
// @Api(Description="Form - SurvivalFollowUpItem")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SurvivalFollowUpItemCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SurvivalFollowUpItemCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SurvivalFollowUpItemCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SurvivalFollowUpItemCollectionResponse)", StatusCode=500)
// @DataContract
export class SurvivalFollowUpItemGetCollection implements IReturn<SurvivalFollowUpItemCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<SurvivalFollowUpItemGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SurvivalFollowUpItemGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SurvivalFollowUpItemCollectionResponse(); }
}

/**
* Form - SurvivalFollowUpItem
*/
// @Route("/medical-review/form/survival-follow-up-item/create", "POST")
// @Api(Description="Form - SurvivalFollowUpItem")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SurvivalFollowUpItemSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SurvivalFollowUpItemSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SurvivalFollowUpItemSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SurvivalFollowUpItemSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SurvivalFollowUpItemSingleResponse)", StatusCode=500)
// @DataContract
export class SurvivalFollowUpItemPostCreate implements IReturn<SurvivalFollowUpItemSingleResponse>
{
    /**
    * The metadata to use when creating the survival follow up item.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the survival follow up item.
    */
    // @DataMember(Order=2)
    public data: SurvivalFollowUpItem;

    public constructor(init?: Partial<SurvivalFollowUpItemPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SurvivalFollowUpItemPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SurvivalFollowUpItemSingleResponse(); }
}

/**
* Form - SurvivalFollowUpItem
*/
// @Route("/medical-review/form/survival-follow-up-item/save", "POST")
// @Api(Description="Form - SurvivalFollowUpItem")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SurvivalFollowUpItemSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SurvivalFollowUpItemSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SurvivalFollowUpItemSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SurvivalFollowUpItemSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SurvivalFollowUpItemSingleResponse)", StatusCode=500)
// @DataContract
export class SurvivalFollowUpItemPostSave implements IReturn<SurvivalFollowUpItemSingleResponse>
{
    /**
    * The metadata to use when creating the survival follow up item.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The survival follow up item to save.
    */
    // @DataMember(Order=2)
    public data: SurvivalFollowUpItem;

    public constructor(init?: Partial<SurvivalFollowUpItemPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SurvivalFollowUpItemPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SurvivalFollowUpItemSingleResponse(); }
}

/**
* Form - SurvivalFollowUpItem
*/
// @Route("/medical-review/form/survival-follow-up-item/validate", "POST")
// @Api(Description="Form - SurvivalFollowUpItem")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SurvivalFollowUpItemValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SurvivalFollowUpItemValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SurvivalFollowUpItemValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SurvivalFollowUpItemValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SurvivalFollowUpItemValidationResponse)", StatusCode=500)
// @DataContract
export class SurvivalFollowUpItemPostValidate implements IReturn<SurvivalFollowUpItemValidationResponse>
{
    /**
    * The metadata to use when validating the survival follow up item.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The survival follow up item to validate.
    */
    // @DataMember(Order=2)
    public data: SurvivalFollowUpItem;

    public constructor(init?: Partial<SurvivalFollowUpItemPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SurvivalFollowUpItemPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SurvivalFollowUpItemValidationResponse(); }
}

/**
* Form - SurvivalFollowUpItem
*/
// @Route("/medical-review/form/survival-follow-up-item/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - SurvivalFollowUpItem")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SurvivalFollowUpItemSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SurvivalFollowUpItemSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SurvivalFollowUpItemSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SurvivalFollowUpItemSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SurvivalFollowUpItemSingleResponse)", StatusCode=500)
// @DataContract
export class SurvivalFollowUpItemGetSingleByMedicalReviewIdAndRepeat implements IReturn<SurvivalFollowUpItemSingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    /**
    * The id of the medical review.
    */
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    /**
    * The repeat of the survival follow up item to retrieve.
    */
    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<SurvivalFollowUpItemGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SurvivalFollowUpItemGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SurvivalFollowUpItemSingleResponse(); }
}

/**
* Form - SurvivalFollowUpItem
*/
// @Route("/medical-review/form/survival-follow-up-item/single/update", "POST")
// @Api(Description="Form - SurvivalFollowUpItem")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SurvivalFollowUpItemSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SurvivalFollowUpItemSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SurvivalFollowUpItemSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SurvivalFollowUpItemSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SurvivalFollowUpItemSingleResponse)", StatusCode=500)
// @DataContract
export class SurvivalFollowUpItemPostUpdateById implements IReturn<SurvivalFollowUpItemSingleResponse>, IFormPostUpdateById
{
    /**
    * The id of the medical review form.
    */
    // @DataMember(Order=1)
    public id?: number;

    /**
    * The additional metadata for the medical review.
    */
    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<SurvivalFollowUpItemPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SurvivalFollowUpItemPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SurvivalFollowUpItemSingleResponse(); }
}

/**
* Form - ThyroidFunction
*/
// @Route("/medical-review/form/thyroid-function/single/id/{Id}", "GET")
// @Api(Description="Form - ThyroidFunction")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ThyroidFunctionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ThyroidFunctionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ThyroidFunctionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ThyroidFunctionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ThyroidFunctionSingleResponse)", StatusCode=500)
// @DataContract
export class ThyroidFunctionGetSingleById implements IReturn<ThyroidFunctionSingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the thyroid function to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<ThyroidFunctionGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ThyroidFunctionGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ThyroidFunctionSingleResponse(); }
}

/**
* Form - ThyroidFunction
*/
// @Route("/medical-review/form/thyroid-function/collection", "GET")
// @Api(Description="Form - ThyroidFunction")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ThyroidFunctionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ThyroidFunctionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ThyroidFunctionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ThyroidFunctionCollectionResponse)", StatusCode=500)
// @DataContract
export class ThyroidFunctionGetCollection implements IReturn<ThyroidFunctionCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<ThyroidFunctionGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ThyroidFunctionGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ThyroidFunctionCollectionResponse(); }
}

/**
* Form - ThyroidFunction
*/
// @Route("/medical-review/form/thyroid-function/create", "POST")
// @Api(Description="Form - ThyroidFunction")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ThyroidFunctionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ThyroidFunctionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ThyroidFunctionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ThyroidFunctionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ThyroidFunctionSingleResponse)", StatusCode=500)
// @DataContract
export class ThyroidFunctionPostCreate implements IReturn<ThyroidFunctionSingleResponse>
{
    /**
    * The metadata to use when creating the thyroid function.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the thyroid function.
    */
    // @DataMember(Order=2)
    public data: ThyroidFunction;

    public constructor(init?: Partial<ThyroidFunctionPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ThyroidFunctionPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ThyroidFunctionSingleResponse(); }
}

/**
* Form - ThyroidFunction
*/
// @Route("/medical-review/form/thyroid-function/save", "POST")
// @Api(Description="Form - ThyroidFunction")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ThyroidFunctionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ThyroidFunctionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ThyroidFunctionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ThyroidFunctionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ThyroidFunctionSingleResponse)", StatusCode=500)
// @DataContract
export class ThyroidFunctionPostSave implements IReturn<ThyroidFunctionSingleResponse>
{
    /**
    * The metadata to use when creating the thyroid function.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The thyroid function to save.
    */
    // @DataMember(Order=2)
    public data: ThyroidFunction;

    public constructor(init?: Partial<ThyroidFunctionPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ThyroidFunctionPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ThyroidFunctionSingleResponse(); }
}

/**
* Form - ThyroidFunction
*/
// @Route("/medical-review/form/thyroid-function/validate", "POST")
// @Api(Description="Form - ThyroidFunction")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ThyroidFunctionValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ThyroidFunctionValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ThyroidFunctionValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ThyroidFunctionValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ThyroidFunctionValidationResponse)", StatusCode=500)
// @DataContract
export class ThyroidFunctionPostValidate implements IReturn<ThyroidFunctionValidationResponse>
{
    /**
    * The metadata to use when validating the thyroid function.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The thyroid function to validate.
    */
    // @DataMember(Order=2)
    public data: ThyroidFunction;

    public constructor(init?: Partial<ThyroidFunctionPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ThyroidFunctionPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ThyroidFunctionValidationResponse(); }
}

/**
* Form - ThyroidFunction
*/
// @Route("/medical-review/form/thyroid-function/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - ThyroidFunction")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ThyroidFunctionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ThyroidFunctionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ThyroidFunctionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ThyroidFunctionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ThyroidFunctionSingleResponse)", StatusCode=500)
// @DataContract
export class ThyroidFunctionGetSingleByMedicalReviewIdAndRepeat implements IReturn<ThyroidFunctionSingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    /**
    * The id of the medical review.
    */
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    /**
    * The repeat of the thyroid function to retrieve.
    */
    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<ThyroidFunctionGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ThyroidFunctionGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ThyroidFunctionSingleResponse(); }
}

/**
* Form - ThyroidFunction
*/
// @Route("/medical-review/form/thyroid-function/single/update", "POST")
// @Api(Description="Form - ThyroidFunction")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ThyroidFunctionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ThyroidFunctionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ThyroidFunctionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ThyroidFunctionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ThyroidFunctionSingleResponse)", StatusCode=500)
// @DataContract
export class ThyroidFunctionPostUpdateById implements IReturn<ThyroidFunctionSingleResponse>, IFormPostUpdateById
{
    /**
    * The id of the medical review form.
    */
    // @DataMember(Order=1)
    public id?: number;

    /**
    * The additional metadata for the medical review.
    */
    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<ThyroidFunctionPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ThyroidFunctionPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ThyroidFunctionSingleResponse(); }
}

/**
* Form - AdverseEvents
*/
// @Route("/medical-review/form/adverse-events/single/id/{Id}", "GET")
// @Api(Description="Form - AdverseEvents")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AdverseEventsSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AdverseEventsSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AdverseEventsSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AdverseEventsSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AdverseEventsSingleResponse)", StatusCode=500)
// @DataContract
export class AdverseEventsGetSingleById implements IReturn<AdverseEventsSingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the adverse events to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<AdverseEventsGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdverseEventsGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new AdverseEventsSingleResponse(); }
}

/**
* Form - AdverseEvents
*/
// @Route("/medical-review/form/adverse-events/collection", "GET")
// @Api(Description="Form - AdverseEvents")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AdverseEventsCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AdverseEventsCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AdverseEventsCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AdverseEventsCollectionResponse)", StatusCode=500)
// @DataContract
export class AdverseEventsGetCollection implements IReturn<AdverseEventsCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<AdverseEventsGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdverseEventsGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new AdverseEventsCollectionResponse(); }
}

/**
* Form - AdverseEvents
*/
// @Route("/medical-review/form/adverse-events/create", "POST")
// @Api(Description="Form - AdverseEvents")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AdverseEventsSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AdverseEventsSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AdverseEventsSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AdverseEventsSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AdverseEventsSingleResponse)", StatusCode=500)
// @DataContract
export class AdverseEventsPostCreate implements IReturn<AdverseEventsSingleResponse>
{
    /**
    * The metadata to use when creating the adverse events.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the adverse events.
    */
    // @DataMember(Order=2)
    public data: AdverseEvents;

    public constructor(init?: Partial<AdverseEventsPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdverseEventsPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new AdverseEventsSingleResponse(); }
}

/**
* Form - AdverseEvents
*/
// @Route("/medical-review/form/adverse-events/save", "POST")
// @Api(Description="Form - AdverseEvents")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AdverseEventsSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AdverseEventsSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AdverseEventsSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AdverseEventsSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AdverseEventsSingleResponse)", StatusCode=500)
// @DataContract
export class AdverseEventsPostSave implements IReturn<AdverseEventsSingleResponse>
{
    /**
    * The metadata to use when creating the adverse events.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The adverse events to save.
    */
    // @DataMember(Order=2)
    public data: AdverseEvents;

    public constructor(init?: Partial<AdverseEventsPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdverseEventsPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AdverseEventsSingleResponse(); }
}

/**
* Form - AdverseEvents
*/
// @Route("/medical-review/form/adverse-events/validate", "POST")
// @Api(Description="Form - AdverseEvents")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AdverseEventsValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AdverseEventsValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AdverseEventsValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AdverseEventsValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AdverseEventsValidationResponse)", StatusCode=500)
// @DataContract
export class AdverseEventsPostValidate implements IReturn<AdverseEventsValidationResponse>
{
    /**
    * The metadata to use when validating the adverse events.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The adverse events to validate.
    */
    // @DataMember(Order=2)
    public data: AdverseEvents;

    public constructor(init?: Partial<AdverseEventsPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdverseEventsPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AdverseEventsValidationResponse(); }
}

/**
* Form - AdverseEvents
*/
// @Route("/medical-review/form/adverse-events/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - AdverseEvents")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AdverseEventsSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AdverseEventsSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AdverseEventsSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AdverseEventsSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AdverseEventsSingleResponse)", StatusCode=500)
// @DataContract
export class AdverseEventsGetSingleByMedicalReviewIdAndRepeat implements IReturn<AdverseEventsSingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    /**
    * The id of the medical review.
    */
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    /**
    * The repeat of the adverse events to retrieve.
    */
    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<AdverseEventsGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdverseEventsGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new AdverseEventsSingleResponse(); }
}

/**
* Form - AdverseEvents
*/
// @Route("/medical-review/form/adverse-events/single/update", "POST")
// @Api(Description="Form - AdverseEvents")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AdverseEventsSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AdverseEventsSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AdverseEventsSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AdverseEventsSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AdverseEventsSingleResponse)", StatusCode=500)
// @DataContract
export class AdverseEventsPostUpdateById implements IReturn<AdverseEventsSingleResponse>, IFormPostUpdateById
{
    /**
    * The id of the medical review form.
    */
    // @DataMember(Order=1)
    public id?: number;

    /**
    * The additional metadata for the medical review.
    */
    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<AdverseEventsPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdverseEventsPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AdverseEventsSingleResponse(); }
}

/**
* Form - ConcomitantMedications
*/
// @Route("/medical-review/form/concomitant-medications/single/id/{Id}", "GET")
// @Api(Description="Form - ConcomitantMedications")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationsSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationsSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationsSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationsSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationsSingleResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationsGetSingleById implements IReturn<ConcomitantMedicationsSingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the concomitant medications to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<ConcomitantMedicationsGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationsGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ConcomitantMedicationsSingleResponse(); }
}

/**
* Form - ConcomitantMedications
*/
// @Route("/medical-review/form/concomitant-medications/collection", "GET")
// @Api(Description="Form - ConcomitantMedications")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationsCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationsCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationsCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationsCollectionResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationsGetCollection implements IReturn<ConcomitantMedicationsCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<ConcomitantMedicationsGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationsGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ConcomitantMedicationsCollectionResponse(); }
}

/**
* Form - ConcomitantMedications
*/
// @Route("/medical-review/form/concomitant-medications/create", "POST")
// @Api(Description="Form - ConcomitantMedications")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationsSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationsSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationsSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationsSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationsSingleResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationsPostCreate implements IReturn<ConcomitantMedicationsSingleResponse>
{
    /**
    * The metadata to use when creating the concomitant medications.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the concomitant medications.
    */
    // @DataMember(Order=2)
    public data: ConcomitantMedications;

    public constructor(init?: Partial<ConcomitantMedicationsPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationsPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ConcomitantMedicationsSingleResponse(); }
}

/**
* Form - ConcomitantMedications
*/
// @Route("/medical-review/form/concomitant-medications/save", "POST")
// @Api(Description="Form - ConcomitantMedications")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationsSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationsSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationsSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationsSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationsSingleResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationsPostSave implements IReturn<ConcomitantMedicationsSingleResponse>
{
    /**
    * The metadata to use when creating the concomitant medications.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The concomitant medications to save.
    */
    // @DataMember(Order=2)
    public data: ConcomitantMedications;

    public constructor(init?: Partial<ConcomitantMedicationsPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationsPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ConcomitantMedicationsSingleResponse(); }
}

/**
* Form - ConcomitantMedications
*/
// @Route("/medical-review/form/concomitant-medications/validate", "POST")
// @Api(Description="Form - ConcomitantMedications")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationsValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationsValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationsValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationsValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationsValidationResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationsPostValidate implements IReturn<ConcomitantMedicationsValidationResponse>
{
    /**
    * The metadata to use when validating the concomitant medications.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The concomitant medications to validate.
    */
    // @DataMember(Order=2)
    public data: ConcomitantMedications;

    public constructor(init?: Partial<ConcomitantMedicationsPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationsPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ConcomitantMedicationsValidationResponse(); }
}

/**
* Form - ConcomitantMedications
*/
// @Route("/medical-review/form/concomitant-medications/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - ConcomitantMedications")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationsSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationsSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationsSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationsSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationsSingleResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationsGetSingleByMedicalReviewIdAndRepeat implements IReturn<ConcomitantMedicationsSingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    /**
    * The id of the medical review.
    */
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    /**
    * The repeat of the concomitant medications to retrieve.
    */
    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<ConcomitantMedicationsGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationsGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ConcomitantMedicationsSingleResponse(); }
}

/**
* Form - ConcomitantMedications
*/
// @Route("/medical-review/form/concomitant-medications/single/update", "POST")
// @Api(Description="Form - ConcomitantMedications")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationsSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationsSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationsSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationsSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationsSingleResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationsPostUpdateById implements IReturn<ConcomitantMedicationsSingleResponse>, IFormPostUpdateById
{
    /**
    * The id of the medical review form.
    */
    // @DataMember(Order=1)
    public id?: number;

    /**
    * The additional metadata for the medical review.
    */
    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<ConcomitantMedicationsPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationsPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ConcomitantMedicationsSingleResponse(); }
}

/**
* Form - GeneralInformation
*/
// @Route("/medical-review/form/general-information/single/id/{Id}", "GET")
// @Api(Description="Form - GeneralInformation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.GeneralInformationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.GeneralInformationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.GeneralInformationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.GeneralInformationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.GeneralInformationSingleResponse)", StatusCode=500)
// @DataContract
export class GeneralInformationGetSingleById implements IReturn<GeneralInformationSingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the general information to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<GeneralInformationGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GeneralInformationGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GeneralInformationSingleResponse(); }
}

/**
* Form - GeneralInformation
*/
// @Route("/medical-review/form/general-information/collection", "GET")
// @Api(Description="Form - GeneralInformation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.GeneralInformationCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.GeneralInformationCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.GeneralInformationCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.GeneralInformationCollectionResponse)", StatusCode=500)
// @DataContract
export class GeneralInformationGetCollection implements IReturn<GeneralInformationCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<GeneralInformationGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GeneralInformationGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GeneralInformationCollectionResponse(); }
}

/**
* Form - GeneralInformation
*/
// @Route("/medical-review/form/general-information/create", "POST")
// @Api(Description="Form - GeneralInformation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.GeneralInformationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.GeneralInformationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.GeneralInformationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.GeneralInformationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.GeneralInformationSingleResponse)", StatusCode=500)
// @DataContract
export class GeneralInformationPostCreate implements IReturn<GeneralInformationSingleResponse>
{
    /**
    * The metadata to use when creating the general information.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the general information.
    */
    // @DataMember(Order=2)
    public data: GeneralInformation;

    public constructor(init?: Partial<GeneralInformationPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GeneralInformationPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GeneralInformationSingleResponse(); }
}

/**
* Form - GeneralInformation
*/
// @Route("/medical-review/form/general-information/save", "POST")
// @Api(Description="Form - GeneralInformation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.GeneralInformationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.GeneralInformationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.GeneralInformationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.GeneralInformationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.GeneralInformationSingleResponse)", StatusCode=500)
// @DataContract
export class GeneralInformationPostSave implements IReturn<GeneralInformationSingleResponse>
{
    /**
    * The metadata to use when creating the general information.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The general information to save.
    */
    // @DataMember(Order=2)
    public data: GeneralInformation;

    public constructor(init?: Partial<GeneralInformationPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GeneralInformationPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GeneralInformationSingleResponse(); }
}

/**
* Form - GeneralInformation
*/
// @Route("/medical-review/form/general-information/validate", "POST")
// @Api(Description="Form - GeneralInformation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.GeneralInformationValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.GeneralInformationValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.GeneralInformationValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.GeneralInformationValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.GeneralInformationValidationResponse)", StatusCode=500)
// @DataContract
export class GeneralInformationPostValidate implements IReturn<GeneralInformationValidationResponse>
{
    /**
    * The metadata to use when validating the general information.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The general information to validate.
    */
    // @DataMember(Order=2)
    public data: GeneralInformation;

    public constructor(init?: Partial<GeneralInformationPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GeneralInformationPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GeneralInformationValidationResponse(); }
}

/**
* Form - GeneralInformation
*/
// @Route("/medical-review/form/general-information/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - GeneralInformation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.GeneralInformationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.GeneralInformationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.GeneralInformationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.GeneralInformationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.GeneralInformationSingleResponse)", StatusCode=500)
// @DataContract
export class GeneralInformationGetSingleByMedicalReviewIdAndRepeat implements IReturn<GeneralInformationSingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    /**
    * The id of the medical review.
    */
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    /**
    * The repeat of the general information to retrieve.
    */
    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<GeneralInformationGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GeneralInformationGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GeneralInformationSingleResponse(); }
}

/**
* Form - GeneralInformation
*/
// @Route("/medical-review/form/general-information/single/update", "POST")
// @Api(Description="Form - GeneralInformation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.GeneralInformationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.GeneralInformationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.GeneralInformationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.GeneralInformationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.GeneralInformationSingleResponse)", StatusCode=500)
// @DataContract
export class GeneralInformationPostUpdateById implements IReturn<GeneralInformationSingleResponse>, IFormPostUpdateById
{
    /**
    * The id of the medical review form.
    */
    // @DataMember(Order=1)
    public id?: number;

    /**
    * The additional metadata for the medical review.
    */
    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<GeneralInformationPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GeneralInformationPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GeneralInformationSingleResponse(); }
}

/**
* Form - Histopathology
*/
// @Route("/medical-review/form/histopathology/single/id/{Id}", "GET")
// @Api(Description="Form - Histopathology")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.HistopathologySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.HistopathologySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.HistopathologySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.HistopathologySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.HistopathologySingleResponse)", StatusCode=500)
// @DataContract
export class HistopathologyGetSingleById implements IReturn<HistopathologySingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the histopathology to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<HistopathologyGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'HistopathologyGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new HistopathologySingleResponse(); }
}

/**
* Form - Histopathology
*/
// @Route("/medical-review/form/histopathology/collection", "GET")
// @Api(Description="Form - Histopathology")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.HistopathologyCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.HistopathologyCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.HistopathologyCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.HistopathologyCollectionResponse)", StatusCode=500)
// @DataContract
export class HistopathologyGetCollection implements IReturn<HistopathologyCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<HistopathologyGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'HistopathologyGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new HistopathologyCollectionResponse(); }
}

/**
* Form - Histopathology
*/
// @Route("/medical-review/form/histopathology/create", "POST")
// @Api(Description="Form - Histopathology")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.HistopathologySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.HistopathologySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.HistopathologySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.HistopathologySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.HistopathologySingleResponse)", StatusCode=500)
// @DataContract
export class HistopathologyPostCreate implements IReturn<HistopathologySingleResponse>
{
    /**
    * The metadata to use when creating the histopathology.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the histopathology.
    */
    // @DataMember(Order=2)
    public data: Histopathology;

    public constructor(init?: Partial<HistopathologyPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'HistopathologyPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new HistopathologySingleResponse(); }
}

/**
* Form - Histopathology
*/
// @Route("/medical-review/form/histopathology/save", "POST")
// @Api(Description="Form - Histopathology")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.HistopathologySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.HistopathologySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.HistopathologySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.HistopathologySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.HistopathologySingleResponse)", StatusCode=500)
// @DataContract
export class HistopathologyPostSave implements IReturn<HistopathologySingleResponse>
{
    /**
    * The metadata to use when creating the histopathology.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The histopathology to save.
    */
    // @DataMember(Order=2)
    public data: Histopathology;

    public constructor(init?: Partial<HistopathologyPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'HistopathologyPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new HistopathologySingleResponse(); }
}

/**
* Form - Histopathology
*/
// @Route("/medical-review/form/histopathology/validate", "POST")
// @Api(Description="Form - Histopathology")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.HistopathologyValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.HistopathologyValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.HistopathologyValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.HistopathologyValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.HistopathologyValidationResponse)", StatusCode=500)
// @DataContract
export class HistopathologyPostValidate implements IReturn<HistopathologyValidationResponse>
{
    /**
    * The metadata to use when validating the histopathology.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The histopathology to validate.
    */
    // @DataMember(Order=2)
    public data: Histopathology;

    public constructor(init?: Partial<HistopathologyPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'HistopathologyPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new HistopathologyValidationResponse(); }
}

/**
* Form - Histopathology
*/
// @Route("/medical-review/form/histopathology/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - Histopathology")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.HistopathologySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.HistopathologySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.HistopathologySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.HistopathologySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.HistopathologySingleResponse)", StatusCode=500)
// @DataContract
export class HistopathologyGetSingleByMedicalReviewIdAndRepeat implements IReturn<HistopathologySingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    /**
    * The id of the medical review.
    */
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    /**
    * The repeat of the histopathology to retrieve.
    */
    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<HistopathologyGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'HistopathologyGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new HistopathologySingleResponse(); }
}

/**
* Form - Histopathology
*/
// @Route("/medical-review/form/histopathology/single/update", "POST")
// @Api(Description="Form - Histopathology")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.HistopathologySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.HistopathologySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.HistopathologySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.HistopathologySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.HistopathologySingleResponse)", StatusCode=500)
// @DataContract
export class HistopathologyPostUpdateById implements IReturn<HistopathologySingleResponse>, IFormPostUpdateById
{
    /**
    * The id of the medical review form.
    */
    // @DataMember(Order=1)
    public id?: number;

    /**
    * The additional metadata for the medical review.
    */
    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<HistopathologyPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'HistopathologyPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new HistopathologySingleResponse(); }
}

/**
* Form - Imaging
*/
// @Route("/medical-review/form/imaging/single/id/{Id}", "GET")
// @Api(Description="Form - Imaging")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ImagingSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ImagingSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ImagingSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ImagingSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ImagingSingleResponse)", StatusCode=500)
// @DataContract
export class ImagingGetSingleById implements IReturn<ImagingSingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the imaging to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<ImagingGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ImagingGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ImagingSingleResponse(); }
}

/**
* Form - Imaging
*/
// @Route("/medical-review/form/imaging/collection", "GET")
// @Api(Description="Form - Imaging")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ImagingCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ImagingCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ImagingCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ImagingCollectionResponse)", StatusCode=500)
// @DataContract
export class ImagingGetCollection implements IReturn<ImagingCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<ImagingGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ImagingGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ImagingCollectionResponse(); }
}

/**
* Form - Imaging
*/
// @Route("/medical-review/form/imaging/create", "POST")
// @Api(Description="Form - Imaging")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ImagingSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ImagingSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ImagingSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ImagingSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ImagingSingleResponse)", StatusCode=500)
// @DataContract
export class ImagingPostCreate implements IReturn<ImagingSingleResponse>
{
    /**
    * The metadata to use when creating the imaging.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the imaging.
    */
    // @DataMember(Order=2)
    public data: Imaging;

    public constructor(init?: Partial<ImagingPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ImagingPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ImagingSingleResponse(); }
}

/**
* Form - Imaging
*/
// @Route("/medical-review/form/imaging/save", "POST")
// @Api(Description="Form - Imaging")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ImagingSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ImagingSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ImagingSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ImagingSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ImagingSingleResponse)", StatusCode=500)
// @DataContract
export class ImagingPostSave implements IReturn<ImagingSingleResponse>
{
    /**
    * The metadata to use when creating the imaging.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The imaging to save.
    */
    // @DataMember(Order=2)
    public data: Imaging;

    public constructor(init?: Partial<ImagingPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ImagingPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ImagingSingleResponse(); }
}

/**
* Form - Imaging
*/
// @Route("/medical-review/form/imaging/validate", "POST")
// @Api(Description="Form - Imaging")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ImagingValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ImagingValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ImagingValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ImagingValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ImagingValidationResponse)", StatusCode=500)
// @DataContract
export class ImagingPostValidate implements IReturn<ImagingValidationResponse>
{
    /**
    * The metadata to use when validating the imaging.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The imaging to validate.
    */
    // @DataMember(Order=2)
    public data: Imaging;

    public constructor(init?: Partial<ImagingPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ImagingPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ImagingValidationResponse(); }
}

/**
* Form - Imaging
*/
// @Route("/medical-review/form/imaging/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - Imaging")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ImagingSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ImagingSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ImagingSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ImagingSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ImagingSingleResponse)", StatusCode=500)
// @DataContract
export class ImagingGetSingleByMedicalReviewIdAndRepeat implements IReturn<ImagingSingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    /**
    * The id of the medical review.
    */
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    /**
    * The repeat of the imaging to retrieve.
    */
    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<ImagingGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ImagingGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ImagingSingleResponse(); }
}

/**
* Form - Imaging
*/
// @Route("/medical-review/form/imaging/single/update", "POST")
// @Api(Description="Form - Imaging")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ImagingSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ImagingSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ImagingSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ImagingSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ImagingSingleResponse)", StatusCode=500)
// @DataContract
export class ImagingPostUpdateById implements IReturn<ImagingSingleResponse>, IFormPostUpdateById
{
    /**
    * The id of the medical review form.
    */
    // @DataMember(Order=1)
    public id?: number;

    /**
    * The additional metadata for the medical review.
    */
    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<ImagingPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ImagingPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ImagingSingleResponse(); }
}

/**
* Form - MedicalHistory
*/
// @Route("/medical-review/form/medical-history/single/id/{Id}", "GET")
// @Api(Description="Form - MedicalHistory")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.MedicalHistorySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.MedicalHistorySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.MedicalHistorySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.MedicalHistorySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.MedicalHistorySingleResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryGetSingleById implements IReturn<MedicalHistorySingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the medical history to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<MedicalHistoryGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalHistoryGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MedicalHistorySingleResponse(); }
}

/**
* Form - MedicalHistory
*/
// @Route("/medical-review/form/medical-history/collection", "GET")
// @Api(Description="Form - MedicalHistory")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.MedicalHistoryCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.MedicalHistoryCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.MedicalHistoryCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.MedicalHistoryCollectionResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryGetCollection implements IReturn<MedicalHistoryCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<MedicalHistoryGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalHistoryGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MedicalHistoryCollectionResponse(); }
}

/**
* Form - MedicalHistory
*/
// @Route("/medical-review/form/medical-history/create", "POST")
// @Api(Description="Form - MedicalHistory")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.MedicalHistorySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.MedicalHistorySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.MedicalHistorySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.MedicalHistorySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.MedicalHistorySingleResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryPostCreate implements IReturn<MedicalHistorySingleResponse>
{
    /**
    * The metadata to use when creating the medical history.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the medical history.
    */
    // @DataMember(Order=2)
    public data: MedicalHistory;

    public constructor(init?: Partial<MedicalHistoryPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalHistoryPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MedicalHistorySingleResponse(); }
}

/**
* Form - MedicalHistory
*/
// @Route("/medical-review/form/medical-history/save", "POST")
// @Api(Description="Form - MedicalHistory")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.MedicalHistorySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.MedicalHistorySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.MedicalHistorySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.MedicalHistorySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.MedicalHistorySingleResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryPostSave implements IReturn<MedicalHistorySingleResponse>
{
    /**
    * The metadata to use when creating the medical history.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The medical history to save.
    */
    // @DataMember(Order=2)
    public data: MedicalHistory;

    public constructor(init?: Partial<MedicalHistoryPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalHistoryPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new MedicalHistorySingleResponse(); }
}

/**
* Form - MedicalHistory
*/
// @Route("/medical-review/form/medical-history/validate", "POST")
// @Api(Description="Form - MedicalHistory")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.MedicalHistoryValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.MedicalHistoryValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.MedicalHistoryValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.MedicalHistoryValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.MedicalHistoryValidationResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryPostValidate implements IReturn<MedicalHistoryValidationResponse>
{
    /**
    * The metadata to use when validating the medical history.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The medical history to validate.
    */
    // @DataMember(Order=2)
    public data: MedicalHistory;

    public constructor(init?: Partial<MedicalHistoryPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalHistoryPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new MedicalHistoryValidationResponse(); }
}

/**
* Form - MedicalHistory
*/
// @Route("/medical-review/form/medical-history/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - MedicalHistory")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.MedicalHistorySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.MedicalHistorySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.MedicalHistorySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.MedicalHistorySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.MedicalHistorySingleResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryGetSingleByMedicalReviewIdAndRepeat implements IReturn<MedicalHistorySingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    /**
    * The id of the medical review.
    */
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    /**
    * The repeat of the medical history to retrieve.
    */
    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<MedicalHistoryGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalHistoryGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MedicalHistorySingleResponse(); }
}

/**
* Form - MedicalHistory
*/
// @Route("/medical-review/form/medical-history/single/update", "POST")
// @Api(Description="Form - MedicalHistory")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.MedicalHistorySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.MedicalHistorySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.MedicalHistorySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.MedicalHistorySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.MedicalHistorySingleResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryPostUpdateById implements IReturn<MedicalHistorySingleResponse>, IFormPostUpdateById
{
    /**
    * The id of the medical review form.
    */
    // @DataMember(Order=1)
    public id?: number;

    /**
    * The additional metadata for the medical review.
    */
    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<MedicalHistoryPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalHistoryPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new MedicalHistorySingleResponse(); }
}

/**
* Form - NeonTherapy
*/
// @Route("/medical-review/form/neon-therapy/single/id/{Id}", "GET")
// @Api(Description="Form - NeonTherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.NeonTherapySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.NeonTherapySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.NeonTherapySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.NeonTherapySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.NeonTherapySingleResponse)", StatusCode=500)
// @DataContract
export class NeonTherapyGetSingleById implements IReturn<NeonTherapySingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the neon therapy to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<NeonTherapyGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'NeonTherapyGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new NeonTherapySingleResponse(); }
}

/**
* Form - NeonTherapy
*/
// @Route("/medical-review/form/neon-therapy/collection", "GET")
// @Api(Description="Form - NeonTherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.NeonTherapyCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.NeonTherapyCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.NeonTherapyCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.NeonTherapyCollectionResponse)", StatusCode=500)
// @DataContract
export class NeonTherapyGetCollection implements IReturn<NeonTherapyCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<NeonTherapyGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'NeonTherapyGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new NeonTherapyCollectionResponse(); }
}

/**
* Form - NeonTherapy
*/
// @Route("/medical-review/form/neon-therapy/create", "POST")
// @Api(Description="Form - NeonTherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.NeonTherapySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.NeonTherapySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.NeonTherapySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.NeonTherapySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.NeonTherapySingleResponse)", StatusCode=500)
// @DataContract
export class NeonTherapyPostCreate implements IReturn<NeonTherapySingleResponse>
{
    /**
    * The metadata to use when creating the neon therapy.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the neon therapy.
    */
    // @DataMember(Order=2)
    public data: NeonTherapy;

    public constructor(init?: Partial<NeonTherapyPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'NeonTherapyPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new NeonTherapySingleResponse(); }
}

/**
* Form - NeonTherapy
*/
// @Route("/medical-review/form/neon-therapy/save", "POST")
// @Api(Description="Form - NeonTherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.NeonTherapySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.NeonTherapySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.NeonTherapySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.NeonTherapySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.NeonTherapySingleResponse)", StatusCode=500)
// @DataContract
export class NeonTherapyPostSave implements IReturn<NeonTherapySingleResponse>
{
    /**
    * The metadata to use when creating the neon therapy.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The neon therapy to save.
    */
    // @DataMember(Order=2)
    public data: NeonTherapy;

    public constructor(init?: Partial<NeonTherapyPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'NeonTherapyPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new NeonTherapySingleResponse(); }
}

/**
* Form - NeonTherapy
*/
// @Route("/medical-review/form/neon-therapy/validate", "POST")
// @Api(Description="Form - NeonTherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.NeonTherapyValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.NeonTherapyValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.NeonTherapyValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.NeonTherapyValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.NeonTherapyValidationResponse)", StatusCode=500)
// @DataContract
export class NeonTherapyPostValidate implements IReturn<NeonTherapyValidationResponse>
{
    /**
    * The metadata to use when validating the neon therapy.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The neon therapy to validate.
    */
    // @DataMember(Order=2)
    public data: NeonTherapy;

    public constructor(init?: Partial<NeonTherapyPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'NeonTherapyPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new NeonTherapyValidationResponse(); }
}

/**
* Form - NeonTherapy
*/
// @Route("/medical-review/form/neon-therapy/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - NeonTherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.NeonTherapySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.NeonTherapySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.NeonTherapySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.NeonTherapySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.NeonTherapySingleResponse)", StatusCode=500)
// @DataContract
export class NeonTherapyGetSingleByMedicalReviewIdAndRepeat implements IReturn<NeonTherapySingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    /**
    * The id of the medical review.
    */
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    /**
    * The repeat of the neon therapy to retrieve.
    */
    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<NeonTherapyGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'NeonTherapyGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new NeonTherapySingleResponse(); }
}

/**
* Form - NeonTherapy
*/
// @Route("/medical-review/form/neon-therapy/single/update", "POST")
// @Api(Description="Form - NeonTherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.NeonTherapySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.NeonTherapySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.NeonTherapySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.NeonTherapySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.NeonTherapySingleResponse)", StatusCode=500)
// @DataContract
export class NeonTherapyPostUpdateById implements IReturn<NeonTherapySingleResponse>, IFormPostUpdateById
{
    /**
    * The id of the medical review form.
    */
    // @DataMember(Order=1)
    public id?: number;

    /**
    * The additional metadata for the medical review.
    */
    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<NeonTherapyPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'NeonTherapyPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new NeonTherapySingleResponse(); }
}

/**
* Form - OrganFunction
*/
// @Route("/medical-review/form/organ-function/single/id/{Id}", "GET")
// @Api(Description="Form - OrganFunction")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.OrganFunctionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.OrganFunctionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.OrganFunctionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.OrganFunctionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.OrganFunctionSingleResponse)", StatusCode=500)
// @DataContract
export class OrganFunctionGetSingleById implements IReturn<OrganFunctionSingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the organ function to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<OrganFunctionGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'OrganFunctionGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new OrganFunctionSingleResponse(); }
}

/**
* Form - OrganFunction
*/
// @Route("/medical-review/form/organ-function/collection", "GET")
// @Api(Description="Form - OrganFunction")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.OrganFunctionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.OrganFunctionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.OrganFunctionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.OrganFunctionCollectionResponse)", StatusCode=500)
// @DataContract
export class OrganFunctionGetCollection implements IReturn<OrganFunctionCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<OrganFunctionGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'OrganFunctionGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new OrganFunctionCollectionResponse(); }
}

/**
* Form - OrganFunction
*/
// @Route("/medical-review/form/organ-function/create", "POST")
// @Api(Description="Form - OrganFunction")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.OrganFunctionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.OrganFunctionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.OrganFunctionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.OrganFunctionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.OrganFunctionSingleResponse)", StatusCode=500)
// @DataContract
export class OrganFunctionPostCreate implements IReturn<OrganFunctionSingleResponse>
{
    /**
    * The metadata to use when creating the organ function.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the organ function.
    */
    // @DataMember(Order=2)
    public data: OrganFunction;

    public constructor(init?: Partial<OrganFunctionPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'OrganFunctionPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new OrganFunctionSingleResponse(); }
}

/**
* Form - OrganFunction
*/
// @Route("/medical-review/form/organ-function/save", "POST")
// @Api(Description="Form - OrganFunction")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.OrganFunctionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.OrganFunctionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.OrganFunctionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.OrganFunctionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.OrganFunctionSingleResponse)", StatusCode=500)
// @DataContract
export class OrganFunctionPostSave implements IReturn<OrganFunctionSingleResponse>
{
    /**
    * The metadata to use when creating the organ function.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The organ function to save.
    */
    // @DataMember(Order=2)
    public data: OrganFunction;

    public constructor(init?: Partial<OrganFunctionPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'OrganFunctionPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new OrganFunctionSingleResponse(); }
}

/**
* Form - OrganFunction
*/
// @Route("/medical-review/form/organ-function/validate", "POST")
// @Api(Description="Form - OrganFunction")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.OrganFunctionValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.OrganFunctionValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.OrganFunctionValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.OrganFunctionValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.OrganFunctionValidationResponse)", StatusCode=500)
// @DataContract
export class OrganFunctionPostValidate implements IReturn<OrganFunctionValidationResponse>
{
    /**
    * The metadata to use when validating the organ function.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The organ function to validate.
    */
    // @DataMember(Order=2)
    public data: OrganFunction;

    public constructor(init?: Partial<OrganFunctionPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'OrganFunctionPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new OrganFunctionValidationResponse(); }
}

/**
* Form - OrganFunction
*/
// @Route("/medical-review/form/organ-function/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - OrganFunction")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.OrganFunctionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.OrganFunctionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.OrganFunctionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.OrganFunctionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.OrganFunctionSingleResponse)", StatusCode=500)
// @DataContract
export class OrganFunctionGetSingleByMedicalReviewIdAndRepeat implements IReturn<OrganFunctionSingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    /**
    * The id of the medical review.
    */
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    /**
    * The repeat of the organ function to retrieve.
    */
    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<OrganFunctionGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'OrganFunctionGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new OrganFunctionSingleResponse(); }
}

/**
* Form - OrganFunction
*/
// @Route("/medical-review/form/organ-function/single/update", "POST")
// @Api(Description="Form - OrganFunction")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.OrganFunctionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.OrganFunctionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.OrganFunctionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.OrganFunctionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.OrganFunctionSingleResponse)", StatusCode=500)
// @DataContract
export class OrganFunctionPostUpdateById implements IReturn<OrganFunctionSingleResponse>, IFormPostUpdateById
{
    /**
    * The id of the medical review form.
    */
    // @DataMember(Order=1)
    public id?: number;

    /**
    * The additional metadata for the medical review.
    */
    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<OrganFunctionPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'OrganFunctionPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new OrganFunctionSingleResponse(); }
}

/**
* Form - OrganFunctionTreatment
*/
// @Route("/medical-review/form/organ-function-treatment/single/id/{Id}", "GET")
// @Api(Description="Form - OrganFunctionTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.OrganFunctionTreatmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.OrganFunctionTreatmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.OrganFunctionTreatmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.OrganFunctionTreatmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.OrganFunctionTreatmentSingleResponse)", StatusCode=500)
// @DataContract
export class OrganFunctionTreatmentGetSingleById implements IReturn<OrganFunctionTreatmentSingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the organ function treatment to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<OrganFunctionTreatmentGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'OrganFunctionTreatmentGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new OrganFunctionTreatmentSingleResponse(); }
}

/**
* Form - OrganFunctionTreatment
*/
// @Route("/medical-review/form/organ-function-treatment/collection", "GET")
// @Api(Description="Form - OrganFunctionTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.OrganFunctionTreatmentCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.OrganFunctionTreatmentCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.OrganFunctionTreatmentCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.OrganFunctionTreatmentCollectionResponse)", StatusCode=500)
// @DataContract
export class OrganFunctionTreatmentGetCollection implements IReturn<OrganFunctionTreatmentCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<OrganFunctionTreatmentGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'OrganFunctionTreatmentGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new OrganFunctionTreatmentCollectionResponse(); }
}

/**
* Form - OrganFunctionTreatment
*/
// @Route("/medical-review/form/organ-function-treatment/create", "POST")
// @Api(Description="Form - OrganFunctionTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.OrganFunctionTreatmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.OrganFunctionTreatmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.OrganFunctionTreatmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.OrganFunctionTreatmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.OrganFunctionTreatmentSingleResponse)", StatusCode=500)
// @DataContract
export class OrganFunctionTreatmentPostCreate implements IReturn<OrganFunctionTreatmentSingleResponse>
{
    /**
    * The metadata to use when creating the organ function treatment.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the organ function treatment.
    */
    // @DataMember(Order=2)
    public data: OrganFunctionTreatment;

    public constructor(init?: Partial<OrganFunctionTreatmentPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'OrganFunctionTreatmentPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new OrganFunctionTreatmentSingleResponse(); }
}

/**
* Form - OrganFunctionTreatment
*/
// @Route("/medical-review/form/organ-function-treatment/save", "POST")
// @Api(Description="Form - OrganFunctionTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.OrganFunctionTreatmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.OrganFunctionTreatmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.OrganFunctionTreatmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.OrganFunctionTreatmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.OrganFunctionTreatmentSingleResponse)", StatusCode=500)
// @DataContract
export class OrganFunctionTreatmentPostSave implements IReturn<OrganFunctionTreatmentSingleResponse>
{
    /**
    * The metadata to use when creating the organ function treatment.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The organ function treatment to save.
    */
    // @DataMember(Order=2)
    public data: OrganFunctionTreatment;

    public constructor(init?: Partial<OrganFunctionTreatmentPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'OrganFunctionTreatmentPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new OrganFunctionTreatmentSingleResponse(); }
}

/**
* Form - OrganFunctionTreatment
*/
// @Route("/medical-review/form/organ-function-treatment/validate", "POST")
// @Api(Description="Form - OrganFunctionTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.OrganFunctionTreatmentValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.OrganFunctionTreatmentValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.OrganFunctionTreatmentValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.OrganFunctionTreatmentValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.OrganFunctionTreatmentValidationResponse)", StatusCode=500)
// @DataContract
export class OrganFunctionTreatmentPostValidate implements IReturn<OrganFunctionTreatmentValidationResponse>
{
    /**
    * The metadata to use when validating the organ function treatment.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The organ function treatment to validate.
    */
    // @DataMember(Order=2)
    public data: OrganFunctionTreatment;

    public constructor(init?: Partial<OrganFunctionTreatmentPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'OrganFunctionTreatmentPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new OrganFunctionTreatmentValidationResponse(); }
}

/**
* Form - OrganFunctionTreatment
*/
// @Route("/medical-review/form/organ-function-treatment/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - OrganFunctionTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.OrganFunctionTreatmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.OrganFunctionTreatmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.OrganFunctionTreatmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.OrganFunctionTreatmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.OrganFunctionTreatmentSingleResponse)", StatusCode=500)
// @DataContract
export class OrganFunctionTreatmentGetSingleByMedicalReviewIdAndRepeat implements IReturn<OrganFunctionTreatmentSingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    /**
    * The id of the medical review.
    */
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    /**
    * The repeat of the organ function treatment to retrieve.
    */
    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<OrganFunctionTreatmentGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'OrganFunctionTreatmentGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new OrganFunctionTreatmentSingleResponse(); }
}

/**
* Form - OrganFunctionTreatment
*/
// @Route("/medical-review/form/organ-function-treatment/single/update", "POST")
// @Api(Description="Form - OrganFunctionTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.OrganFunctionTreatmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.OrganFunctionTreatmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.OrganFunctionTreatmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.OrganFunctionTreatmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.OrganFunctionTreatmentSingleResponse)", StatusCode=500)
// @DataContract
export class OrganFunctionTreatmentPostUpdateById implements IReturn<OrganFunctionTreatmentSingleResponse>, IFormPostUpdateById
{
    /**
    * The id of the medical review form.
    */
    // @DataMember(Order=1)
    public id?: number;

    /**
    * The additional metadata for the medical review.
    */
    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<OrganFunctionTreatmentPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'OrganFunctionTreatmentPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new OrganFunctionTreatmentSingleResponse(); }
}

/**
* Form - Surgery
*/
// @Route("/medical-review/form/surgery/single/id/{Id}", "GET")
// @Api(Description="Form - Surgery")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SurgerySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SurgerySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SurgerySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SurgerySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SurgerySingleResponse)", StatusCode=500)
// @DataContract
export class SurgeryGetSingleById implements IReturn<SurgerySingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the surgery to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<SurgeryGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SurgeryGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SurgerySingleResponse(); }
}

/**
* Form - Surgery
*/
// @Route("/medical-review/form/surgery/collection", "GET")
// @Api(Description="Form - Surgery")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SurgeryCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SurgeryCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SurgeryCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SurgeryCollectionResponse)", StatusCode=500)
// @DataContract
export class SurgeryGetCollection implements IReturn<SurgeryCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<SurgeryGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SurgeryGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SurgeryCollectionResponse(); }
}

/**
* Form - Surgery
*/
// @Route("/medical-review/form/surgery/create", "POST")
// @Api(Description="Form - Surgery")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SurgerySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SurgerySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SurgerySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SurgerySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SurgerySingleResponse)", StatusCode=500)
// @DataContract
export class SurgeryPostCreate implements IReturn<SurgerySingleResponse>
{
    /**
    * The metadata to use when creating the surgery.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the surgery.
    */
    // @DataMember(Order=2)
    public data: Surgery;

    public constructor(init?: Partial<SurgeryPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SurgeryPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SurgerySingleResponse(); }
}

/**
* Form - Surgery
*/
// @Route("/medical-review/form/surgery/save", "POST")
// @Api(Description="Form - Surgery")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SurgerySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SurgerySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SurgerySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SurgerySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SurgerySingleResponse)", StatusCode=500)
// @DataContract
export class SurgeryPostSave implements IReturn<SurgerySingleResponse>
{
    /**
    * The metadata to use when creating the surgery.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The surgery to save.
    */
    // @DataMember(Order=2)
    public data: Surgery;

    public constructor(init?: Partial<SurgeryPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SurgeryPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SurgerySingleResponse(); }
}

/**
* Form - Surgery
*/
// @Route("/medical-review/form/surgery/validate", "POST")
// @Api(Description="Form - Surgery")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SurgeryValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SurgeryValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SurgeryValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SurgeryValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SurgeryValidationResponse)", StatusCode=500)
// @DataContract
export class SurgeryPostValidate implements IReturn<SurgeryValidationResponse>
{
    /**
    * The metadata to use when validating the surgery.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The surgery to validate.
    */
    // @DataMember(Order=2)
    public data: Surgery;

    public constructor(init?: Partial<SurgeryPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SurgeryPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SurgeryValidationResponse(); }
}

/**
* Form - Surgery
*/
// @Route("/medical-review/form/surgery/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - Surgery")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SurgerySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SurgerySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SurgerySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SurgerySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SurgerySingleResponse)", StatusCode=500)
// @DataContract
export class SurgeryGetSingleByMedicalReviewIdAndRepeat implements IReturn<SurgerySingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    /**
    * The id of the medical review.
    */
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    /**
    * The repeat of the surgery to retrieve.
    */
    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<SurgeryGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SurgeryGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SurgerySingleResponse(); }
}

/**
* Form - Surgery
*/
// @Route("/medical-review/form/surgery/single/update", "POST")
// @Api(Description="Form - Surgery")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SurgerySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SurgerySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SurgerySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SurgerySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SurgerySingleResponse)", StatusCode=500)
// @DataContract
export class SurgeryPostUpdateById implements IReturn<SurgerySingleResponse>, IFormPostUpdateById
{
    /**
    * The id of the medical review form.
    */
    // @DataMember(Order=1)
    public id?: number;

    /**
    * The additional metadata for the medical review.
    */
    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<SurgeryPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SurgeryPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SurgerySingleResponse(); }
}

/**
* Form - SurvivalAndRecurrence
*/
// @Route("/medical-review/form/survival-and-recurrence/single/id/{Id}", "GET")
// @Api(Description="Form - SurvivalAndRecurrence")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SurvivalAndRecurrenceSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SurvivalAndRecurrenceSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SurvivalAndRecurrenceSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SurvivalAndRecurrenceSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SurvivalAndRecurrenceSingleResponse)", StatusCode=500)
// @DataContract
export class SurvivalAndRecurrenceGetSingleById implements IReturn<SurvivalAndRecurrenceSingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the survival and recurrence to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<SurvivalAndRecurrenceGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SurvivalAndRecurrenceGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SurvivalAndRecurrenceSingleResponse(); }
}

/**
* Form - SurvivalAndRecurrence
*/
// @Route("/medical-review/form/survival-and-recurrence/collection", "GET")
// @Api(Description="Form - SurvivalAndRecurrence")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SurvivalAndRecurrenceCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SurvivalAndRecurrenceCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SurvivalAndRecurrenceCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SurvivalAndRecurrenceCollectionResponse)", StatusCode=500)
// @DataContract
export class SurvivalAndRecurrenceGetCollection implements IReturn<SurvivalAndRecurrenceCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<SurvivalAndRecurrenceGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SurvivalAndRecurrenceGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SurvivalAndRecurrenceCollectionResponse(); }
}

/**
* Form - SurvivalAndRecurrence
*/
// @Route("/medical-review/form/survival-and-recurrence/create", "POST")
// @Api(Description="Form - SurvivalAndRecurrence")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SurvivalAndRecurrenceSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SurvivalAndRecurrenceSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SurvivalAndRecurrenceSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SurvivalAndRecurrenceSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SurvivalAndRecurrenceSingleResponse)", StatusCode=500)
// @DataContract
export class SurvivalAndRecurrencePostCreate implements IReturn<SurvivalAndRecurrenceSingleResponse>
{
    /**
    * The metadata to use when creating the survival and recurrence.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the survival and recurrence.
    */
    // @DataMember(Order=2)
    public data: SurvivalAndRecurrence;

    public constructor(init?: Partial<SurvivalAndRecurrencePostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SurvivalAndRecurrencePostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SurvivalAndRecurrenceSingleResponse(); }
}

/**
* Form - SurvivalAndRecurrence
*/
// @Route("/medical-review/form/survival-and-recurrence/save", "POST")
// @Api(Description="Form - SurvivalAndRecurrence")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SurvivalAndRecurrenceSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SurvivalAndRecurrenceSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SurvivalAndRecurrenceSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SurvivalAndRecurrenceSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SurvivalAndRecurrenceSingleResponse)", StatusCode=500)
// @DataContract
export class SurvivalAndRecurrencePostSave implements IReturn<SurvivalAndRecurrenceSingleResponse>
{
    /**
    * The metadata to use when creating the survival and recurrence.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The survival and recurrence to save.
    */
    // @DataMember(Order=2)
    public data: SurvivalAndRecurrence;

    public constructor(init?: Partial<SurvivalAndRecurrencePostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SurvivalAndRecurrencePostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SurvivalAndRecurrenceSingleResponse(); }
}

/**
* Form - SurvivalAndRecurrence
*/
// @Route("/medical-review/form/survival-and-recurrence/validate", "POST")
// @Api(Description="Form - SurvivalAndRecurrence")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SurvivalAndRecurrenceValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SurvivalAndRecurrenceValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SurvivalAndRecurrenceValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SurvivalAndRecurrenceValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SurvivalAndRecurrenceValidationResponse)", StatusCode=500)
// @DataContract
export class SurvivalAndRecurrencePostValidate implements IReturn<SurvivalAndRecurrenceValidationResponse>
{
    /**
    * The metadata to use when validating the survival and recurrence.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The survival and recurrence to validate.
    */
    // @DataMember(Order=2)
    public data: SurvivalAndRecurrence;

    public constructor(init?: Partial<SurvivalAndRecurrencePostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SurvivalAndRecurrencePostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SurvivalAndRecurrenceValidationResponse(); }
}

/**
* Form - SurvivalAndRecurrence
*/
// @Route("/medical-review/form/survival-and-recurrence/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - SurvivalAndRecurrence")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SurvivalAndRecurrenceSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SurvivalAndRecurrenceSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SurvivalAndRecurrenceSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SurvivalAndRecurrenceSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SurvivalAndRecurrenceSingleResponse)", StatusCode=500)
// @DataContract
export class SurvivalAndRecurrenceGetSingleByMedicalReviewIdAndRepeat implements IReturn<SurvivalAndRecurrenceSingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    /**
    * The id of the medical review.
    */
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    /**
    * The repeat of the survival and recurrence to retrieve.
    */
    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<SurvivalAndRecurrenceGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SurvivalAndRecurrenceGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SurvivalAndRecurrenceSingleResponse(); }
}

/**
* Form - SurvivalAndRecurrence
*/
// @Route("/medical-review/form/survival-and-recurrence/single/update", "POST")
// @Api(Description="Form - SurvivalAndRecurrence")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SurvivalAndRecurrenceSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SurvivalAndRecurrenceSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SurvivalAndRecurrenceSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SurvivalAndRecurrenceSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SurvivalAndRecurrenceSingleResponse)", StatusCode=500)
// @DataContract
export class SurvivalAndRecurrencePostUpdateById implements IReturn<SurvivalAndRecurrenceSingleResponse>, IFormPostUpdateById
{
    /**
    * The id of the medical review form.
    */
    // @DataMember(Order=1)
    public id?: number;

    /**
    * The additional metadata for the medical review.
    */
    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<SurvivalAndRecurrencePostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SurvivalAndRecurrencePostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SurvivalAndRecurrenceSingleResponse(); }
}

// @Route("/medical-review/file/view/{Id}", "GET")
export class GetFile implements IReturn<Blob>, IGet
{
    public id?: number;

    public constructor(init?: Partial<GetFile>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetFile'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Blob(); }
}

// @Route("/medical-review/file/download/{Id}", "GET")
export class DownloadFile implements IReturn<Blob>, IGet
{
    public id?: number;

    public constructor(init?: Partial<DownloadFile>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DownloadFile'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Blob(); }
}

/**
* Master Group
*/
// @Route("/medical-review/master-group/single/id/{Id}", "GET")
// @Api(Description="Master Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupSingleResponse)", StatusCode=500)
// @DataContract
export class MasterGroupGetSingleById implements IReturn<MasterGroupSingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the master group to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<MasterGroupGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MasterGroupGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MasterGroupSingleResponse(); }
}

/**
* Master Group
*/
// @Route("/medical-review/master-group/collection", "GET")
// @Api(Description="Master Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupCollectionResponse)", StatusCode=500)
// @DataContract
export class MasterGroupGetCollection implements IReturn<MasterGroupCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<MasterGroupGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MasterGroupGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MasterGroupCollectionResponse(); }
}

/**
* Master Group
*/
// @Route("/medical-review/master-group/create", "POST")
// @Api(Description="Master Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupSingleResponse)", StatusCode=500)
// @DataContract
export class MasterGroupPostCreate implements IReturn<MasterGroupSingleResponse>
{
    /**
    * The metadata to use when creating the master group.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the master group.
    */
    // @DataMember(Order=2)
    public data: MasterGroup;

    public constructor(init?: Partial<MasterGroupPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MasterGroupPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MasterGroupSingleResponse(); }
}

/**
* Master Group
*/
// @Route("/medical-review/master-group/save", "POST")
// @Api(Description="Master Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupSingleResponse)", StatusCode=500)
// @DataContract
export class MasterGroupPostSave implements IReturn<MasterGroupSingleResponse>
{
    /**
    * The metadata to use when creating the master group.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The master group to save.
    */
    // @DataMember(Order=2)
    public data: MasterGroup;

    public constructor(init?: Partial<MasterGroupPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MasterGroupPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new MasterGroupSingleResponse(); }
}

/**
* Master Group
*/
// @Route("/medical-review/master-group/validate", "POST")
// @Api(Description="Master Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupValidationResponse)", StatusCode=500)
// @DataContract
export class MasterGroupPostValidate implements IReturn<MasterGroupValidationResponse>
{
    /**
    * The metadata to use when validating the master group.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The master group to validate.
    */
    // @DataMember(Order=2)
    public data: MasterGroup;

    public constructor(init?: Partial<MasterGroupPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MasterGroupPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new MasterGroupValidationResponse(); }
}

/**
* Coordinating Group
*/
// @Route("/medical-review/coordinating-group/single/id/{Id}", "GET")
// @Api(Description="Coordinating Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupSingleResponse)", StatusCode=500)
// @DataContract
export class CoordinatingGroupGetSingleById implements IReturn<CoordinatingGroupSingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the coordinating group to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<CoordinatingGroupGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CoordinatingGroupGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CoordinatingGroupSingleResponse(); }
}

/**
* Coordinating Group
*/
// @Route("/medical-review/coordinating-group/collection", "GET")
// @Api(Description="Coordinating Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupCollectionResponse)", StatusCode=500)
// @DataContract
export class CoordinatingGroupGetCollection implements IReturn<CoordinatingGroupCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<CoordinatingGroupGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CoordinatingGroupGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CoordinatingGroupCollectionResponse(); }
}

/**
* Coordinating Group
*/
// @Route("/medical-review/coordinating-group/create", "POST")
// @Api(Description="Coordinating Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupSingleResponse)", StatusCode=500)
// @DataContract
export class CoordinatingGroupPostCreate implements IReturn<CoordinatingGroupSingleResponse>
{
    /**
    * The metadata to use when creating the coordinating group.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the coordinating group.
    */
    // @DataMember(Order=2)
    public data: CoordinatingGroup;

    public constructor(init?: Partial<CoordinatingGroupPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CoordinatingGroupPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CoordinatingGroupSingleResponse(); }
}

/**
* Coordinating Group
*/
// @Route("/medical-review/coordinating-group/save", "POST")
// @Api(Description="Coordinating Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupSingleResponse)", StatusCode=500)
// @DataContract
export class CoordinatingGroupPostSave implements IReturn<CoordinatingGroupSingleResponse>
{
    /**
    * The metadata to use when creating the coordinating group.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The coordinating group to save.
    */
    // @DataMember(Order=2)
    public data: CoordinatingGroup;

    public constructor(init?: Partial<CoordinatingGroupPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CoordinatingGroupPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CoordinatingGroupSingleResponse(); }
}

/**
* Coordinating Group
*/
// @Route("/medical-review/coordinating-group/validate", "POST")
// @Api(Description="Coordinating Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupValidationResponse)", StatusCode=500)
// @DataContract
export class CoordinatingGroupPostValidate implements IReturn<CoordinatingGroupValidationResponse>
{
    /**
    * The metadata to use when validating the coordinating group.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The coordinating group to validate.
    */
    // @DataMember(Order=2)
    public data: CoordinatingGroup;

    public constructor(init?: Partial<CoordinatingGroupPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CoordinatingGroupPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CoordinatingGroupValidationResponse(); }
}

/**
* Country
*/
// @Route("/medical-review/country/single/id/{Id}", "GET")
// @Api(Description="Country")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.Country.CountrySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.Country.CountrySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.Country.CountrySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.Country.CountrySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.Country.CountrySingleResponse)", StatusCode=500)
// @DataContract
export class CountryGetSingleById implements IReturn<CountrySingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the country to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<CountryGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CountryGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CountrySingleResponse(); }
}

/**
* Country
*/
// @Route("/medical-review/country/collection", "GET")
// @Api(Description="Country")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.Country.CountryCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.Country.CountryCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.Country.CountryCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.Country.CountryCollectionResponse)", StatusCode=500)
// @DataContract
export class CountryGetCollection implements IReturn<CountryCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<CountryGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CountryGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CountryCollectionResponse(); }
}

/**
* Country
*/
// @Route("/medical-review/country/create", "POST")
// @Api(Description="Country")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.Country.CountrySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.Country.CountrySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.Country.CountrySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.Country.CountrySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.Country.CountrySingleResponse)", StatusCode=500)
// @DataContract
export class CountryPostCreate implements IReturn<CountrySingleResponse>
{
    /**
    * The metadata to use when creating the country.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the country.
    */
    // @DataMember(Order=2)
    public data: Country;

    public constructor(init?: Partial<CountryPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CountryPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CountrySingleResponse(); }
}

/**
* Country
*/
// @Route("/medical-review/country/save", "POST")
// @Api(Description="Country")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.Country.CountrySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.Country.CountrySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.Country.CountrySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.Country.CountrySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.Country.CountrySingleResponse)", StatusCode=500)
// @DataContract
export class CountryPostSave implements IReturn<CountrySingleResponse>
{
    /**
    * The metadata to use when creating the country.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The country to save.
    */
    // @DataMember(Order=2)
    public data: Country;

    public constructor(init?: Partial<CountryPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CountryPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CountrySingleResponse(); }
}

/**
* Country
*/
// @Route("/medical-review/country/validate", "POST")
// @Api(Description="Country")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.Country.CountryValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.Country.CountryValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.Country.CountryValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.Country.CountryValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.Country.CountryValidationResponse)", StatusCode=500)
// @DataContract
export class CountryPostValidate implements IReturn<CountryValidationResponse>
{
    /**
    * The metadata to use when validating the country.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The country to validate.
    */
    // @DataMember(Order=2)
    public data: Country;

    public constructor(init?: Partial<CountryPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CountryPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CountryValidationResponse(); }
}

/**
* Institution
*/
// @Route("/medical-review/institution/single/id/{Id}", "GET")
// @Api(Description="Institution")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.Institution.InstitutionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.Institution.InstitutionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.Institution.InstitutionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.Institution.InstitutionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.Institution.InstitutionSingleResponse)", StatusCode=500)
// @DataContract
export class InstitutionGetSingleById implements IReturn<InstitutionSingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the institution to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<InstitutionGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'InstitutionGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new InstitutionSingleResponse(); }
}

/**
* Institution
*/
// @Route("/medical-review/institution/collection", "GET")
// @Api(Description="Institution")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.Institution.InstitutionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.Institution.InstitutionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.Institution.InstitutionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.Institution.InstitutionCollectionResponse)", StatusCode=500)
// @DataContract
export class InstitutionGetCollection implements IReturn<InstitutionCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<InstitutionGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'InstitutionGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new InstitutionCollectionResponse(); }
}

/**
* Institution
*/
// @Route("/medical-review/institution/create", "POST")
// @Api(Description="Institution")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.Institution.InstitutionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.Institution.InstitutionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.Institution.InstitutionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.Institution.InstitutionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.Institution.InstitutionSingleResponse)", StatusCode=500)
// @DataContract
export class InstitutionPostCreate implements IReturn<InstitutionSingleResponse>
{
    /**
    * The metadata to use when creating the institution.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the institution.
    */
    // @DataMember(Order=2)
    public data: Institution;

    public constructor(init?: Partial<InstitutionPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'InstitutionPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new InstitutionSingleResponse(); }
}

/**
* Institution
*/
// @Route("/medical-review/institution/save", "POST")
// @Api(Description="Institution")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.Institution.InstitutionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.Institution.InstitutionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.Institution.InstitutionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.Institution.InstitutionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.Institution.InstitutionSingleResponse)", StatusCode=500)
// @DataContract
export class InstitutionPostSave implements IReturn<InstitutionSingleResponse>
{
    /**
    * The metadata to use when creating the institution.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The institution to save.
    */
    // @DataMember(Order=2)
    public data: Institution;

    public constructor(init?: Partial<InstitutionPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'InstitutionPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new InstitutionSingleResponse(); }
}

/**
* Institution
*/
// @Route("/medical-review/institution/validate", "POST")
// @Api(Description="Institution")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.Institution.InstitutionValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.Institution.InstitutionValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.Institution.InstitutionValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.Institution.InstitutionValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.Institution.InstitutionValidationResponse)", StatusCode=500)
// @DataContract
export class InstitutionPostValidate implements IReturn<InstitutionValidationResponse>
{
    /**
    * The metadata to use when validating the institution.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The institution to validate.
    */
    // @DataMember(Order=2)
    public data: Institution;

    public constructor(init?: Partial<InstitutionPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'InstitutionPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new InstitutionValidationResponse(); }
}

/**
* Patient - MedicalReviewPatient
*/
// @Route("/medical-review/patient/single/id/{Id}", "GET")
// @Api(Description="Patient - MedicalReviewPatient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=500)
// @DataContract
export class PatientGetSingleById implements IReturn<PatientSingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the patient to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<PatientGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientSingleResponse(); }
}

/**
* Patient - MedicalReviewPatient
*/
// @Route("/medical-review/patient/collection", "GET")
// @Api(Description="Patient - MedicalReviewPatient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.Patient.PatientCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.Patient.PatientCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.Patient.PatientCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.Patient.PatientCollectionResponse)", StatusCode=500)
// @DataContract
export class PatientGetCollection implements IReturn<PatientCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<PatientGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientCollectionResponse(); }
}

/**
* Patient - MedicalReviewPatient
*/
// @Route("/medical-review/patient/create", "POST")
// @Api(Description="Patient - MedicalReviewPatient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=500)
// @DataContract
export class PatientPostCreate implements IReturn<PatientSingleResponse>
{
    /**
    * The metadata to use when creating the patient.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the patient.
    */
    // @DataMember(Order=2)
    public data: MedicalReviewPatient;

    public constructor(init?: Partial<PatientPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientSingleResponse(); }
}

/**
* Patient - MedicalReviewPatient
*/
// @Route("/medical-review/patient/save", "POST")
// @Api(Description="Patient - MedicalReviewPatient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=500)
// @DataContract
export class PatientPostSave implements IReturn<PatientSingleResponse>
{
    /**
    * The metadata to use when creating the patient.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The patient to save.
    */
    // @DataMember(Order=2)
    public data: MedicalReviewPatient;

    public constructor(init?: Partial<PatientPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PatientSingleResponse(); }
}

/**
* Patient - MedicalReviewPatient
*/
// @Route("/medical-review/patient/validate", "POST")
// @Api(Description="Patient - MedicalReviewPatient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.Patient.PatientValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.Patient.PatientValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.Patient.PatientValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.Patient.PatientValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.Patient.PatientValidationResponse)", StatusCode=500)
// @DataContract
export class PatientPostValidate implements IReturn<PatientValidationResponse>
{
    /**
    * The metadata to use when validating the patient.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The patient to validate.
    */
    // @DataMember(Order=2)
    public data: MedicalReviewPatient;

    public constructor(init?: Partial<PatientPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PatientValidationResponse(); }
}

/**
* Patient - MedicalReviewPatient
*/
// @Route("/medical-review/patient/single/study-number/{StudyNumber}", "GET")
// @Api(Description="Patient - MedicalReviewPatient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=500)
// @DataContract
export class PatientGetSingleByStudyNumber implements IReturn<PatientSingleResponse>, IPatientGetSingleByStudyNumber
{
    /**
    * The Study Number of the patient to retrieve.
    */
    // @DataMember(Order=1)
    public studyNumber: string;

    public constructor(init?: Partial<PatientGetSingleByStudyNumber>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientGetSingleByStudyNumber'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientSingleResponse(); }
}

// @Route("/medical-review/open-clinica/document", "GET")
export class GetOpenClinicaDocument implements IReturn<Blob>, IGet
{
    public fileName: string;

    public constructor(init?: Partial<GetOpenClinicaDocument>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetOpenClinicaDocument'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Blob(); }
}

// @Route("/medical-review/permissions", "POST")
export class PostHasPermissions implements IReturn<PostHasPermissionsResponse>, IPost
{
    public context: MedicalReviewRequestContext;
    public permissions: MedicalReviewPermission[];

    public constructor(init?: Partial<PostHasPermissions>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PostHasPermissions'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PostHasPermissionsResponse(); }
}

/**
* Medical Review Definition
*/
// @Route("/medical-review/medical-review-definition/single/id/{Id}", "GET")
// @Api(Description="Medical Review Definition")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.MedicalReviewDefinition.MedicalReviewDefinitionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.MedicalReviewDefinition.MedicalReviewDefinitionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.MedicalReviewDefinition.MedicalReviewDefinitionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.MedicalReviewDefinition.MedicalReviewDefinitionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.MedicalReviewDefinition.MedicalReviewDefinitionSingleResponse)", StatusCode=500)
// @DataContract
export class MedicalReviewDefinitionGetSingleById implements IReturn<MedicalReviewDefinitionSingleResponse>, IConfigurationGetSingleById
{
    /**
    * The ID of the medical review definition to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<MedicalReviewDefinitionGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalReviewDefinitionGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MedicalReviewDefinitionSingleResponse(); }
}

/**
* Medical Review Definition
*/
// @Route("/medical-review/medical-review-definition/collection", "GET")
// @Api(Description="Medical Review Definition")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.MedicalReviewDefinition.MedicalReviewDefinitionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.MedicalReviewDefinition.MedicalReviewDefinitionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.MedicalReviewDefinition.MedicalReviewDefinitionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.MedicalReviewDefinition.MedicalReviewDefinitionCollectionResponse)", StatusCode=500)
// @DataContract
export class MedicalReviewDefinitionGetCollection implements IReturn<MedicalReviewDefinitionCollectionResponse>, IConfigurationGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<MedicalReviewDefinitionGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalReviewDefinitionGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MedicalReviewDefinitionCollectionResponse(); }
}

/**
* Medical Review Definition
*/
// @Route("/medical-review/medical-review-definition/single/code/{Code}", "GET")
// @Api(Description="Medical Review Definition")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.MedicalReviewDefinition.MedicalReviewDefinitionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.MedicalReviewDefinition.MedicalReviewDefinitionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.MedicalReviewDefinition.MedicalReviewDefinitionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.MedicalReviewDefinition.MedicalReviewDefinitionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.MedicalReviewDefinition.MedicalReviewDefinitionSingleResponse)", StatusCode=500)
// @DataContract
export class MedicalReviewDefinitionGetSingleByCode implements IReturn<MedicalReviewDefinitionSingleResponse>, IConfigurationGetSingleByCode
{
    /**
    * The ID of the medical review definition to retrieve.
    */
    // @DataMember(Order=1)
    public code: string;

    public constructor(init?: Partial<MedicalReviewDefinitionGetSingleByCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalReviewDefinitionGetSingleByCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MedicalReviewDefinitionSingleResponse(); }
}

/**
* Query Recipient
*/
// @Route("/medical-review/query-recipient/single/id/{Id}", "GET")
// @Api(Description="Query Recipient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.QueryRecipient.QueryRecipientSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.QueryRecipient.QueryRecipientSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.QueryRecipient.QueryRecipientSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.QueryRecipient.QueryRecipientSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.QueryRecipient.QueryRecipientSingleResponse)", StatusCode=500)
// @DataContract
export class QueryRecipientGetSingleById implements IReturn<QueryRecipientSingleResponse>, IConfigurationGetSingleById
{
    /**
    * The ID of the query recipient to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<QueryRecipientGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'QueryRecipientGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryRecipientSingleResponse(); }
}

/**
* Query Recipient
*/
// @Route("/medical-review/query-recipient/collection", "GET")
// @Api(Description="Query Recipient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.QueryRecipient.QueryRecipientCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.QueryRecipient.QueryRecipientCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.QueryRecipient.QueryRecipientCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.QueryRecipient.QueryRecipientCollectionResponse)", StatusCode=500)
// @DataContract
export class QueryRecipientGetCollection implements IReturn<QueryRecipientCollectionResponse>, IConfigurationGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<QueryRecipientGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'QueryRecipientGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryRecipientCollectionResponse(); }
}

/**
* Reviewer
*/
// @Route("/medical-review/reviewer/single/id/{Id}", "GET")
// @Api(Description="Reviewer")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.Reviewer.ReviewerSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.Reviewer.ReviewerSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.Reviewer.ReviewerSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.Reviewer.ReviewerSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.Reviewer.ReviewerSingleResponse)", StatusCode=500)
// @DataContract
export class ReviewerGetSingleById implements IReturn<ReviewerSingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the reviewer to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<ReviewerGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ReviewerGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ReviewerSingleResponse(); }
}

/**
* Reviewer
*/
// @Route("/medical-review/reviewer/collection", "GET")
// @Api(Description="Reviewer")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.Reviewer.ReviewerCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.Reviewer.ReviewerCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.Reviewer.ReviewerCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.Reviewer.ReviewerCollectionResponse)", StatusCode=500)
// @DataContract
export class ReviewerGetCollection implements IReturn<ReviewerCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<ReviewerGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ReviewerGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ReviewerCollectionResponse(); }
}

/**
* Reviewer
*/
// @Route("/medical-review/reviewer/create", "POST")
// @Api(Description="Reviewer")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.Reviewer.ReviewerSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.Reviewer.ReviewerSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.Reviewer.ReviewerSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.Reviewer.ReviewerSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.Reviewer.ReviewerSingleResponse)", StatusCode=500)
// @DataContract
export class ReviewerPostCreate implements IReturn<ReviewerSingleResponse>
{
    /**
    * The metadata to use when creating the reviewer.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the reviewer.
    */
    // @DataMember(Order=2)
    public data: Reviewer;

    public constructor(init?: Partial<ReviewerPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ReviewerPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ReviewerSingleResponse(); }
}

/**
* Reviewer
*/
// @Route("/medical-review/reviewer/save", "POST")
// @Api(Description="Reviewer")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.Reviewer.ReviewerSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.Reviewer.ReviewerSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.Reviewer.ReviewerSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.Reviewer.ReviewerSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.Reviewer.ReviewerSingleResponse)", StatusCode=500)
// @DataContract
export class ReviewerPostSave implements IReturn<ReviewerSingleResponse>
{
    /**
    * The metadata to use when creating the reviewer.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The reviewer to save.
    */
    // @DataMember(Order=2)
    public data: Reviewer;

    public constructor(init?: Partial<ReviewerPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ReviewerPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ReviewerSingleResponse(); }
}

/**
* Reviewer
*/
// @Route("/medical-review/reviewer/validate", "POST")
// @Api(Description="Reviewer")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.Reviewer.ReviewerValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.Reviewer.ReviewerValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.Reviewer.ReviewerValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.Reviewer.ReviewerValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.Reviewer.ReviewerValidationResponse)", StatusCode=500)
// @DataContract
export class ReviewerPostValidate implements IReturn<ReviewerValidationResponse>
{
    /**
    * The metadata to use when validating the reviewer.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The reviewer to validate.
    */
    // @DataMember(Order=2)
    public data: Reviewer;

    public constructor(init?: Partial<ReviewerPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ReviewerPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ReviewerValidationResponse(); }
}

/**
* Medical Review
*/
// @Route("/medical-review/medical-review/single/id/{Id}", "GET")
// @Api(Description="Medical Review")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewSingleResponse)", StatusCode=500)
// @DataContract
export class MedicalReviewGetSingleById implements IReturn<MedicalReviewSingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the medical review to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<MedicalReviewGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalReviewGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MedicalReviewSingleResponse(); }
}

/**
* Medical Review
*/
// @Route("/medical-review/medical-review/collection", "GET")
// @Api(Description="Medical Review")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewCollectionResponse)", StatusCode=500)
// @DataContract
export class MedicalReviewGetCollection implements IReturn<MedicalReviewCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<MedicalReviewGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalReviewGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MedicalReviewCollectionResponse(); }
}

/**
* Medical Review
*/
// @Route("/medical-review/medical-review/create", "POST")
// @Api(Description="Medical Review")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewSingleResponse)", StatusCode=500)
// @DataContract
export class MedicalReviewPostCreate implements IReturn<MedicalReviewSingleResponse>
{
    /**
    * The metadata to use when creating the medical review.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the medical review.
    */
    // @DataMember(Order=2)
    public data: MedicalReview;

    public constructor(init?: Partial<MedicalReviewPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalReviewPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MedicalReviewSingleResponse(); }
}

/**
* Medical Review
*/
// @Route("/medical-review/medical-review/save", "POST")
// @Api(Description="Medical Review")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewSingleResponse)", StatusCode=500)
// @DataContract
export class MedicalReviewPostSave implements IReturn<MedicalReviewSingleResponse>
{
    /**
    * The metadata to use when creating the medical review.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The medical review to save.
    */
    // @DataMember(Order=2)
    public data: MedicalReview;

    public constructor(init?: Partial<MedicalReviewPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalReviewPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new MedicalReviewSingleResponse(); }
}

/**
* Medical Review
*/
// @Route("/medical-review/medical-review/validate", "POST")
// @Api(Description="Medical Review")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewValidationResponse)", StatusCode=500)
// @DataContract
export class MedicalReviewPostValidate implements IReturn<MedicalReviewValidationResponse>
{
    /**
    * The metadata to use when validating the medical review.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The medical review to validate.
    */
    // @DataMember(Order=2)
    public data: MedicalReview;

    public constructor(init?: Partial<MedicalReviewPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalReviewPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new MedicalReviewValidationResponse(); }
}

/**
* Medical Review
*/
// @Route("/medical-review/medical-review/single/patient-id-repeat/{PatientId}/{Repeat}", "GET")
// @Api(Description="Medical Review")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewSingleResponse)", StatusCode=500)
// @DataContract
export class MedicalReviewGetSingleByPatientIdAndRepeat implements IReturn<MedicalReviewSingleResponse>, IMedicalReviewGetSingleByPatientIdAndRepeat
{
    /**
    * The Study Number of the medical review to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Study Number of the medical review to retrieve.
    */
    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<MedicalReviewGetSingleByPatientIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalReviewGetSingleByPatientIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MedicalReviewSingleResponse(); }
}

/**
* Medical Review
*/
// @Route("/medical-review/medical-review/collection/patient-id/{PatientId}", "GET")
// @Api(Description="Medical Review")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewCollectionResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewCollectionResponse)", StatusCode=500)
// @DataContract
export class MedicalReviewGetCollectionByPatientId implements IReturn<MedicalReviewCollectionResponse>, IMedicalReviewGetCollectionByPatientId
{
    /**
    * The Study Number of the medical review to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    public constructor(init?: Partial<MedicalReviewGetCollectionByPatientId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalReviewGetCollectionByPatientId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MedicalReviewCollectionResponse(); }
}

/**
* Medical Review
*/
// @Route("/medical-review/medical-review/create-review", "POST")
// @Api(Description="Medical Review")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewCreationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewCreationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewCreationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewCreationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewCreationSingleResponse)", StatusCode=500)
// @DataContract
export class MedicalReviewPostCreateReview implements IReturn<MedicalReviewCreationSingleResponse>
{
    /**
    * The metadata to use when creating the medical review.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the medical review.
    */
    // @DataMember(Order=2)
    public data: MedicalReview;

    /**
    * The options to use when creating the medical review.
    */
    // @DataMember(Order=2)
    public options: MedicalReviewCreationOptions;

    public constructor(init?: Partial<MedicalReviewPostCreateReview>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalReviewPostCreateReview'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new MedicalReviewCreationSingleResponse(); }
}

/**
* Medical Review
*/
// @Route("/medical-review/medical-review/update-review", "POST")
// @Api(Description="Medical Review")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewCreationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewCreationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewCreationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewCreationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewCreationSingleResponse)", StatusCode=500)
// @DataContract
export class MedicalReviewPostUpdateReview implements IReturn<MedicalReviewCreationSingleResponse>, IMedicalReviewPostUpdateReview
{
    /**
    * The metadata to use when creating the medical review.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The Id of the medical review.
    */
    // @DataMember(Order=2)
    public medicalReviewId?: number;

    /**
    * The options to use when creating the medical review.
    */
    // @DataMember(Order=2)
    public options: MedicalReviewUpdateOptions;

    public constructor(init?: Partial<MedicalReviewPostUpdateReview>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalReviewPostUpdateReview'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new MedicalReviewCreationSingleResponse(); }
}

/**
* Medical Review
*/
// @Route("/medical-review/medical-review/collection/validate-forms", "GET")
// @Api(Description="Medical Review")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewValidationCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewValidationCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewValidationCollectionResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewValidationCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewValidationCollectionResponse)", StatusCode=500)
// @DataContract
export class MedicalReviewPostFormValidationCollectionById implements IReturn<MedicalReviewValidationCollectionResponse>, IMedicalReviewPostFormValidationCollectionById
{
    /**
    * The metadata to use when validating the medical review forms.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The Id of the medical review to medical review.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<MedicalReviewPostFormValidationCollectionById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalReviewPostFormValidationCollectionById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new MedicalReviewValidationCollectionResponse(); }
}

/**
* Query
*/
// @Route("/medical-review/query/single/id/{Id}", "GET")
// @Api(Description="Query")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.Query.QuerySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.Query.QuerySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.Query.QuerySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.Query.QuerySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.Query.QuerySingleResponse)", StatusCode=500)
// @DataContract
export class QueryGetSingleById implements IReturn<QuerySingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the query to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<QueryGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'QueryGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QuerySingleResponse(); }
}

/**
* Query
*/
// @Route("/medical-review/query/collection", "GET")
// @Api(Description="Query")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.Query.QueryCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.Query.QueryCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.Query.QueryCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.Query.QueryCollectionResponse)", StatusCode=500)
// @DataContract
export class QueryGetCollection implements IReturn<QueryCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<QueryGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'QueryGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryCollectionResponse(); }
}

/**
* Query
*/
// @Route("/medical-review/query/create", "POST")
// @Api(Description="Query")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.Query.QuerySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.Query.QuerySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.Query.QuerySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.Query.QuerySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.Query.QuerySingleResponse)", StatusCode=500)
// @DataContract
export class QueryPostCreate implements IReturn<QuerySingleResponse>
{
    /**
    * The metadata to use when creating the query.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the query.
    */
    // @DataMember(Order=2)
    public data: Query;

    public constructor(init?: Partial<QueryPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'QueryPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QuerySingleResponse(); }
}

/**
* Query
*/
// @Route("/medical-review/query/save", "POST")
// @Api(Description="Query")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.Query.QuerySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.Query.QuerySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.Query.QuerySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.Query.QuerySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.Query.QuerySingleResponse)", StatusCode=500)
// @DataContract
export class QueryPostSave implements IReturn<QuerySingleResponse>
{
    /**
    * The metadata to use when creating the query.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The query to save.
    */
    // @DataMember(Order=2)
    public data: Query;

    public constructor(init?: Partial<QueryPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'QueryPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new QuerySingleResponse(); }
}

/**
* Query
*/
// @Route("/medical-review/query/validate", "POST")
// @Api(Description="Query")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.Query.QueryValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.Query.QueryValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.Query.QueryValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.Query.QueryValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.Query.QueryValidationResponse)", StatusCode=500)
// @DataContract
export class QueryPostValidate implements IReturn<QueryValidationResponse>
{
    /**
    * The metadata to use when validating the query.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The query to validate.
    */
    // @DataMember(Order=2)
    public data: Query;

    public constructor(init?: Partial<QueryPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'QueryPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new QueryValidationResponse(); }
}

/**
* Query
*/
// @Route("/medical-review/query/collection/patient-id/{PatientId}", "GET")
// @Api(Description="Query")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.Query.QueryCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.Query.QueryCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.Query.QueryCollectionResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.Query.QueryCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.Query.QueryCollectionResponse)", StatusCode=500)
// @DataContract
export class QueryGetCollectionByMedicalReviewId implements IReturn<QueryCollectionResponse>, IQueryGetCollectionByMedicalReviewId
{
    /**
    * The ID of the medical review to retrieve query for.
    */
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    public constructor(init?: Partial<QueryGetCollectionByMedicalReviewId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'QueryGetCollectionByMedicalReviewId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryCollectionResponse(); }
}

/**
* Query
*/
// @Route("/medical-review/query/save/collection", "POST")
// @Api(Description="Query")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.Query.QueryCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.Query.QueryCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.Query.QueryCollectionResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.Query.QueryCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.Query.QueryCollectionResponse)", StatusCode=500)
// @DataContract
export class QueryPostSaveCollection implements IReturn<QueryCollectionResponse>
{
    /**
    * The metadata to use when creating the query.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The query to save.
    */
    // @DataMember(Order=2)
    public data: Query[];

    public constructor(init?: Partial<QueryPostSaveCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'QueryPostSaveCollection'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new QueryCollectionResponse(); }
}

/**
* UpdatedFormProperty
*/
// @Route("/medical-review/updatedformproperty/single/id/{Id}", "GET")
// @Api(Description="UpdatedFormProperty")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=500)
// @DataContract
export class UpdatedFormPropertyGetSingleById implements IReturn<UpdatedFormPropertySingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the updatedformproperty to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<UpdatedFormPropertyGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdatedFormPropertyGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new UpdatedFormPropertySingleResponse(); }
}

/**
* UpdatedFormProperty
*/
// @Route("/medical-review/updatedformproperty/collection", "GET")
// @Api(Description="UpdatedFormProperty")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertyCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertyCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertyCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertyCollectionResponse)", StatusCode=500)
// @DataContract
export class UpdatedFormPropertyGetCollection implements IReturn<UpdatedFormPropertyCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<UpdatedFormPropertyGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdatedFormPropertyGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new UpdatedFormPropertyCollectionResponse(); }
}

/**
* UpdatedFormProperty
*/
// @Route("/medical-review/updatedformproperty/create", "POST")
// @Api(Description="UpdatedFormProperty")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=500)
// @DataContract
export class UpdatedFormPropertyPostCreate implements IReturn<UpdatedFormPropertySingleResponse>
{
    /**
    * The metadata to use when creating the updatedformproperty.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the updatedformproperty.
    */
    // @DataMember(Order=2)
    public data: UpdatedFormProperty;

    public constructor(init?: Partial<UpdatedFormPropertyPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdatedFormPropertyPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new UpdatedFormPropertySingleResponse(); }
}

/**
* UpdatedFormProperty
*/
// @Route("/medical-review/updatedformproperty/save", "POST")
// @Api(Description="UpdatedFormProperty")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=500)
// @DataContract
export class UpdatedFormPropertyPostSave implements IReturn<UpdatedFormPropertySingleResponse>
{
    /**
    * The metadata to use when creating the updatedformproperty.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The updatedformproperty to save.
    */
    // @DataMember(Order=2)
    public data: UpdatedFormProperty;

    public constructor(init?: Partial<UpdatedFormPropertyPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdatedFormPropertyPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new UpdatedFormPropertySingleResponse(); }
}

/**
* UpdatedFormProperty
*/
// @Route("/medical-review/updatedformproperty/validate", "POST")
// @Api(Description="UpdatedFormProperty")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertyValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertyValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertyValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertyValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertyValidationResponse)", StatusCode=500)
// @DataContract
export class UpdatedFormPropertyPostValidate implements IReturn<UpdatedFormPropertyValidationResponse>
{
    /**
    * The metadata to use when validating the updatedformproperty.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The updatedformproperty to validate.
    */
    // @DataMember(Order=2)
    public data: UpdatedFormProperty;

    public constructor(init?: Partial<UpdatedFormPropertyPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdatedFormPropertyPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new UpdatedFormPropertyValidationResponse(); }
}

/**
* UpdatedFormProperty
*/
// @Route("/medical-review/updatedformproperty/collection/form-definition-id-form-id/{FormDefinitionId}/{FormId}", "GET")
// @Api(Description="UpdatedFormProperty")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertyCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertyCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertyCollectionResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertyCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertyCollectionResponse)", StatusCode=500)
// @DataContract
export class UpdatedFormPropertyGetCollectionByFormDefinitionIdAndFormId implements IReturn<UpdatedFormPropertyCollectionResponse>, IUpdatedFormPropertyGetCollectionByFormDefinitionIdAndFormId
{
    /**
    * The Form Definition ID of the updatedformproperty to retrieve.
    */
    // @DataMember(Order=1)
    public formDefinitionId?: number;

    /**
    * The Form ID of the updatedformproperty to retrieve.
    */
    // @DataMember(Order=1)
    public formId?: number;

    public constructor(init?: Partial<UpdatedFormPropertyGetCollectionByFormDefinitionIdAndFormId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdatedFormPropertyGetCollectionByFormDefinitionIdAndFormId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new UpdatedFormPropertyCollectionResponse(); }
}

